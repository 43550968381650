import React from "react";
import PaymentSuccess from "./PaymentSuccess";
import PaymentLoading from "./PaymentLoading";
import PaymentFailed from "./PaymentFailed";

export default function PaymentModal({ type = "loading", handelModalType }) {
  return (
    <div className="modal  bg-black/60 h-screen fixed left-0 flex justify-center items-center top-0 w-full z-50 px-4 py-3 md:px-10  ">
      {type === "loading" ? (
        <PaymentLoading />
      ) : type === "success" ? (
        <PaymentSuccess />
      ) : (
        <PaymentFailed handelModalType={handelModalType} />
      )}
    </div>
  );
}
