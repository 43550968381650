import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Schemas from "../../utilities/validations";
import { useReduxStore } from "../../hooks/useReduxStore";
import {
  LoginAction,
  RESET_REQUEST_ACTION,
  RegisterAction,
  resetPasswordRequestAction,
} from "../../store/Actions/action";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { updateItem } from "../../store/Reducers/itemReducer";
export default function useForgetPassword() {
  const navigate = useNavigate();
  const { dispatch, getState } = useReduxStore();
  const [email, setEmail] = useState("");
  const { loginSuccess } = getState("AUTH");
  const { emailSent } = getState("Item");
  let { route, goBack } = window?.history?.state?.usr || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: Schemas.forget,
  });

  const onSubmit = (data = {}) => {
    setEmail(data?.email);
    dispatch(resetPasswordRequestAction({ ...data }));
  };
  const onNavigate = () => {
    goBack ? navigate(-1) : navigate(route || "/", { replace: Boolean(route) });
  };
  useEffect(() => {
    loginSuccess && onNavigate();
  }, [loginSuccess]);
  useEffect(() => {
    return () => {
      dispatch(updateItem({ emailSent: false }));
    };
  }, []);
  return {
    email,
    emailSent,
    register,
    handleSubmit,
    onSubmit,
    errors,
  };
}
