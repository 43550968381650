import React from "react";
import ProfileInput from "./ProfileInput";
import { Button } from "@material-tailwind/react";
import { useReduxStore } from "../hooks/useReduxStore";
import { useForm } from "react-hook-form";
import { EditBasicInfoAction } from "../store/Actions/action";
import Schemas from "../utilities/validations";
export default function BasicInfoComponent() {
  const { getState, dispatch } = useReduxStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: Schemas.editBasicInfo,
  });

  const onSave = (data = {}) => {
    // console.log("data------", data);
    // dispatch(EditBasicInfoAction({ ...data }));
  };
  const { userData } = getState("AUTH");
  const { name, email, avatar } = userData || {};
  return (
    <>
      <ProfileInput
        disabled
        label={"Name"}
        defaultValue={name}
        placeholder="Enter  Name"
        type="text"
        id="first_name"
        name={"name"}
        errors={errors}
        register={register}
      />
      <ProfileInput
        disabled
        label={"Email"}
        defaultValue={email}
        placeholder="Enter Email"
        type="email"
        id="email"
        name={"email"}
        errors={errors}
        register={register}
      />
      {/* <div className="mt-10">
        <button
          onClick={handleSubmit(onSave)}
          className="inline-block w-full rounded-md bg-secondary px-8 py-3 font-medium text-white sm:w-auto"
        >
          Save Changes
        </button>
      </div> */}
      {/* <Button
        onClick={() => setChPassword(!ch_password)}
        color="orange"
        variant="text"
        className="flex items-center p-0 py-1 my-2 text-base"
      >
        {ch_password ? "Don't Change Password" : "Change Password"}
      </Button> */}
    </>
  );
}
