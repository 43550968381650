import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Schemas from "../../utilities/validations";
import { useReduxStore } from "../../hooks/useReduxStore";
import { RegisterAction } from "../../store/Actions/action";
import { useNavigate } from "react-router-dom";

export default function useSignup() {
  const navigate = useNavigate();
  const { dispatch, getState } = useReduxStore();
  const [email, setEmail] = useState("");
  const { registerSuccess } = getState("AUTH");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: Schemas.register,
  });

  const onSubmit = (data = {}) => {
    dispatch(RegisterAction({ ...data }));
  };

  useEffect(() => {
    if (registerSuccess) {
      
      navigate("/OTP", { state: { email: email } });
    }
  }, [registerSuccess, navigate]);

  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
    setEmail,
    email
  };
}
