import Footer from "../../Components/Footer/Footer";
import useChangePassword from "./useChangePassword";
import AuthInput from "../../Components/AuthInput";
export default function ChangePassword() {
  const { register, handleSubmit, onSubmit, errors } = useChangePassword();
  return (
    <>
      <section className="min-h-screen flex items-stretch text-white ">
        <div
          className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
          style={{
            backgroundImage:
              "url(https://image.hoopladigital.com/www/home/banner.jpg",
          }}
        >
          <div className="absolute bg-black opacity-60 inset-0 z-0" />
          <div className="w-full px-24 z-10">
            <h1 className="text-5xl font-bold text-left tracking-wide">
              Keep it special
            </h1>
            <p className="text-3xl my-4">
              Instant access to millions of audiobooks, ebooks, Movies,audios,
              and more
            </p>
          </div>
        </div>
        <div
          className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0"
          style={{ backgroundColor: "#161616" }}
        >
          <div
            className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
            style={{
              backgroundImage:
                "url(https://image.hoopladigital.com/www/home/banner.jpg",
            }}
          >
            <div className="absolute bg-black opacity-60 inset-0 z-0" />
          </div>
          <div className="w-full py-6 z-20">
            <h1 className="my-6 text-3xl font-bold">Change Password</h1>

            <form action="" className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto">
              <AuthInput
                errors={errors}
                register={register}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
              />
              <AuthInput
                errors={errors}
                register={register}
                type="password"
                name="password_confirmation"
                id="cpassword"
                placeholder="Confirm Your Password"
              />

              <div className="px-4 pb-2 pt-4">
                <button
                  onClick={handleSubmit(onSubmit)}
                  className="uppercase block w-full p-4 text-lg rounded-full bg-secondary  hover:bg-secondary/75 focus:outline-none"
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
