import { IconButton, Typography, rating } from "@material-tailwind/react";
import React from "react";
import { PlayIcon } from "@heroicons/react/24/outline";
import {
  FilmIcon,
  MusicalNoteIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import VedioPlayer from "../Pages/VedioPlayer/VedioPlayer";
import AudioPlayerComponent from "./AudioPlayerComponent";
import { HeartIcon, ShareIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid";
import SharePopover from "./SharePopover";
import { getYear, returnSubCategoryName } from "../helper/helper";
import { musicData } from "../Constant/Data";
import PlaylistItem from "./PlaylistItem";
export default function PlaylistComponent({
  rating = "4.0",
  views = 1,
  type = "movie",
  description,
  image,
  title,
  author,
  artist,
  sub_category = [],
  released_date,
  publish_date,
  duration_time = "",
  onClickBuy,
  isPurchased = false,
  onWishClick,
  onPlayClick,
  onFeaturedClick,
  feature_video
}) {
  sub_category = returnSubCategoryName(sub_category);

  const isPlaylist = type === "Audio";
  const isMovie = type === "movie" || type === "season";
  const isEbook = type === "ebook";
  const isAudioBook = type === "audio_book";
  const typeName = `${isPlaylist ? "Playlist" : "Album"}`;
  const navigate = useNavigate();
  const onAddToCart = () => {
    navigate("/AddToCart");
  };
  const onWatch = () => {
    navigate("/Checkout");
  };
  const onPlay = () => {
    // navigate("/Ebook");
    onPlayClick();
  };
  const onFirstBtnPress = () => {
    isPurchased ? onPlay() : onClickBuy();
  };
  return (
    <div>
      <section
        id="Detail"
        className=" bg-white  mx-auto  px-4 sm:px-6 md:px-4 lg:px-10 py-4 sm:py-10 shadow-md"
      >
        <div className=" mx-auto justify-end  px-4  sm:px-6  lg:px-8 flex gap-10 md:gap-20 lg:flex-justify lg:flex  flex-col-reverse lg:flex-row-reverse">
          <div className="sm:text-center lg:text-left lg:w-1/3 lg:flex lg:flex-col lg:justify-center">
            <h2 className="text-2xl lg:text-4xl leading-10 lg:leading-[2.9rem] text-center md:text-left text-black  font-medium ">
              {title || "INCEPTIONS"}
            </h2>
            <div className="mt-4   items-center justify-center md:justify-start ">
              <Typography
                color="blue-gray"
                className="flex items-center  justify-center text-center md:justify-start gap-1.5 font-normal text-xs md:text-lg "
              >
                <MusicalNoteIcon className="h-5 w-5 text-secondary" />
                {`${typeName} |  `}
                <EyeIcon className="h-4 w-4 md:h-6 md:w-6 text-secondary" />
                {views} | {getYear(publish_date || released_date)}
                {duration_time && ` | ${duration_time}`}
              </Typography>
            </div>
            {description && (
              <p className="text-gray-600 line-clamp-2 text-base md:text-lg mt-4 text-center md:text-left ">
                {description}
              </p>
            )}
            {/* <Typography className="text-center text-secondary md:text-left mt-3 gap-1.5 font-normal text-sm  line-clamp-3   ">
              {sub_category}
            </Typography> */}
            <Typography
              color="blue-gray"
              className="text-center md:text-left mt-2 gap-1.5 font-normal text-base md:text-base line-clamp-3   "
            >
              {`Created by : ${artist || author}, ${50} Songs, 2hr`}
            </Typography>

            <div className="mt-10 gap-6 flex flex-col-reverse sm:flex-row justify-center  md:justify-start md:items-center">
              <button
                onClick={onFirstBtnPress}
                className="w-full sm:w-32 px-3 py-2 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
              >
                {!isPurchased && "Buy and "}
                {"Play"}
              </button>
              { feature_video&&  <button
              onClick={onFeaturedClick}
              className="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
           Watch Promo 
            </button>}
              <div className="mt-2 md:mt-0 gap-6 flex  sm:flex-row justify-center  md:justify-start md:items-center">
                {
                  <IconButton
                    color="black"
                    variant="outlined"
                    className="rounded-full bg-white border-black  text-black"
                    onClick={onWishClick}
                  >
                    <HeartIcon className="h-6 w-6 text-black" />
                  </IconButton>
                }
                {<SharePopover />}
              </div>
            </div>
          </div>
          {/*   Image Section     */}
          <div className="lg:inset-y-0 relative  lg:right-0">
            <img
              className="h-56 w-full object-contain sm:h-72 md:h-96  lg:w-full lg:h-full lg:max-h-[18rem]"
              src={
                image ||
                "https://ntvb.tmsimg.com/assets/p7825626_v_h10_aa.jpg?w=1280&h=720"
              }
              alt=""
            />
          </div>
        </div>
      </section>
      {/* List section start------ */}
      <>
        <div className="  mx-auto px-4 sm:px-8 lg:px-14 bg-white border-t-2 border-t-gray-100">
          <div className="py-2">
            <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-2 overflow-x-auto">
              <div className="inline-block min-w-full  overflow-hidden">
                <table className="min-w-full leading-normal">
                  <thead>
                    <tr>
                      <th className="px-5 py-3 border-b-2 border-white bg-white text-left text-xs md:text-base font-semibold text-black uppercase tracking-wider">
                        Song
                      </th>
                      <th className="px-5 py-3 border-b-2 border-white bg-white text-center text-xs md:text-base font-semibold text-black uppercase tracking-wider">
                        Artist
                      </th>
                      <th className="px-5  py-3 border-b-2 border-white bg-white text-center text-xs md:text-base font-semibold text-black uppercase tracking-wider">
                        Realeased
                      </th>
                      <th className="px-5 py-3 border-b-2 border-white bg-white text-center text-xs md:text-base font-semibold text-black uppercase tracking-wider">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {musicData.map((item, i) => (
                      <PlaylistItem {...item} isActive={i == 1} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
      {/* end-------- */}
    </div>
  );
}
