import React from "react";

export default function CollectionBanner({
  categoryTitle,
  bannerImage = "https://manybooks.net/sites/default/files/2018-07/bookcoverssmall2.jpg",
}) {
  return (
    <section
      style={{
        backgroundImage: `url(${bannerImage})`,
      }}
      className={`relative h-48 lg:h-72   bg-cover bg-center bg-no-repeat`}
    >
      <div className="absolute h-48 lg:h-72  inset-0 bg-black/50  "></div>

      <div className="relative h-48 mx-auto max-w-screen-xl px-4 lg:py-32 py-16 sm:px-6 lg:flex lg:h-72 lg:items-center lg:px-3">
        <div className=" -mt-4 max-w-xl text-center sm:text-left ">
          <h1 className="text-3xl font-extrabold sm:text-6xl text-white">
            Let us find your
            <strong className="block mt-4 font-extrabold sm:text-6xl  text-secondary ">
              Favourites {categoryTitle}
            </strong>
          </h1>
        </div>
      </div>
    </section>
  );
}
