import React from "react";

export default function DetailItem({ title, value }) {
  return (
    <div className="grid grid-cols-1 gap-1 p-4 px-6 sm:grid-cols-4 sm:gap-4">
      <dt className="font-medium text-lg text-gray-900">{title}</dt>
      <dd className="text-gray-700 text-lg overflow-x-clip sm:col-span-2">
        {value}
      </dd>
    </div>
  );
}
