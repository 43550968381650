import React, { useState } from "react";
// import pdf from "../../assets/pdf2.pdf";
// import { Document, Page } from "react-pdf";
// import { pdfjs } from "react-pdf";
import { useReduxStore } from "../../hooks/useReduxStore";
// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   "pdfjs-dist/build/pdf.worker.min.js",
//   import.meta.url
// ).toString();

// import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
export default () => {
  const { getState, dispatch } = useReduxStore();
  const { itemDetail } = getState("Item");
  // const [numPages, setNumPages] = useState(1);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }
  return (
    <div className="h-screen w-screen">
      <iframe src={itemDetail?.file} width={"100%"} height={"100%"} />
      {/* <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} />
        ))}
      </Document>
      <p>
        Page {pageNumber} of {numPages}
      </p> */}
    </div>
  );
};
