import React from "react";
import { useReduxStore } from "./useReduxStore";
import { updateHome } from "../store/Reducers/homeReducer";

const useSetWidth = () => {
  const { dispatch, getState } = useReduxStore();
  const { width } = getState("Home");
  React.useEffect(() => {
    dispatch(updateHome({ width: window.innerWidth }));

    window.addEventListener("resize", () =>
      dispatch(updateHome({ width: window.innerWidth }))
    );
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return { width };
};

export default useSetWidth;
