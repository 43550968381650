import React from "react";
import { Link } from "react-router-dom";
import { login } from "../assets/Images";
export default function InfoModal({ handelModal, onLoginClick }) {
  return (
    <div
      onClick={handelModal}
      className="modal  bg-black/60 h-screen fixed left-0 flex justify-center items-center top-0 w-full z-50 px-6 py-3 md:px-10  "
    >
      <div className="bg-white  p-6 px-4 md:px-8 rounded-md lg:max-w-[32vw] my-auto md:mx-auto">
        <img
          src={login}
          className=" w-[5rem] h-[5rem] md:w-[8rem] md:h-[8rem] mx-auto my-6 md:my-6"
        ></img>
        <div className="text-center">
          <h3 className="md:text-2xl text-xl text-gray-900 font-semibold text-center">
            Login First!
          </h3>
          <p className="text-gray-900 my-3 md:text-lg">
            If you want to buy this item please login and continue
          </p>
          <div className=" py-8 md:py-10 mt-2 text-center">
            <button
              onClick={onLoginClick}
              state={{ route: "/Detail" }}
              to="/Login"
              className="px-12 rounded-sm bg-black hover:bg-black/75 text-white font-semibold py-2 md:py-3"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
