import { Carousel } from '@material-tailwind/react';
import { Fade,Flip,Slide,Bounce,Zoom } from "react-reveal";
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import {Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
export default function MystiaAbout() {
  return (
    <section className="text-white body-font bg-black border-neutral-900 ">
    <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <Fade 
        duration={1200}
        distance={'6rem'}
        left
        
      >
      <h1 className="title-font sm:text-5xl text-3xl mb-8 font-semibold text-white">
      Mystio A Name You Can Trust

        </h1>
      </Fade>
      <Fade
          duration={1200}
          distance={'6rem'}
          left
          
      >
      <p className="mb-8 text-gray-400 text-lg leading-relaxed">
      Mystio is more than just a delivery service; it's a brand synonymous with trust and excellence. Our commitment to quality and customer satisfaction sets us apart as the Boss Amongst Bosses in the delivery industry. Whether you're a retailer looking to enhance your logistics or a consumer seeking reliable delivery, Mystio is your go-to solution.
        </p>
      </Fade>
      </div>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
  
                <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          {({isActive})=>(
        <Fade 
        duration={3000}
        cascade
        when={isActive}
     >
    <img
        className="object-cover object-center rounded"
        alt="hero"
        src={require("../assets/mystio2.png")}
      />
    </Fade>
          )}
        </SwiperSlide>
        {/* <SwiperSlide>
          {({isActive})=>(
   <Fade 
      duration={3000}
          cascade
          when={isActive}
          distance={"2rem"}
>
<img
   className="object-cover object-center rounded"
   alt="hero"
   src={require("../assets/Designer (20).png")}
 />
</Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
   <Fade 
      duration={3000}
          cascade
          when={isActive}
          distance={"2rem"}
>
<img
   className="object-cover object-center rounded"
   alt="hero"
   src={require("../assets/downloadfile-3.jpg")}
 />
</Fade>
          )}
        </SwiperSlide> */}
      </Swiper>
      </div>
    </div>
    <div className="container mx-auto flex px-5 py-24 md:flex-row-reverse flex-col items-center">
   <Fade
    duration={1200}
    distance={'6rem'}
    right
   >
   <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h1 className="title-font sm:text-5xl text-3xl mb-8 font-semibold text-white">
        Secure Your Deliveries with Mystio  
        </h1>
        <p className="mb-8 text-lg text-gray-400 leading-relaxed">
        Join the ranks of satisfied customers who have made Mystio their preferred delivery partner. Experience the difference with our top-tier service and see why Mystio is the name you can trust for all your delivery needs.

        </p>
      
      </div>
   </Fade>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
             <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        {/* <SwiperSlide>
    {({isActive})=>(
          <Fade
          duration={3000}
          cascade
          when={isActive}
          distance={"2rem"}
          >
          <img
             className="object-cover object-center rounded"
             alt="hero"
             src={require("../assets/downloadfile-1.jpg")}
           />
          </Fade>
    )}
        </SwiperSlide> */}
        <SwiperSlide>
   {({isActive})=>(
         <Fade
         duration={3000}
         cascade
         when={isActive}
         distance={"2rem"}
         >
         <img
            className="object-cover object-center rounded"
            alt="hero"
            src={require("../assets/mystio.png")}
          />
         </Fade>
   )}
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  <div className='bg-black p-8'>
  <h1 className="title-font sm:text-4xl text-center text-3xl mb-12 mt-4 font-semibold text-white bg-black">
    Mystio– Delivering Excellence, One Package at a Time.
        </h1>
  </div>
  </section>
  )
}
