import {
  IconButton,
  Typography,
  rating,
  Popover,
  PopoverHandler,
  PopoverContent,
} from "@material-tailwind/react";
import React from "react";
import { HeartIcon, ShareIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid";
import {
  FilmIcon,
  MusicalNoteIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SharePopover from "./SharePopover";
import { getYear, returnSubCategoryName, toFixed } from "../helper/helper";
import FavouriteBtn from "./FavouriteBtn";
import { DetailPageUrl } from "../services/API";
export default function DetailBanner2({
  views = 1,
  rating = "4.0",
  type = "movie",
  description,
  image,
  title,
  author,
  sub_category = [],
  edition = "",
  released_date,
  publish_date,
  pages = 102,
  onClickBuy,
  onWishClick,
  onPlayClick,
  isPurchased = false,
  isAudioBook = false,
  price,
  is_favourite,
  isPlayerOpen,
  id,
  onFeaturedClick,
  feature_video
}) {
  let subCategoryString = returnSubCategoryName(sub_category);
  const isMusic = type === "Audio";
  const isMovie = type === "Vedio" || type === "season";
  const isEbook = type === "ebook" || type === "Book";
  // const isAudioBook = type === "audio_book";
  const typeName = `${
    isAudioBook
      ? "Audio Book"
      : isEbook
      ? "Book"
      : isMusic
      ? "Music"
      : isMovie
      ? "Movie"
      : "Season"
  }`;
  const navigate = useNavigate();
  const onAddToCart = () => {
    // navigate("/AddToCart");
  };
  const onListen = () => {
    onPlayClick();
  };
  const onRead = () => {
    navigate("/Ebook");
  };
  const onFirstBtnPress = () => {
    !isPurchased ? onClickBuy() : isAudioBook ? onListen() : onRead();
  };

  return (
    <section
      id="Detail"
      className=" bg-white  mx-auto  px-4 sm:px-6 md:px-4 lg:px-14 py-4 sm:py-14 shadow-md"
    >
      <div className=" mx-auto justify-end  px-4  sm:px-6  lg:px-8 flex gap-10 md:gap-20 lg:flex-justify lg:flex  flex-col-reverse lg:flex-row-reverse">
        <div className="sm:text-center lg:text-left lg:w-1/3 lg:flex lg:flex-col lg:justify-center">
          <h2 className="text-2xl lg:text-4xl leading-10 lg:leading-[2.9rem] text-center md:text-left text-black  font-medium ">
            {title}
          </h2>
          <div className="mt-4   items-center justify-center md:justify-start ">
            <Typography
              color="blue-gray"
              className="flex items-center  justify-center text-center md:justify-start gap-1.5 font-normal text-xs md:text-lg "
            >
              <BookOpenIcon className=" h-5 w-5 text-secondary" />
              {`${typeName}  |`}
              {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="-mt-0.5 h-4 w-4 text-secondary"
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                  clipRule="evenodd"
                />
              </svg> */}
              <EyeIcon className="h-4 w-4 md:h-6 md:w-6 text-secondary" />
              {`${views}  `}
              {publish_date && `| ${getYear(publish_date)}`}
            </Typography>
          </div>

          <div className="">
            <p className=" text-gray-600 line-clamp-3 text-base md:text-lg mt-4 text-center md:text-left ">
              {description ||
                "An intelligence operative for a shadowy global peacekeeping agency races to stop a hacker from stealing its most valuable — and dangerous — weapon. races to stop a hacker from stealing its most valuable — and dangerous — weapon."}
            </p>
          </div>

          <Typography className="text-center text-gray-800 md:text-left mt-5 gap-1.5 font-normal text-base md:text-base line-clamp-3   ">
            {"By"} : {author}
          </Typography>
          {!isPurchased && (
            <Typography
              color="blue-gray"
              className="text-center md:text-left mt-2 gap-1.5 font-normal text-base md:text-base line-clamp-3   "
            >
              {"Price"} : $ {toFixed(price)}
            </Typography>
          )}
          <Typography className="text-center md:text-left mt-2 gap-1.5 font-semibold text-base text-secondary md:text-base line-clamp-2   ">
            {subCategoryString}
          </Typography>
          <div className="mt-10 gap-6 flex flex-col-reverse sm:flex-row justify-center md:justify-start">
            <button
              onClick={onFirstBtnPress}
              className="w-full sm:min-w-40 sm:max-w-[14rem] px-4 py-3 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
              {!isPurchased ? "Buy and " : ""}
              {isPlayerOpen ? "Pause" : isAudioBook ? "Listen" : "Read"}
            
            </button>
            { feature_video&&  <button
              onClick={onFeaturedClick}
              className="w-full sm:min-w-40 sm:max-w-[14rem] px-4 py-3 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
           Watch Promo 
            </button>}
            <div className="mt-2 md:mt-0 gap-6 flex  sm:flex-row justify-center  md:justify-start md:items-center">
              <FavouriteBtn is_favourite={is_favourite} onClick={onWishClick} />
              <SharePopover link={`${DetailPageUrl}?id=${id}`} />
            </div>
          </div>
        </div>
        {/*   Image Section     */}
        <div className="lg:inset-y-0 relative  lg:right-0  ">
          <img
            className="h-56 w-full object-contain sm:h-72 md:h-96  lg:w-full lg:h-full lg:max-h-[27rem] "
            src={
              image ||
              "https://ntvb.tmsimg.com/assets/p7825626_v_h10_aa.jpg?w=1280&h=720"
            }
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
