import { Button } from "@material-tailwind/react";
import React from "react";

export default function Pagination({
  totalItemsCount,
  currentPage,
  lastPage,
  perPageCount,
  onBtnClick,
}) {
  const onNextClick = () => onBtnClick(currentPage + 1);
  const onPreviousClick = () => onBtnClick(currentPage - 1);
  const previousDisabled = currentPage <= 1;
  const nextDisabled = currentPage === lastPage;
  return (
    <div className="w-full flex flex-col justify-center items-center py-8 mt-5  ">
      <div className="inline-flex items-center justify-center gap-3">
        <button
          onClick={onPreviousClick}
          disabled={previousDisabled}
          // type="button"
          className={`inline-flex h-10 w-10 items-center justify-center rounded border ${
            previousDisabled ? "opacity-[0.5]" : "opacity-[1]"
          } border-gray-100 bg-secondary text-white rtl:rotate-180`}
        >
          {/* <span className="sr-only">Next Page</span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>

        <h3 className="text-sm  font-semibold text-black">
          {currentPage}
          <span className="mx-0.25">/</span>
          {lastPage}
        </h3>

        <button
          onClick={onNextClick}
          disabled={nextDisabled}
          // type="button"
          className={`inline-flex h-10 w-10 items-center justify-center rounded border border-gray-100 bg-secondary ${
            nextDisabled ? "opacity-[0.5]" : "opacity-[1]"
          } text-white rtl:rotate-180`}
        >
          {/* <span className="sr-only">Next Page</span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-white"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
