import { delay, put, takeLatest, call } from "redux-saga/effects";
import { updateHome } from "../Reducers/homeReducer";
import { updateItem } from "../Reducers/itemReducer";
import {
  GetItemDetail,
  GetHomeData,
  GetItemsByCategory,
  ViewItemAction,
  GetBuyItemsAction,
  GetSimilarItems,
} from "../Actions/action";
import * as homeService from "../../services/homeService";
import * as itemService from "../../services/itemService";
import * as authService from "../../services/authService";
import { onError } from "../../helper/helper";
function* viewItemSaga(action) {
  const { id } = action?.payload || {};
  try {
    const { ok, data, originalError } = yield call(itemService.viewItem, {
      id,
    });
    if (ok) {
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
    }
  } catch (error) {
    // console.log("error-----------", error);
  }
}
function* getItemDetailSaga(action) {
  yield put(
    updateItem({
      itemLoading: true,
    })
  );
  const { id, token, user_id } = action?.payload || {};
  // console.log("user_id----", user_id);
  try {
    let { ok, data, originalError } = yield call(itemService.getItemDetail, {
      id,
      user_id: user_id || false,
    });
    if (ok) {
      const { data: itemDetail } = data || {};
      let is_purchased = false;
      let is_favourite = false;
      if (token) {
        let { ok: orderOk, data: orderAPIData } = yield call(
          itemService.getBuyItems,
          { stopLoading: true, pagination: 0 }
        );
        // console.log("buy items ----------------------", orderAPIData);
        let { ok: favouriteOk, data: favouriteAPIData } = yield call(
          authService.getWishListItem,
          { stopLoading: true }
        );
        const { data: orders } = orderAPIData || {};
        const { data: favouritesItems } = favouriteAPIData || {};
        if (orderOk) {
          is_purchased = Boolean(
            orders.find((item) => item?.product_details?.id === itemDetail?.id)
          );
        }
        if (favouriteOk) {
          is_favourite = Boolean(
            favouritesItems.find((item) => item?.product?.id === itemDetail?.id)
          );
        }
      }
      yield put(
        updateItem({
          itemDetail: { ...itemDetail, is_purchased, is_favourite },
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      // if (errorMsg) showError(message);
      // else showError(apiResponse(message));
    }
  } catch (error) {
    console.log("error-----------", error);
  } finally {
    yield put(
      updateItem({
        itemLoading: false,
      })
    );
  }
}
function* getHomeDataSaga(action) {
  try {
    const { ok, data, originalError } = yield call(homeService.trendingItems);
    // console.log("originalError------", originalError);

    if (ok) {
      const { data: trendingItems } = data || {};
      // console.log("ok------", trendingItems);
      yield put(
        updateHome({
          trendingItems,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
    }
  } catch (error) {
    // console.log("error-----------", error);
  }
}

function* getSimilarItemsSaga(action) {
  try {
    const { id } = action?.payload || {};
    yield put(
      updateItem({
        similarItemsLoading: true,
      })
    );
    const { ok, data, originalError } = yield call(
      itemService.similarProducts,
      { id }
    );
    if (ok) {
      const { data: similarItems } = data || {};
      yield put(
        updateItem({
          similarItems,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
    }
  } catch (error) {
    // console.log("error-----------", error);
  } finally {
    yield put(
      updateItem({
        similarItemsLoading: false,
      })
    );
  }
}
function* getBuyItemsSaga(action) {
  try {
    let { page } = action?.payload || {};
    const { ok, data, originalError, status } = yield call(
      itemService.getBuyItems,
      { page, pagination: 1 }
    );

    // console.log("buyItems------", data);
    if (ok) {
      const { data: buyItems, meta } = data || {};
      yield put(
        updateItem({
          buyItems,
          currentPage: meta?.current_page || 0,
          lastPage: meta?.last_page || 0,
          totalItemsCount: meta?.total || 0,
          perPageCount: meta?.per_page || 0,
        })
      );
    } else {
      if (status === 404) {
        yield put(
          updateItem({
            items: [],
          })
        );
        return;
      }
      onError(data, originalError);
    }
  } catch (error) {
    // console.log("error-----------", error);
  }
}

function* itemSaga() {
  yield takeLatest(ViewItemAction, viewItemSaga);
  yield takeLatest(GetItemDetail, getItemDetailSaga);
  yield takeLatest(GetHomeData, getHomeDataSaga);
  yield takeLatest(GetBuyItemsAction, getBuyItemsSaga);
  yield takeLatest(GetSimilarItems, getSimilarItemsSaga);
}

export default itemSaga;
