import React from "react";
import {
  ArrowRightOnRectangleIcon,
  UserIcon,
  ShoppingBagIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import { useReduxStore } from "../hooks/useReduxStore";
import { LogoutAction } from "../store/Actions/action";
import { scrollToTop } from "../helper/helper";
export default function ProfileMenuSection({
  heading = false,
  setSelectTab,
  selectedTab,
}) {
  const { dispatch } = useReduxStore();
  const navigate = useNavigate();
  const isProfile = selectedTab === "profile";
  const isMyItems = selectedTab === "my_items";
  const isWishlist = selectedTab === "wishlist";
  const onLogout = () => {
    navigate("/", { replace: true });
    dispatch(LogoutAction());
    scrollToTop();
  };
  return (
    <div className="bg-white">
      {heading && (
        <h4 className="block text-base md:text-2xl  font-medium text-black mb-4">
          Filters
        </h4>
      )}

      <ul>
        <li
          onClick={() => {
            setSelectTab("profile");
          }}
        >
          <a
            className={`${
              isProfile
                ? "flex items-center gap-2 border-s-[3px] border-secondaryColorLight bg-orange-50 px-4 py-3 text-secondary hover:cursor-pointer"
                : "flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 hover:cursor-pointer"
            }`}
          >
            <UserIcon className="h-6 w-6 " />
            <span className="text-sm font-medium"> Profile </span>
          </a>
        </li>
        <li
          onClick={() => {
            setSelectTab("my_items");
          }}
        >
          <a
            className={`${
              isMyItems
                ? "flex items-center gap-2 border-s-[3px] border-secondaryColorLight bg-orange-50 px-4 py-3 text-secondary hover:cursor-pointer"
                : "flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 hover:cursor-pointer"
            }`}
          >
            <ShoppingBagIcon className="h-6 w-6 " />
            <span className="text-sm font-medium "> My Items</span>
          </a>
        </li>
        <li
          onClick={() => {
            setSelectTab("wishlist");
          }}
        >
          <a
            className={`${
              isWishlist
                ? "flex items-center gap-2 border-s-[3px] border-secondaryColorLight bg-orange-50 px-4 py-3 text-secondary hover:cursor-pointer"
                : "flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 hover:cursor-pointer"
            }`}
          >
            <HeartIcon className="h-6 w-6 " />
            <span className="text-sm font-medium ">Wishlist</span>
          </a>
        </li>

        <li onClick={onLogout} className="hidden md:block">
          <a className="flex items-center gap-2 border-s-[3px] border-transparent px-4 py-3 text-gray-500 hover:border-gray-100 hover:bg-gray-50 hover:text-gray-700 hover:cursor-pointer">
            <ArrowRightOnRectangleIcon className="h-6 w-6 text-red-500" />
            <span className="text-sm font-medium text-red-500"> Logout </span>
          </a>
        </li>
      </ul>
    </div>
  );
}
