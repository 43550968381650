import React from "react";
import {
  Popover,
  PopoverHandler,
  PopoverContent,
  IconButton,
} from "@material-tailwind/react";
import Share from "./Share";
import { ShareIcon } from "@heroicons/react/24/outline";
export default function SharePopover({ isDark = true, link }) {
  return (
    <Popover>
      <PopoverHandler>
        {isDark ? (
          <IconButton
            // color="black"
            variant="outlined"
            className="rounded-full bg-white border-black border  text-black"
          >
            <ShareIcon className="h-6 w-6 text-black" />
          </IconButton>
        ) : (
          <IconButton
            color="white"
            variant="outlined"
            className="rounded-full bg-white border-white text-white"
          >
            <ShareIcon className="h-6 w-6 text-black" />
          </IconButton>
        )}
      </PopoverHandler>
      <PopoverContent className="z-50">
        <Share link={link}></Share>
      </PopoverContent>
    </Popover>
  );
}
