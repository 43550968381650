import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import Footer from "../../Components/Footer/Footer";
import AuthInput from "../../Components/AuthInput";
import { Link } from "react-router-dom";
import useLogin from "./useLogin";

const RECAPTCHA_SITE_KEY = "6Le84TsqAAAAACaoQneF1ELV6M8LtgExgrcHGdPl"; 

export default function Login() {
  const { register, handleSubmit, onSubmit, errors } = useLogin();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  // Callback for reCAPTCHA verification
  const handleCaptcha = (token) => {
    setCaptchaToken(token);
    setCaptchaError(false); // Reset any previous CAPTCHA error
  };

  // Function to handle form submission
  const handleFormSubmit = (data) => {
    if (!captchaToken) {
      setCaptchaError(true); // Show an error if CAPTCHA is not completed
      return;
    }

    onSubmit({ ...data, captchaToken });
  };

  return (
    <>
      <section className="min-h-screen flex items-stretch text-white">
        <div
          className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
          style={{
            backgroundImage: "url(https://image.hoopladigital.com/www/home/banner.jpg)",
          }}
        >
          <div className="absolute bg-black opacity-60 inset-0 z-0" />
          <div className="w-full px-24 z-10">
            <h1 className="text-5xl font-bold text-left tracking-wide">
              Keep it special
            </h1>
            <p className="text-3xl my-4">
              Instant access to millions of audiobooks, ebooks, Movies, audios,
              and more
            </p>
          </div>
        </div>
        <div
          className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-6 z-0"
          style={{ backgroundColor: "#161616" }}
        >
          <div className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
            style={{
              backgroundImage: "url(https://image.hoopladigital.com/www/home/banner.jpg)",
            }}
          >
            <div className="absolute bg-black opacity-60 inset-0 z-0" />
          </div>
          <div className="w-full py-6 z-20">
            <h1 className="my-6 text-3xl font-bold">
              Welcome To BAB Digital
            </h1>
            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
            >
              <AuthInput
                errors={errors}
                register={register}
                type="email"
                name="email"
                id="email"
                placeholder="Email"
              />
              <AuthInput
                errors={errors}
                register={register}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
              />
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleCaptcha}
                className="my-4"
              />
              {captchaError && (
                <div className="text-red-500">Please complete the reCAPTCHA</div>
              )}
              <div className="text-right text-gray-400 hover:underline py-2 hover:text-gray-100">
                <Link to="/ForgetPassword">Forgot your password?</Link>
              </div>
              <div className="px-4 pb-2 pt-4">
                {
                  !captchaToken ? 
                  <button
                  onClick={()=>setCaptchaError(true)}
                  
                  className={`uppercase block w-full p-4 text-lg rounded-full bg-secondary hover:bg-secondary/75'}`}
                 
                >
                  Sign In
                </button> : 
                 <button
                 
                 type="submit"
                 className={`uppercase block w-full p-4 text-lg rounded-full bg-secondary hover:bg-secondary/75`}
                
               >
                 Sign In
               </button> 
                }
                
              </div>
              <div className="mt-2 text-center text-gray-400 hover:underline hover:text-gray-100">
                <Link to="/Signup">Register Account</Link>
              </div>
              <p className="text-gray-100 py-3">OR</p>
              <div className="text-center text-gray-400 hover:underline hover:text-gray-100">
                <a href="https://admin.bab-media.com/public/login">
                  Become a Seller
                </a>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
