import APIRequest from "./APIRequest";

export const getItemDetail = ({ id, user_id }) => {
  let params = {
    stopLoading: true,
  };
  if (user_id) {
    params.user_id = user_id;
  }
  return APIRequest.get(`product/${id}?user_id=${user_id}`, params);
};
export const viewItem = ({ id }) => {
  return APIRequest.post(`products/${id}/view`, { stopLoading: true });
};

export const getItemsByCategory = ({ id }) => {
  return APIRequest.get(`products/category/${id}`);
};
export const similarProducts = ({ id }) => {
  return APIRequest.get(`products/category/${id}`, { stopLoading: true });
};
export const getBuyItems = ({ page, stopLoading = false, pagination = 0 }) => {
  return APIRequest.get(`orders?pagination=${pagination}&page=${page}`, {
    stopLoading,
    pagination,
  });
};
