import React from "react";
import SelectItemComponent from "./SelectItemComponent";
import { useReduxStore } from "../hooks/useReduxStore";
import { Chip } from "@material-tailwind/react";
export default function FilterSection({
  heading = true,
  selectedFilter = {},
  onSelect,
}) {
  const { getState } = useReduxStore();
  const { subCategories } = getState("Home");
  const releasesDateData = [
    {
      label: "7 Days",
      id: 1,
    },
    {
      label: "15 Days",
      id: 2,
    },
    {
      label: "30 Days",
      id: 3,
    },
    {
      label: "3 Months",
      id: 4,
    },
    {
      label: "6 Months",
      id: 5,
    },
    {
      label: "12 Months",
      id: 6,
    },
  ];
  const languageData = [
    {
      label: "English",
      id: 1,
    },
    {
      label: "French",
      id: 2,
    },
    {
      label: "Spanish",
      id: 3,
    },
    {
      label: "German",
      id: 4,
    },
    {
      label: "Korean",
      id: 5,
    },
    {
      label: "Hindi",
      id: 6,
    },
  ];
  const selectedValues = Object?.values(selectedFilter) || [];
  const selectedKeys = Object?.keys(selectedFilter) || [];
  return (
    <div>
      {heading && (
        <h3 className="block text-base md:text-4xl  font-bold text-black mb-10">
          Filters
        </h3>
      )}
      <div className="flex gap-3 flex-wrap mb-4">
        {selectedValues.map((item, i) => {
          return (
            item?.id && (
              <Chip
                className="bg-[#ff8c21]"
                value={item?.name || item?.label}
                onClose={() => {
                  onSelect({}, selectedKeys[i]);
                }}
              />
            )
          );
        })}
      </div>
      <div className="mt-1 space-y-2">
        <details
          open={true}
          className="overflow-hidden rounded bg-white border border-gray-300 [&_summary::-webkit-details-marker]:hidden"
        >
          <summary className="flex cursor-pointer  items-center justify-between gap-2 p-4 text-gray-900 transition">
            <span className="text-sm font-medium"> Category </span>

            <span className="transition group-open:-rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </summary>

          <div className="border-t border-gray-200 bg-white">
            {/* <header className="flex items-center justify-between p-4">
              <span className="text-sm text-gray-700"> 0 Selected </span>

              <button
                type="button"
                className="text-sm text-gray-900 underline underline-offset-4"
              >
                Reset
              </button>
            </header> */}

            <ul className="space-y-1 border-t border-gray-200 p-4 max-h-[12rem] overflow-y-scroll">
              {subCategories.map((item, i) => (
                <SelectItemComponent
                  onSelect={onSelect}
                  selected={Boolean(
                    selectedFilter["category"]?.id === item?.id
                  )}
                  title={item?.name}
                  filterType={"category"}
                  item={item}
                />
              ))}
            </ul>
          </div>
        </details>
        {/* <details className="overflow-hidden rounded bg-white border border-gray-300 [&_summary::-webkit-details-marker]:hidden">
          <summary className="flex cursor-pointer items-center justify-between gap-2 p-4 text-gray-900 transition">
            <span className="text-sm font-medium"> Rating </span>

            <span className="transition group-open:-rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </summary>
          <hr />
          <div class="space-y-1 overflow-y-auto pl-2 pr-1 py-4  ">
            <button
              class="flex flex-row justify-between p-1 cursor-default w-full rounded  hover:bg-gray-100 !cursor-pointer"
              aria-label="Apply 5 star rating filter. 29109 titles."
              aria-disabled="false"
            >
              <div class="select-none capitalize font-normal text-sm text-gray-800 flex items-center normal-case">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
              </div>
              <div class="text-sm text-secondary-interaction">29109</div>
            </button>
            <button
              class="flex flex-row justify-between p-1 cursor-default w-full rounded  hover:bg-gray-100 !cursor-pointer"
              aria-label="Apply 4 stars and up rating filter. 80426 titles."
              aria-disabled="false"
            >
              <div class="select-none capitalize font-normal text-sm text-gray-800 flex items-center normal-case">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                &amp; Up
              </div>
              <div class="text-sm text-secondary-interaction">80426</div>
            </button>
            <button
              class="flex flex-row justify-between p-1 cursor-default w-full rounded  hover:bg-gray-100 !cursor-pointer"
              aria-label="Apply 3 stars and up rating filter. 95607 titles."
              aria-disabled="false"
            >
              <div class="select-none capitalize font-normal text-sm text-gray-800 flex items-center normal-case">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                &amp; Up
              </div>
              <div class="text-sm text-secondary-interaction">95607</div>
            </button>
            <button
              class="flex flex-row justify-between p-1 cursor-default w-full rounded  hover:bg-gray-100 !cursor-pointer"
              aria-label="Apply 2 stars and up rating filter. 99463 titles."
              aria-disabled="false"
            >
              <div class="select-none capitalize font-normal text-sm text-gray-800 flex items-center normal-case">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                &amp; Up
              </div>
              <div class="text-sm text-secondary-interaction">99463</div>
            </button>
            <button
              class="flex flex-row justify-between p-1 cursor-default w-full rounded  hover:bg-gray-100 !cursor-pointer"
              aria-label="Apply 1 stars and up rating filter. 101816 titles."
              aria-disabled="false"
            >
              <div class="select-none capitalize font-normal text-sm text-gray-800 flex items-center normal-case">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="fill-secondary"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"></path>
                  </svg>
                </div>
                &amp; Up
              </div>
              <div class="text-sm text-secondary-interaction">101816</div>
            </button>
          </div>
        </details> */}
        <details
          open={true}
          className="overflow-hidden rounded border bg-white border-gray-300 [&_summary::-webkit-details-marker]:hidden"
        >
          <summary className="flex cursor-pointer items-center justify-between gap-2 p-4 text-gray-900 transition">
            <span className="text-sm font-medium"> Realese Date </span>

            <span className="transition group-open:-rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </summary>

          <div className="border-t border-gray-200 bg-white">
            {/* <header className="flex items-center justify-between p-4">
              <span className="text-sm text-gray-700"> 0 Selected </span>

              <button
                type="button"
                className="text-sm text-gray-900 underline underline-offset-4"
              >
                Reset
              </button>
            </header> */}

            <ul className="space-y-1 border-t border-gray-200 p-4">
              {releasesDateData.map((item, i) => (
                <SelectItemComponent
                  onSelect={onSelect}
                  selected={selectedFilter["releasedDate"]?.id === item?.id}
                  title={`Last ${item?.label}`}
                  filterType={"releasedDate"}
                  item={item}
                />
              ))}
            </ul>
          </div>
        </details>
        {/* <details
          open={true}
          className="overflow-hidden rounded border bg-white border-gray-300 [&_summary::-webkit-details-marker]:hidden"
        >
          <summary className="flex cursor-pointer items-center justify-between gap-2 p-4 text-gray-900 transition">
            <span className="text-sm font-medium"> Language </span>

            <span className="transition group-open:-rotate-180">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-4 w-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </span>
          </summary>

          <div className="border-t border-gray-200 bg-white">
            <ul className="space-y-1 border-t border-gray-200 p-4 max-h-[13rem] ">
              {languageData.map((item, i) => (
                <SelectItemComponent
                  onSelect={onSelect}
                  selected={selectedFilter["language"]?.id === item?.id}
                  title={item?.label}
                  filterType={"language"}
                  item={item}
                />
              ))}
            </ul>
          </div>
        </details> */}
      </div>
    </div>
  );
}
