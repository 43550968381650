import React, { useEffect, useState, useRef } from "react";
import { useReduxStore } from "../../hooks/useReduxStore";
import {
  GetItemDetail,
  GetSimilarItems,
  ToggleWishlistItemAction,
  ViewItemAction,
} from "../../store/Actions/action";
import { updateItem } from "../../store/Reducers/itemReducer";
import { useNavigate } from "react-router-dom";
import { updateAuth } from "../../store/Reducers/authReducer";
import { useMemo } from "react";
import useSetWidth from "../../hooks/useSetWidth";
import { useSearchParams } from "react-router-dom";
const useDetail = () => {
  const playerRef = useRef(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const navigate = useNavigate();
  const { dispatch, getState } = useReduxStore();
  const { itemDetail, similarItems, similarItemsLoading, itemLoading } =
    getState("Item");
  const { loading, token, userData } = getState("AUTH");
  const { width } = getState("Home");
  const [open, setOpen] = useState(false);
  const [featuredVideoModal, setFeaturedVideoModal] = useState(false);
  const [isPlayerOpen, setIsPlayerOpen] = useState(false);
  const [loginModal, setLoginMdal] = useState(false);
  let data = window?.history?.state?.usr || itemDetail || {};
  const similarItemsData = useMemo(() => {
    return similarItems.filter((item) => item?.id !== itemDetail.id);
  }, [similarItems]);
  const handleOpen = () => setOpen(!open);
  const handleLoginModal = () => setLoginMdal(!loginModal);
  const onLoginClick = () =>
    navigate("/Login", { state: { route: "/Detail" } });
    const toggleFaturedVideoModal = () => setFeaturedVideoModal(!featuredVideoModal);
  const getItemDetail = () => {
    dispatch(
      GetItemDetail({
        id: id || data?.id,
        token,
        user_id: userData?.id,
      })
    );
  };
  const togglePlayerOpen = () => {
    setIsPlayerOpen(!isPlayerOpen);
    let pause = playerRef.current.audio.current.paused;
    pause
      ? playerRef.current.audio.current.play()
      : playerRef.current.audio.current.pause();
  };
  const viewItem = () => {
    dispatch(ViewItemAction({ id: data?.id || 1 }));
  };
  const getSimilarItems = (id) => {
    dispatch(GetSimilarItems({ id }));
  };
  const onClickBuy = () => {
    token ? navigate("/Checkout") : handleLoginModal();
  };
  const onUnMount = () => {
    dispatch(updateItem({ itemDetail: {} }));
  };

  const onWishClick = () => {
    token
      ? dispatch(
          ToggleWishlistItemAction({
            id: data?.id || 1,
            itemDetail: {
              ...itemDetail,
              is_favourite: !itemDetail.is_favourite,
            },
          })
        )
      : handleLoginModal();
  };
  useEffect(() => {
    getItemDetail();
    viewItem();
  }, []);
  useEffect(() => {
    itemDetail?.category && getSimilarItems(itemDetail?.category[0]?.id);
  }, [itemDetail?.id]);
  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);
  // useEffect(() => {
  //   return () => {
  //     onUnMount();
  //   };
  // }, []);

  return {
    itemDetail,
    similarItems,
    similarItemsData,
    similarItemsLoading,
    data,
    open,
    itemLoading,
    playerRef,
    featuredVideoModal,
    toggleFaturedVideoModal,
    handleOpen,
    loading,
    onClickBuy,
    onWishClick,
    loginModal,
    handleLoginModal,
    onLoginClick,
    isPlayerOpen,
    setIsPlayerOpen,
    togglePlayerOpen,
    width,
  };
};

export default useDetail;
