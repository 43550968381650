import APIRequest from "./APIRequest";

export const getWishListItem = ({ stopLoading = false }) => {
  return APIRequest.get(`favorites`, { stopLoading });
};
export const toggleWisthListItem = ({ id }) => {
  return APIRequest.post(`favorite/toggle?product_id=${id}`, {
    stopLoading: true,
  });
};
export const register = (params) => {
  return APIRequest.post(`signup`, params);
};
export const VerifyUser = (params) => {
  return APIRequest.post(`verify-otp`, params);
};
export const ReSendOTP = (params) => {
  return APIRequest.post(`resend-otp`, params);
};
export const editBasicInfo = (params) => {
  return APIRequest.post(`edit/basic`, params);
};
export const changePassword = (params) => {
  return APIRequest.post(`change/password`, params);
};
export const login = (params) => {
  return APIRequest.post(`login`, params);
};
export const logout = () => {
  return APIRequest.post(`logout`);
};
export const resetPasswordRequest = (params) => {
  return APIRequest.post(`password/reset/request`, params);
};
export const resetPassword = (params) => {
  return APIRequest.post(`password/reset`, params);
};
