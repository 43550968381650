import { createSlice } from "@reduxjs/toolkit";
const initial_state = {
  similarItems: [],
  items: [],
  itemDetail: {},
  buyItems: [],
  currentPage: 1,
  lastPage: 0,
  totalItemsCount: 0,
  perPageCount: 0,
  similarItemsLoading: false,
  search: "",
  searchModal: false,
  itemLoading: false,
  emailSent: false,
};

const itemReducer = createSlice({
  name: "Item",
  initialState: initial_state,
  reducers: {
    updateItem: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { updateItem } = itemReducer.actions;
export default itemReducer.reducer;
