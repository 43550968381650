import React from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
export default function SelectBoxSimple({
  label,
  labelClassName = "",
  labelColor,
  data = [],
  onItemClick,
  selected,
  className = "",
  variant = "text",
  color = "",
}) {
  const [openMenu, setOpenMenu] = React.useState(false);
  return (
    <Menu open={openMenu} handler={setOpenMenu} allowHover>
      <MenuHandler>
        <Button
          color={color}
          variant={variant}
          className={`flex py-[0.5rem] px-5 items-center gap-3 text-xs md:text-base font-normal capitalize tracking-normal border-2  border-gray-300 ${
            labelColor || ""
          } ${labelClassName} `}
        >
          <p className="line-clamp-1"> {selected?.name || label}</p>
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openMenu ? "rotate-180" : ""
            } ${labelColor || ""}`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="z-[9999999] !z-[9999999]">
        {data.map((item) => (
          <MenuItem onClick={() => onItemClick(item)}>
            {item?.label || item?.name}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
