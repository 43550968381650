import "./App.css";
import Home from "./Pages/Home/Home";
import Landing from "./Pages/Landing/Landing";
import Login from "./Pages/Login/Login";
import Signup from "./Pages/Signup/Signup";
import Checkout from "./Pages/Checkout/Checkout";
import Detail from "./Pages/Detail/Detail";
import ProductByCategory from "./Pages/ProductByCategory/ProductByCategory";
import Vendor from "./Pages/Vendor/Vendor";
import AddToCart2 from "./Pages/AddToCart2/AddToCart2";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Profile from "./Pages/Profile/Profile";
import ChangePassword from "./Pages/ChangePassword/ChangePassword";
import ForgetPassword from "./Pages/ForgetPassword/ForgetPassword";
// import PDFReader from "./Pages/PDFReader/PDFReader";
import PDFReader from "./Pages/PDFReader2/PDFReader";
// import PDFReader from "./Pages/PDFReader3/PDFReader";
import OTP from "./Pages/OTP/OTP";
import About from "./Pages/About/index";
import Contact from "./Pages/Contact/Contact";
import FAQ from "./Pages/FAQ/FAQ";
import TermsAndConditionPolicy from "./Pages/TermsAndConditionPolicy/index";
import VedioPlayer from "./Pages/VedioPlayer/VedioPlayer";
import Article from "./Pages/Article/Article";
import FraudPolicy from "./Pages/FraudPolicy/index";
import {
  HashRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./Components/ScrollToTop";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PageNotFound from "./Pages/PageNotFound";
import Loader from "./Components/Loader";
import { useReduxStore } from "./hooks/useReduxStore";
import { ToastContainer, toast } from "react-toastify";
import useSetWidth from "./hooks/useSetWidth";
import SearchModal from "./Components/SearchModal";
import { stripeAPIKey } from "./Constant/Data";

const stripePromise = loadStripe(stripeAPIKey);
function App() {
  useSetWidth();
  const { getState } = useReduxStore();
  const { loading, token } = getState("AUTH");
  const { searchModal, itemDetail } = getState("Item");

  return (
    <Elements stripe={stripePromise}>
      <Router future={{ v7_startTransition: true }}>
        <ScrollToTop />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
        <ToastContainer />

        {loading && <Loader />}
        {searchModal && <SearchModal />}
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/Signup" element={<Signup />}></Route>
          <Route path="/Login" element={<Login />}></Route>
          <Route
            path="/Checkout"
            element={
              token && itemDetail?.id ? (
                <Checkout />
              ) : (
                <Navigate to="/" replace />
              )
            }
          ></Route>
          <Route path="/AddToCart" element={<AddToCart2 />}></Route>
          <Route path="/Detail" element={<Detail />}></Route>
          <Route
            path="/Vedio"
            element={
              token && itemDetail?.is_purchased ? (
                <VedioPlayer />
              ) : (
                <Navigate to="/" replace />
              )
            }
          ></Route>
          {
            <Route
              path="/Ebook"
              element={
                token && itemDetail?.is_purchased ? (
                  <PDFReader />
                ) : (
                  <Navigate to={"/"} replace />
                )
              }
            ></Route>
          }
          <Route path="/AddToCart2" element={<AddToCart2 />}></Route>
          <Route path="/Vendor" element={<Vendor />}></Route>
          <Route
            path="/ProductCollection"
            element={<ProductByCategory />}
          ></Route>
          <Route
            path="/Profile"
            element={token ? <Profile /> : <Navigate to="/" replace />}
          ></Route>
          <Route path="/Policy" element={<PrivacyPolicy />}></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/FAQ" element={<FAQ />}></Route>
          <Route path="/Article" element={<Article />}></Route>
          <Route
            path="/TermsCondition"
            element={<TermsAndConditionPolicy />}
          ></Route>
          <Route path="/FraudPolicy" element={<FraudPolicy />}></Route>
          <Route
            path="/ChangePassword/:token"
            element={<ChangePassword />}
          ></Route>
          <Route path="/ForgetPassword" element={<ForgetPassword />}></Route>
          <Route path="/OTP" element={<OTP />}></Route>
          {/* <Route path="/Rating" element={<RatingPage />}></Route> */}
          <Route path="/*" element={<PageNotFound />}></Route>
        </Routes>
      </Router>
    </Elements>
  );
}

export default App;
