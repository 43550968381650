import { Carousel } from '@material-tailwind/react';
import React from 'react'
import { Fade,Flip,Slide } from "react-reveal";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
export default function LandingPageBanner() {
  return (
    <div className="bg-black h-[100vh] py-20 ">
    <div className="container mx-auto px-6 md:px-12">
      <div className="flex mr-4 sm:mr-0 flex-col md:flex-row items-center">
        <div className="md:w-1/2 lg:w-2/3">
       <Fade
          duration={1200}
          distance={'6rem'}
          top
          cascade
          
       >
          <h1 className="text-4xl md:text-6xl lg:text-7xl text-white font-bold mb-6">
            Welcome to  <br className="hidden md:block" />
            <span className="text-secondary">B.A.B Soul Flow </span>Records 
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl text-gray-400 mb-8">
          We are dedicated to bringing your music to life. 
          </p>
          </Fade>
        
        </div>
        <div className="md:w-1/2 lg:w-1/3 mt-8 md:mt-0 w-full">
        <Swiper
        spaceBetween={10}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          {({isActive})=>(
              <Fade
              duration={900}
              distance={'2rem'}
              right
              cascade
              when={isActive}
              
           >
           <img
                src={require("../assets/Picture1.png")}
                alt="Hero Image"
                className="rounded-lg shadow-lg "
              />
           </Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
   <Fade
   duration={900}
   distance={'2rem'}
   right
   cascade
   when={isActive}
>
<img
     src={require("../assets/Designer (22).png")}
     alt="Hero Image"
     className="rounded-lg shadow-lg "
   />
</Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
    <Fade
    duration={900}
    distance={'2rem'}
    right
    cascade
    when={isActive}   
   >
   <img
        src={require("../assets/downloadfile.jpg")}
        alt="Hero Image"
        className="rounded-lg shadow-lg "
      />
   </Fade>
          )}
        </SwiperSlide>
        </Swiper>
        {/* <Carousel
         
      loop
      autoplay
      transition={{ duration: 2 ,type:"tween"}}
      className="h-[15rem] 2xl:h-[32rem] xl:h-[32rem]lg:h-[32rem] md:h-[32rem] "
    > 
       <Fade
          duration={900}
          distance={'2rem'}
          right
          cascade
          
       >
       <img
            src={require("../assets/Picture1.png")}
            alt="Hero Image"
            className="rounded-lg shadow-lg "
          />
       </Fade>
       <Fade
          duration={900}
          distance={'2rem'}
          right
          cascade
          
       >
       <img
            src={require("../assets/Designer (22).png")}
            alt="Hero Image"
            className="rounded-lg shadow-lg "
          />
       </Fade>
       <Fade
        duration={900}
        distance={'2rem'}
        right
        cascade
          
       >
       <img
            src={require("../assets/downloadfile.jpg")}
            alt="Hero Image"
            className="rounded-lg shadow-lg "
          />
       </Fade>
       </Carousel> */}
        </div>
      </div>
    </div>
  </div>
  )
}
