import React from 'react'
import { Fade,Flip,Slide,Zoom,Bounce } from "react-reveal";

export default function SoulFlowServiceSection() {
  return (
    <div className="px-4 w-full  bg-black relative py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-full md:px-24 lg:px-28 lg:py-28">
  
    <div className="max-w-xl mx-2 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-24">
      <div>
        <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-sky-500 text-white">
        </p>
      </div>
    <Fade
    duration={1000}
    distance={'6rem'}
    top
    >
    <h2 className="max-w-lg mb-8 text-center font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-5xl md:mx-auto">
        <span className="relative inline-block">
          <svg
            viewBox="0 0 52 24"
            fill="currentColor"
            className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-slate-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
          >
            <defs>
              <pattern
                id="fdca20a0-aeb4-4caf-ba1b-4351eee42363"
                x={0}
                y={0}
                width=".135"
                height=".30"
              >
                <circle cx={1} cy={1} r=".7" />
              </pattern>
            </defs>
            <rect
              fill="url(#fdca20a0-aeb4-4caf-ba1b-4351eee42363)"
              width={52}
              height={24}
            />
          </svg>
          <span className="relative mr-2 invisible">"</span>
        </span>
        
      Our Services  
        <br/>
     
      </h2>
      <p className="text-base text-center text-gray-300 md:text-lg">
      We offer a comprehensive range of services to support artists in their musical journey.
      </p>
    </Fade>
    </div>
  <Zoom
    duration={1600}
    cascade
  >
  <div className="grid mx-2 max-w-md gap-10 row-gap-10 sm:mx-auto lg:max-w-full lg:grid-cols-4">
      <div className="flex  flex-col sm:flex-row md:flex-col  ">
        <div className="sm:mr-4 mb-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-green-50">
            <svg
              className="w-12 h-12 text-secondary"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </div>
        <div>
          <h6 className="mb-5 font-semibold text-white text-2xl leading-5">Music Production:</h6>
          <p className="mb-3 text-md text-gray-500">
          Our experienced team offers top-notch music production services, from recording to mastering, to help artists bring their musical vision to life
          </p>
   
        </div>
      </div>
      <div className="flex flex-col sm:flex-row md:flex-col">
        <div className="sm:mr-4 mb-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-green-50">
            <svg
              className="w-12 h-12 text-secondary"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </div>
        <div>
          <h6 className="mb-5 font-semibold text-white text-2xl leading-5">
          Recording Services:
          </h6>
          <p className="mb-3 text-md text-gray-500">
          Step into our state-of-the-art recording studio, equipped with the latest technology, for a high-quality recording experience that captures the essence of your music. 
          </p>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row md:flex-col ">
        <div className="sm:mr-4 mb-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-green-50">
            <svg
              className="w-12 h-12 text-secondary"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </div>
        <div>
          <h6 className="mb-5 font-semibold text-white text-2xl leading-5">
          Artist Management:
          </h6>
          <p className="mb-3 text-md text-gray-500">
          Our dedicated artist management team provides personalized guidance and support to help artists navigate the music industry and reach their full potential.
          </p>
        </div>
  
      </div>
      <div className="flex flex-col sm:flex-row md:flex-col">
        <div className="sm:mr-4 mb-2">
          <div className="flex items-center justify-center w-14 h-14 mb-4 rounded-full bg-green-50">
            <svg
              className="w-12 h-12 text-secondary"
              stroke="currentColor"
              viewBox="0 0 52 52"
            >
              <polygon
                strokeWidth={3}
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
                points="29 13 14 29 25 29 23 39 38 23 27 23"
              />
            </svg>
          </div>
        </div>
        <div>
          <h6 className="mb-5 font-semibold text-white text-2xl leading-5">Publishing and distribution</h6>
          <p className="mb-3 text-md text-gray-500">
          Utilize our platform for publishing and distribution services to showcase your music to a wider audience and make your mark in the industry. 
          </p>
        </div>
      </div>
    </div>

  </Zoom>
    <div
        className="absolute bottom-0 right-0 z-0 h-full w-full"
        style={{
          backgroundImage:
            "linear-gradient(to left bottom, rgba(220, 38, 38, 0.2) 0%, transparent 50%, transparent 100%)",
          borderColor: "rgba(92, 79, 240, 0.2)"
        }}
      ></div>
  </div>
  )
}
