import React from "react";
import { IconButton } from "@material-tailwind/react";
import { HeartIcon, ShareIcon } from "@heroicons/react/24/outline";

export default function FavouriteBtn({ is_favourite, onClick, isLight }) {
  return is_favourite ? (
    <IconButton
      ripple
      color="red"
      className={`rounded-full !bg-red-700`}
      onClick={onClick}
    >
      <HeartIcon className="h-6 w-6 text-white   " />
    </IconButton>
  ) : isLight ? (
    <IconButton
      ripple
      color="white"
      variant="outlined"
      className="rounded-full bg-white border-white text-white"
      onClick={onClick}
    >
      <HeartIcon className="h-6 w-6 text-black" />
    </IconButton>
  ) : (
    <IconButton
      ripple
      color="black"
      variant={"outlined"}
      className={`rounded-full 
        bg-white
       border-black  text-black`}
      onClick={onClick}
    >
      <HeartIcon className="h-6 w-6 text-black" />
    </IconButton>
  );
}
