import LandingPageBanner from "../../Components/LandingPageBanner.jsx";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
// import Swiper core and required modules
import SwiperCore from 'swiper'
import { Navigation, Pagination ,HashNavigation,Mousewheel ,EffectFade} from 'swiper/modules';
import { useReduxStore } from "../../hooks/useReduxStore.js";
import BABSoulFlowFooter from "../../Components/BABSoulFlowFooter.jsx";
import { BABSoulFlowHeader } from "../../Components/BABSoulFlowHeader.js";
import bgImage from '../../assets/mystio3.png'
SwiperCore.use([Mousewheel, Pagination, Navigation,HashNavigation])

export default function Article() {
  const { getState, dispatch } = useReduxStore();
  const {
    categoriesItems,
  } = getState("Home");
  return (
    <>
    <BABSoulFlowHeader />
    <div className=" center bg-black">
    <div className="flex flex-wrap">
  <div className="w-full sm:w-8/12 mb-10 pt-8">
    <div className="container mx-auto h-full sm:p-10">
     
        <div className="w-full">
          <h1 className="text-4xl text-white lg:text-6xl font-bold">
            Find your <span className="text-secondary">greeny</span> stuff for
            your room
          </h1>
          <div className="w-20 h-2 bg-secondary my-4" />
          <p className="text-xl text-white mb-10">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Recusandae
            maiores neque eaque ea odit placeat, tenetur illum distinctio nulla
            voluptatum a corrupti beatae tempora aperiam quia id aliquam
            possimus aut.
          </p>
          <button className="bg-secondary text-white text-2xl font-medium px-4 py-2 rounded shadow">
            Learn More
          </button>
        </div>
    </div>
  </div>
  <img
    src={bgImage}
    alt="Leafs"
    className="w-full h-48 object-cover sm:h-screen sm:w-4/12"
  />
</div>

    <div className="w-full mx-auto p-5 sm:p-10 md:p-16 relative">
  <div className="max-w-3xl mx-auto">
    
    <div className="mt-3 bg-black rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
      <div className="bg-black relative top-0 mt-32 p-5 sm:p-10">
        <h1 href="#" className="text-secondary font-bold text-3xl mb-2">
          Revenge of the Never Trumpers
        </h1>
        <p className="text-gray-700 text-xs mt-2">
          Written By:
          <a
            href="#"
            className="text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
          >
            Ahmad Sultani
          </a>{" "}
          In
          <a
            href="#"
            className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
          >
            Election
          </a>
          ,
          <a
            href="#"
            className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
          >
            Politics
          </a>
        </p>
        <p className="text-gray-200 leading-8 my-5">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <h3 className="text-2xl text-secondary font-bold my-5">#1. What is Lorem Ipsum?</h3>
        <p className="text-gray-200 leading-8 my-5">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <blockquote className="border-l-4 text-gray-200 italic leading-8 my-5 p-5 text-secondary">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s
        </blockquote>
        <p className="text-gray-200 leading-8 my-5">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing Lorem Ipsum passages, and
          more recently with desktop publishing software like Aldus PageMaker
          including versions of Lorem Ipsum.
        </p>
        <a
          href="#"
          className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
        >
          #Election
        </a>
        ,
        <a
          href="#"
          className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
        >
          #people
        </a>
        ,
        <a
          href="#"
          className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
        >
          #Election2020
        </a>
        ,
        <a
          href="#"
          className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
        >
          #trump
        </a>
        ,
        <a
          href="#"
          className="text-xs text-secondary font-medium hover:text-white transition duration-500 ease-in-out"
        >
          #Joe
        </a>
      </div>
    </div>
  </div>
</div>

    </div>
    <BABSoulFlowFooter />
          </>
  );
}
