import React, { useRef } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
} from "@material-tailwind/react";
import {
  UserCircleIcon,
  ChevronDownIcon,
  PowerIcon,
  Bars2Icon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/solid";
import { bab_logo, bab_logo_short } from "../../assets/Images";
import { Link, useNavigate } from "react-router-dom";
import SelectBoxSimple from "../SelectBoxSimple.jsx";
import { useReduxStore } from "../../hooks/useReduxStore.js";
import { updateHome } from "../../store/Reducers/homeReducer.js";
import { LogoutAction } from "../../store/Actions/action.js";
import { updateItem } from "../../store/Reducers/itemReducer.js";
import { toast } from "react-toastify";
import { getFirstName, scrollToTop } from "../../helper/helper.js";
// profile menu component
const profileMenuItems = [
  {
    label: "My Account",
    icon: UserCircleIcon,
    route: "/Profile",
  },
  // {
  //   label: "My Cart",
  //   icon: ShoppingCartIcon,
  //   route: "/AddToCart",
  // },
  {
    label: "Sign Out",
    icon: PowerIcon,
    route: "/",
  },
];

function ProfileMenu({}) {
  const { dispatch, getState } = useReduxStore();
  const { userData } = getState("AUTH");
  const { name, email, avatar } = userData || {};
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate();
  const closeMenu = () => setIsMenuOpen(false);
  const handleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const onProfileMenuClick = (route, isLastItem) => {
    isLastItem && dispatch(LogoutAction());
    navigate(route);
    isLastItem && scrollToTop();
    closeMenu();
  };

  return (
    <Menu
      open={isMenuOpen}
      className="w-fit bg-white "
      handler={handleMenu}
      placement="bottom-end"
    >
      <MenuHandler>
        <Button
          variant="text"
          color="blue-gray"
          className="flex items-center gap-1 rounded-full py-0.5 pr-2 pl-0.5 "
        >
          <Avatar
            variant="circular"
            size="sm"
            alt="tania andrew"
            className="border border-white p-0.5"
            src={
              avatar ||
              "https://i.pinimg.com/originals/5f/40/6a/5f406ab25e8942cbe0da6485afd26b71.jpg"
            }
          />
          <small className="px-1 text-white">
            {getFirstName(userData?.name)}
          </small>
          <ChevronDownIcon
            strokeWidth={2.5}
            className={`h-3 w-3 transition-transform ${
              isMenuOpen ? "rotate-180" : ""
            }`}
          />
        </Button>
      </MenuHandler>
      <MenuList className="p-1 z-[9999999]">
        {profileMenuItems.map(({ label, icon, route }, key) => {
          const isLastItem = key === profileMenuItems.length - 1;
          return (
            <MenuItem
              key={label}
              onClick={() => onProfileMenuClick(route, isLastItem)}
              className={`flex items-center gap-2 rounded ${
                isLastItem
                  ? "hover:bg-white/50 focus:bg-white/50 active:bg-white/50"
                  : ""
              }`}
            >
              {React.createElement(icon, {
                className: `h-4 w-4 ${isLastItem ? "text-white" : ""}`,
                strokeWidth: 2,
              })}
              <Typography
                as="span"
                variant="small"
                className="font-normal"
                color={isLastItem ? "red" : "inherit"}
              >
                {label}
              </Typography>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}

function NavList({
  onCategoryPress,
  category,
  data = [],
  selectedCategory,
  toggleIsNavOpen,
}) {
  const navigate = useNavigate();
  const onNavBarItemPress = (selectedCategory) => {
    toggleIsNavOpen && toggleIsNavOpen();
    navigate("/ProductCollection", { state: {} });
    onCategoryPress && onCategoryPress(selectedCategory);
  };

  return (
    <ul className="mt-6 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center  ">
      {data.map((item, key) => {
        let { name, icon, type, id } = item;
        const isActive = id === selectedCategory?.id;
        return (
          <Typography
            key={name}
            variant="small"
            color="white"
            className="font-medium text-secondary "
          >
            <MenuItem
              onClick={() => onNavBarItemPress(item)}
              className={
                isActive
                  ? "flex items-center  bg-secondary focus:bg-secondary  active:bg-secondary lg:rounded-full hover:bg-secondary lg:px-4"
                  : "flex items-center   focus:bg-secondary  active:bg-secondary lg:rounded-full hover:bg-secondary lg:px-4"
              }
            >
              {/* {React.createElement(icon, { className: "h-[25px] w-[25px]" })}{" "} */}
              <span className="text-white text-base md:text-base ">
                {" "}
                {name}
              </span>
            </MenuItem>
          </Typography>
        );
      })}
    </ul>
    // <SelectBoxSimple
    //   onItemClick={onNavBarItemPress}
    //   data={data}
    //   labelColor={"text-white"}
    //   label={"Browse Category"}
    //   selected={selectedCategory}
    //   labelClassName="text-sm"
    // />
  );
}

export function Navbar2({ category }) {
  const navigate = useNavigate();
  const { getState, dispatch } = useReduxStore();
  const { categories, selectedCategory } = getState("Home");
  const { token } = getState("AUTH");
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
  const openSearchModal = () => dispatch(updateItem({ searchModal: true }));
  const onCategoryPress = (selectedCategory) => {
    dispatch(updateHome({ selectedCategory }));
  };
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);
  const onLogin = () => {
    navigate("/Login", { state: { goBack: true } });
  };
  return (
    <Navbar
      color="black"
      fullWidth
      className="mx-auto max-w-screen px-2 py-1 bg-black lg:px-6 md:py-2 sticky top-0 z-[9999] shadow-sm"
    >
      <div className="relative mx-auto flex items-center justify-between text-white">
        <Link to={"/"}>
          <img
            className=" object-contain object-center -ml-8  "
            src={bab_logo}
          />
        </Link>
        <div className="hidden mx-auto  lg:gap-6 lg:flex lg:flex-row items-center justify-center ">
          <NavList
            selectedCategory={selectedCategory}
            category={category}
            data={categories}
            onCategoryPress={onCategoryPress}
          />
          {/* <SearchBar
            selectedCategory={selectedCategory}
            category={category}
            data={categories}
            onCategoryPress={onCategoryPress}
          /> */}
        </div>

        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
        <IconButton
          size="sm"
          color="white"
          variant="text"
          onClick={openSearchModal}
          className=" md:ml-0 mr-2 "
        >
          <MagnifyingGlassIcon className="h-6 w-6 text-white" />
        </IconButton>
        {!token && (
          <Button
            onClick={onLogin}
            className="!text-xs line-clamp-1 !sm:text-sm"
            color="orange"
            size="sm"
            variant="outlined"
          >
            <span className="!text-xs line-clamp-1 !sm:text-sm">LogIn</span>
          </Button>
        )}
        {token && <ProfileMenu />}
      </div>
      <MobileNav
        open={isNavOpen}
        className="fixed top-18 bg-black w-full -ml-2 px-4"
      >
        <NavList
          toggleIsNavOpen={toggleIsNavOpen}
          selectedCategory={selectedCategory}
          data={categories}
          category={category}
          onCategoryPress={onCategoryPress}
        />
      </MobileNav>
    </Navbar>
  );
}
