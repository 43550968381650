import React from "react";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import { aboutContent } from "../../Constant/Data";
export default function About() {
  return (
    <>
      <Navbar2 />
      <div className="min-w-screen min-h-screen px-2 sm:px-5 lg:px-20 xl:px-32  py-8">
        <div className="container mx-auto p-4">
          <h1 className="text-3xl font-semibold text-center mb-8  ">About</h1>

          <div className=" p-6  rounded-lg mb-4">
            <p className="mb-2 leading-[2rem] text-center ">{aboutContent}</p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
