import { createAction } from "@reduxjs/toolkit";
export const RegisterAction = createAction("REGISTER");
export const EditBasicInfoAction = createAction("EDIT_BASIC_INFO");
export const ChangePasswordAction = createAction("CHANGE_PASSWORD_ACTION");
export const LoginAction = createAction("LOGIN");
export const LogoutAction = createAction("LOGOUT");
export const OTPAction = createAction("OTP");
export const ResendPasswordAction = createAction("ResendPassword");
export const resetPasswordRequestAction = createAction(
  "RESET_PASSWORD_REQUEST_ACTION"
);
export const resetPasswordAction = createAction("RESET_PASSWORD_ACTION");
export const ToggleWishlistItemAction = createAction("TOGGLE_WISHLIST_ITEM");
export const GetWishlistItemsAction = createAction("GET_WISHLIST_ITEMS");
// home actions---------------------
export const GetBanners = createAction("GET_BANNERS");
export const GetHomeData = createAction("GET_HOME_DATA");
export const GetItemsByCategory = createAction("GET_ITEMS_CATEGORY");
// item action----------------------
export const GetItemDetail = createAction("GET_ITEM_DETAIL");
export const GetSimilarItems = createAction("GET_SIMILAR_ITEMS");
export const ViewItemAction = createAction("VIEW_ITEM");
export const GetBuyItemsAction = createAction("GET_Buy_ITEMS");
