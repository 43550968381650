import { Typography } from "@material-tailwind/react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import HorizontalListCard from "./HorizontalListCard.jsx";
import HorizontalCardLoader from "./HorizontalCardLoader.jsx";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
export default function HorizontalList({
  title = "Title",
  data = [],
  type,
  loading,
  loadingData = [1, 1, 1, 1, 1, 1],
  isViewMore = false,
  onViewAll,
  item,
}) {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const onView = () => {
    onViewAll(item);
  };
  return (
    <div className="flex flex-col justify-center align-middle py-6 lg:py-12 px-1 md:px-1 ">
      <div className="flex w-full items-center justify-between    mb-0 md:mb-4  mx-auto md:px-1 px-[9px] ">
        <Typography
          variant="h3"
          className="text-xl font-semibold text-black lg:text-3xl"
          color="black"
        >
          {title}
        </Typography>
        {isViewMore && (
          <button className="flex items-center" onClick={onView}>
            <Typography
              className="flex text-sm md:text-base"
              variant="h5"
              color="orange"
            >
              {"View All"}
            </Typography>
            <ChevronDoubleRightIcon className="h-4 w-4 mt-0 md:h-5 mdw-5 ml-1 md:-mt-1 font-semibold text-secondary" />
          </button>
        )}
      </div>
      <Carousel
        infinite
        autoPlay={true}
        autoPlaySpeed={2000}
        transitionDuration={500}
        centerMode={false}
        additionalTransfrom={0}
        removeArrowOnDeviceType={["mobile"]}
        className="py-2"
        responsive={responsive}
        slidesToSlide={1}
        itemClass="pr-1  md:pr-0"
      >
        {loading
          ? loadingData.map((item, i) => (
              <HorizontalCardLoader key={i.toString()} />
            ))
          : data.map((item, i) => (
              <HorizontalListCard key={i.toString()} {...item} item={item} />
            ))}
      </Carousel>
    </div>
  );
}
