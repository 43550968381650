import React, { useState } from "react";
import { Avatar, Button } from "@material-tailwind/react";
import { PlayIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useReduxStore } from "../hooks/useReduxStore";
import BasicInfoComponent from "./BasicInfoComponent";
import ChangePasswordComponent from "./ChangePasswordComponent";
export default function ProfileForm() {
  const { getState, dispatch } = useReduxStore();
  const { userData } = getState("AUTH");
  // console.log("user Data--------", userData);
  const { name, email, avatar } = userData || {};
  // console.log("userdata--------", userData);
  const [ch_password, setChPassword] = useState(false);
  return (
    <section className="md:rounded-lg bg-white p-4 py-10 md:p-16   md:shadow-lg w-screen  md:w-[65vw]  ">
      <div className="mx-auto  px-4 py-0 lg:py-0 sm:px-6 lg:px-0">
        <div className="grid grid-cols-1  lg:grid-cols-1">
          <div className=" lg:col-span-3 ">
            <div className="space-y-8">
              <div className=" flex flex-col  md:flex-row justify-between   items-center  mb-10 md:mb-2 ">
                <Avatar
                  src={
                    avatar ||
                    "https://i.pinimg.com/originals/5f/40/6a/5f406ab25e8942cbe0da6485afd26b71.jpg"
                  }
                  alt="avatar"
                  variant="square"
                  withBorder={true}
                  color="orange"
                  className="p-0.2 w-[10rem] h-[10rem]"
                />
                {/* <div className=" mt-6 space-y-2 md:space-y-3">
                  <h1 className="text-center md:text-right font-regular text-black text-base md:text-lg">
                    Total Spending :{" "}
                    <span className="text-secondary ml-2 font-medium">
                      $ 78.00
                    </span>
                  </h1>
                  <h1 className="text-center md:text-right  font-regular text-black text-base md:text-lg">
                    Total Buy items :{" "}
                    <span className="text-secondary ml-2 font-medium">10</span>
                  </h1>
                </div> */}
                {/* <div className="hidden md:flex hover:bg-black/30  hover:cursor-pointer absolute   justify-center items-center top-0 bottom-0  w-[10rem]    lg:h-full  inset-0 bg-black/0  ">
                  <PencilSquareIcon className="h-6 w-6 text-white  " />
                </div> */}
              </div>
              {!ch_password ? (
                <BasicInfoComponent />
              ) : (
                <ChangePasswordComponent />
              )}
              <div className="mt-10">
                <Button
                  onClick={() => setChPassword(!ch_password)}
                  color="orange"
                  variant="text"
                  className="flex items-center p-0 py-1 my-2 text-base"
                >
                  {ch_password ? "Don't Change Password" : "Change Password"}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
