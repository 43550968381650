import React from 'react'
import { Fade,Flip,Slide,Zoom,Bounce } from "react-reveal";

export default function SoulFlowStatsSection() {
  return (
    <div className="bg-black py-24 sm:py-32 border-t  border-neutral-900">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:max-w-none">
      <Fade
     duration={1000}
     distance={'6rem'}
     top
 >
        <div className="text-center space-y-4">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-5xl">
            Trusted by Artist worldwide
          </h2>
          <p className="text-lg leading-8 text-gray-300">
          Our reputation as a trusted partner among artists globally is built on a foundation of reliability, quality, and commitment.
          </p>
        </div>
        </Fade>
    <Zoom
     duration={1200}
cascade
    >
    <dl className="mt-16 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-4">
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              music record in 2023
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">
              5K
            </dd>
          </div>
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              music uploaded
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">
              1 million
            </dd>
          </div>
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
            artists we have worked with
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">
              6.6k
            </dd>
          </div>
          <div className="flex flex-col bg-white/5 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-300">
              music production
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">
              2.1k
            </dd>
          </div>
        </dl>
    </Zoom>
      </div>
    </div>
  </div>
  )
}
