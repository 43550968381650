import { createSlice } from "@reduxjs/toolkit";
const initial_state = {
  homeBanners: [],
  popularLoading: false,
  trendingLoading: false,
  popularByCategoryLoading: false,
  trendingItems: [],
  popularItems: [],
  items: [],
  currentPage: 1,
  lastPage: 0,
  totalItemsCount: 0,
  perPageCount: 0,
  categories: [],
  subCategories: [],
  categoriesItems: [],
  selectedCategory: null,
  width: 0,
  updateHome: false,
};

const homeSlice = createSlice({
  name: "Home",
  initialState: initial_state,
  reducers: {
    updateHome: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

export const { updateHome } = homeSlice.actions;
export default homeSlice.reducer;
