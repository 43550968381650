export const bannersData = [
  {
    image:
      "https://collider.com/wp-content/uploads/the-avengers-movie-poster-banners-04.jpg",
  },
  {
    image:
      "https://images.squarespace-cdn.com/content/v1/55e594e1e4b0a22db8637b85/1697210958361-25D7DSD7NL68TEKA7STK/The+beekeeper+NL.png",
  },
  { image: "https://s39940.pcdn.co/wp-content/uploads/2023/04/1500x500.jpg" },
  {
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/promo-images.hoopladigital.com/hoopla_HMNowBP_GoodWitch_MQ.jpg",
  },
  {
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/promo-images.hoopladigital.com/Q42023-HBC-MQ-Cover.jpg",
  },
  {
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/promo-images.hoopladigital.com/MQ-ViralWorthyZombie-NoCovers-Movies.jpg",
  },
  {
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/promo-images.hoopladigital.com/MQ-SupernaturalScares-US-Movie.jpg",
  },
  {
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/promo-images.hoopladigital.com/MQ-DCManga-hooplaExclusive.jpg",
  },
  {
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/promo-images.hoopladigital.com/MQ-Homeward-Audio.jpg",
  },
];

const ebooksData = [
  {
    name: "The Unfortunate Side Effects of Heartbreak and Magic",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/drs_9781639105748_270.jpeg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Just the Nicest Couple",
    description:
      "Grabs you on the first page and doesn't let go until you reach the end.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/hqn_9780369718273_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Harry Potter and the Sorcerer's Stone",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/csp_9781781100486_270.jpeg",
    rating: 5,
    views: "100k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "The Unfortunate Side Effects of Heartbreak and Magic",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/drs_9781639105748_270.jpeg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Just the Nicest Couple",
    description:
      "Grabs you on the first page and doesn't let go until you reach the end.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/hqn_9780369718273_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Harry Potter and the Sorcerer's Stone",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/csp_9781781100486_270.jpeg",
    rating: 5,
    views: "100k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
];
const audioBookData = [
  {
    name: "One Last Secret",
    description: `Everyone has secrets, don't they?

      One last client
      
      A week at a beautiful chateau in the south of France-it should be a straightforward final job for Dora. She's a smart, stunning and discreet escort, and Daniel has paid for her services before. This time, all she has to do is to convince the assembled guests that she is his girlfriend. Dora is used to playing roles and being whatever men want her to be. It's all about putting on a front.
      
      One last chance
      
      It will be a last, luxurious look at how the other half lives before Dora turns her back on the escort world and all its dangers. She has found someone she loves and trusts. With him, she can escape the life she's trapped in. But when Dora arrives at the chateau, it quickly becomes obvious that nothing is what it seems...
      
      One last secret
      
      Dora finds herself face-to-face with a man she has never forgotten, the one man who really knows her. And as old secrets surface, it becomes terrifyingly apparent that one last secret could cost Dora her life...
      
      From the Sunday Times number one bestseller Adele Parks comes a blisteringly provocative novel about power, sex, money and revenge.`,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/hpc_9781488215551_270.jpeg",
    rating: 4.2,
    views: "120k",
    released: "2022",
    sub_category: "Fiction",
    type: "audio_book",
    cast: "Adele Parks",
  },
  {
    name: "A Noble Inheritance",
    description: `Lady Verity wants nothing more than to provide a safe home for her sister, whose simple-mindedness is often misunderstood. When Lady Verity's father and husband both die, passing their house to a stranger, she vows to do whatever she must to ensure her sister's safety. The new earl has a reputation for being a rake, and Lady Verity is prepared to make him an offer he cannot refuse. Daniel Palmer has run from his past for years, hiding from his failures and inadequacies by burying himself in cards, drink, and women. When a distant cousin and his heir both die, leaving Daniel with a title, house, and responsibilities he does not want, Daniel's impulse is to run. But his mother has different plans, and she invites the previous earl's widow and her two daughters to Arden Castle so they can become acquainted on neutral territory. When the countess and her daughters arrive, Daniel quickly learns that Lady Verity is hiding something from him. But as he grows closer to the truth, he learns that not everything is as it seems-and how to measure the true worth of a soul. Book four in the Castles & Courtship Series, A Noble Inheritance is a sweet and clean Regency romance about reforming a rake and the trials faced by people with special needs in the 19th century.`,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/dsa_9781666649208_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2023",
    sub_category: "Romance",
    type: "audio_book",
    cast: "Kasey Stockton",
  },
  {
    name: "Journey of Souls",
    description: `Case Studies of Life Between Lives

      Now considered a classic in the field, this remarkable book was the first to fully explore the mystery of life between lives. Journey of Souls presents the first-hand accounts of twenty-nine people placed in a "superconscious" state of awareness using Dr. Michael Newton's groundbreaking techniques. This unique approach allows Dr. Newton to reach his subjects' hidden memories of life in the spirit world after physical death. While in deep hypnosis, the subjects movingly describe what happened to them between lives. They reveal graphic details about what the spirit world is really like, where we go and what we do as souls, and why we come back in certain bodies.Through the extraordinary stories in this book, you will learn the specifics about:-How it feels to die-What you see and feel right after death-When and where you learn to recognize soul mates on earth-Different levels of soul: beginning, intermediate, and advanced-What happens to "disturbed" souls-The purpose of life and manifestation of a "creator"`,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/ttm_9781452620886_270.jpeg",
    rating: 5,
    views: "100k",
    released: "2012",
    sub_category: "Science Fiction",
    type: "audio_book",
    cast: "Michael Newton, Ph.D",
  },
  {
    name: "The Unfortunate Side Effects of Heartbreak and Magic",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/drs_9781639105748_270.jpeg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "audio_book",
    cast: "Robert Holand",
  },
  {
    name: "Baby Daddy",
    description: `"A hot and hilarious new standalone from New York Times best seller Kendall Ryan. We met in a trapped elevator. Emmett was on his way to work, sophisticated and handsome in his tailored suit and tie. I was on my way to the sperm bank. Awkward, right? At 35, my life hadn't taken the path I thought it would and I was tired of waiting - I wanted a baby. And I was ready to take matters into my own hands to make it happen. After our ill-fated elevator encounter, Emmett insisted on taking me to dinner - he also insisted on something else - that I ditch my plan involving a turkey baster and let him do the job. He would be my baby daddy. He was a wealthy and powerful CEO with little interest in diapers or playdates. And since he didn't want kids, I'd be on my own once his bun was in my oven, free to go my own way. But once his baby was inside me, it was like a switch had been flipped, and I got a whole lot more than I ever bargained for. This full-length standalone contains a hot, swoon-worthy hero, lots of playful banter and some hot baby-making! Enjoy. "`,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/drs_9781666652321_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2023",
    sub_category: "Religious",
    type: "audio_book",
    cast: "Kendall Ryan",
  },
  {
    name: "Harry Potter and the Sorcerer's Stone",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/csp_9781781100486_270.jpeg",
    rating: 5,
    views: "100k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "audio_book",
    cast: "Robert Holand",
  },
];
const moviesData = [
  {
    name: "The Hating Game",
    description:
      "Based on the best-selling book, THE HATING GAME tells the story of ambitious good girl Lucy Hutton and her cold, efficient work nemesis, Joshua Templeton. Committed to achieving professional success without compromising her ethics, Lucy ultimately embarks on a ruthless game of one-upmanship against Josh, a rivalry that is increasingly complicated by her mounting attraction to him. ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/ver_vert0471_270.jpeg",
    rating: 4.4,
    views: "100k",
    released: "2021",
    sub_category: "Romance",
    type: "Video",
    cast: "Lucy Hale, Austin Stowell,",
    price: 70,
  },
  {
    name: "Valkyrie",
    description:
      "At the height of WWII, a group of German officers hatched a plot to assassinate Hitler and seize control of the military command in order to end the war. The operation was code-named  for the emergency plan that was meant to be used in case of a revolt against the Nazi government. This plan had been modified by the conspirators to ensure their success, but for various reasons the plot failed when finally carried out on 20 July 1944. The conspirators of the inner circle were shot after a kangaroo trial or sentenced to death soon after",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/mgm_ho04129u_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2008",
    sub_category: "Drama",
    type: "Video",
    cast: " David Bamber, Christian Berkel",
    price: 30,
  },
  {
    name: "The Road Dog",
    description:
      "The Road Dog is the story of Jimmy Quinn, an alcoholic stand-up comedian who gets a second chance at life when he reconnects with his estranged son.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/frs_fdmdi842_270.jpeg",
    rating: 4.7,
    views: "100k",
    released: "2023",
    sub_category: "Comedy",
    type: "Video",
    cast: " Doug Stanhope, Khrysytne Haje",
    price: 150,
  },
  {
    name: "Matilda",
    image: "https://pad.mymovies.it/filmclub/2020/05/037/locandina.jpg",
    description: `A girl gifted with a keen intellect and psychic powers uses both to deal with her crude, distant family and free her kind teacher from their sadistic headmistress.`,
    sub_category: "Comedy",
    type: "Video",
    released: "2022",
  },
  {
    name: "The Black Book",
    image:
      "https://i0.wp.com/businessday.ng/wp-content/uploads/2023/09/Black-Book.png?fit=700%2C400&ssl=1",
    description: `After his son is framed for a kidnapping, a bereaved deacon takes justice into his own hands and fights a corrupt police gang to absolve him.`,
    sub_category: "Crime",
    type: "Video",
    released: "2023",
  },
  {
    name: "The ABC Murder",
    image: "https://i.ytimg.com/vi/l-JnLcOuqro/maxresdefault.jpg",
    description: `In 1933, retired detective Hercule Poirot is targeted by a taunting killer who sends letters signed "ABC", which Poirot must decode in order to discover the identity of the murderer.`,
    sub_category: "Thriller",
    type: "Video",
    released: "2018",
  },
  {
    name: "The Hating Game",
    description:
      "Based on the best-selling book, THE HATING GAME tells the story of ambitious good girl Lucy Hutton and her cold, efficient work nemesis, Joshua Templeton. Committed to achieving professional success without compromising her ethics, Lucy ultimately embarks on a ruthless game of one-upmanship against Josh, a rivalry that is increasingly complicated by her mounting attraction to him. ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/ver_vert0471_270.jpeg",
    rating: 4.4,
    views: "100k",
    released: "2021",
    sub_category: "Romance",
    type: "Video",
    cast: "Lucy Hale, Austin Stowell,",
    price: 70,
  },
  {
    name: "Valkyrie",
    description:
      "At the height of WWII, a group of German officers hatched a plot to assassinate Hitler and seize control of the military command in order to end the war. The operation was code-named  for the emergency plan that was meant to be used in case of a revolt against the Nazi government. This plan had been modified by the conspirators to ensure their success, but for various reasons the plot failed when finally carried out on 20 July 1944. The conspirators of the inner circle were shot after a kangaroo trial or sentenced to death soon after",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/mgm_ho04129u_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2008",
    sub_category: "Drama",
    type: "Video",
    cast: " David Bamber, Christian Berkel",
    price: 30,
  },
  {
    name: "The Road Dog",
    description:
      "The Road Dog is the story of Jimmy Quinn, an alcoholic stand-up comedian who gets a second chance at life when he reconnects with his estranged son.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/frs_fdmdi842_270.jpeg",
    rating: 4.7,
    views: "100k",
    released: "2023",
    sub_category: "Comedy",
    type: "Video",
    cast: " Doug Stanhope, Khrysytne Haje",
    price: 150,
  },
];
const seasonsData = [
  {
    name: "Alone: Frozen - Season 1",
    description:
      "In Alone: Frozen six of Alone's strongest participants from past seasons return to put their survival skills to the test for a second time. ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/aae_alfr266421_270.jpeg",
    rating: 4.8,
    views: "100k",
    released: "2022",
    sub_category: "Reality",
    type: "Video",
    cast: "Zachary Behr",
    price: 70,
  },
  {
    name: "Manhunt - Season 2",
    description: `Fresh off his success catching a headline-grabbing killer, DCI Colin Sutton (Martin Clunes, Doc Martin) is on the brink of retirement when he's asked to review the long-running inquiry into another infamous predator. Known as "The Night Stalker" to the press and "Minstead Man" to the police, this serial rapist has stalked the elderly of Southeast London for nearly two decades.

      Many at the Metropolitan Police view the inquiry as a poisoned chalice: high-profile but unsolvable crimes sure to sully Sutton's career at its pinnacle. Despite that risk, Colin can't help but commit himself to the investigation. Inspired by insights from his wife (Claudie Blakely, Pride & Prejudice), a police data analyst, Colin applies a new approach to the case, hoping to crack it open at last and end the attacker's 17-year reign of terror. "Clunes `,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/rlj_amp293601_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2021",
    sub_category: "Crime",
    type: "Video",
    cast: "Martin Clunes, Claudie Blakley, Katie Lyons",
    price: 30,
  },
  {
    name: "MasterClass Presents Jessie Krebs Teaches Wilderness Survival",
    description: `Wilderness survival expert and former Air Force SERE specialist Jessie Krebs teaches you the skills to explore nature safely and confidently.
      `,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/mrc_mrcjkreb101_270.jpeg",
    rating: 4.7,
    views: "100k",
    released: "2021",
    sub_category: "Comedy",
    type: "Video",
    cast: "Jessie Krebs",
    price: 180,
  },
  {
    name: "Midsomer Murders ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/atm_midsomermurderss07e01_270.jpeg",
    description: `A veteran Detective Chief Inspector and his young Sergeant investigate murders around the regional community of Midsomer County.`,
    sub_category: "Crime",
    type: "Video",
    released: "2003",
    cast: " John Nettles, Jane Wymark, Daniel Casey",
  },
  {
    name: "The Black Book",
    image:
      "https://i0.wp.com/businessday.ng/wp-content/uploads/2023/09/Black-Book.png?fit=700%2C400&ssl=1",
    description: `After his son is framed for a kidnapping, a bereaved deacon takes justice into his own hands and fights a corrupt police gang to absolve him.`,
    sub_category: "Crime",
    type: "Video",
    released: "2023",
  },
  {
    name: "The ABC Murder",
    image: "https://i.ytimg.com/vi/l-JnLcOuqro/maxresdefault.jpg",
    description: `In 1933, retired detective Hercule Poirot is targeted by a taunting killer who sends letters signed "ABC", which Poirot must decode in order to discover the identity of the murderer.`,
    sub_category: "Thriller",
    type: "Video",
    released: "2018",
  },
  {
    name: "The Hating Game",
    description:
      "Based on the best-selling book, THE HATING GAME tells the story of ambitious good girl Lucy Hutton and her cold, efficient work nemesis, Joshua Templeton. Committed to achieving professional success without compromising her ethics, Lucy ultimately embarks on a ruthless game of one-upmanship against Josh, a rivalry that is increasingly complicated by her mounting attraction to him. ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/ver_vert0471_270.jpeg",
    rating: 4.4,
    views: "100k",
    released: "2021",
    sub_category: "Romance",
    type: "Video",
    cast: "Lucy Hale, Austin Stowell,",
    price: 70,
  },
  {
    name: "Valkyrie",
    description:
      "At the height of WWII, a group of German officers hatched a plot to assassinate Hitler and seize control of the military command in order to end the war. The operation was code-named  for the emergency plan that was meant to be used in case of a revolt against the Nazi government. This plan had been modified by the conspirators to ensure their success, but for various reasons the plot failed when finally carried out on 20 July 1944. The conspirators of the inner circle were shot after a kangaroo trial or sentenced to death soon after",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/mgm_ho04129u_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2008",
    sub_category: "Drama",
    type: "Video",
    cast: " David Bamber, Christian Berkel",
    price: 30,
  },
  {
    name: "The Road Dog",
    description:
      "The Road Dog is the story of Jimmy Quinn, an alcoholic stand-up comedian who gets a second chance at life when he reconnects with his estranged son.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/frs_fdmdi842_270.jpeg",
    rating: 4.7,
    views: "100k",
    released: "2023",
    sub_category: "Comedy",
    type: "Video",
    cast: " Doug Stanhope, Khrysytne Haje",
    price: 150,
  },
];
const musicData = [
  {
    name: "Look What You Made Me Do ",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image: "https://i1.sndcdn.com/artworks-000240354051-ah9uw9-t500x500.jpg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Taylor Swift",
  },
  {
    name: "New Rules",
    description:
      "Grabs you on the first page and doesn't let go until you reach the end.",
    image: "https://i1.sndcdn.com/artworks-000232672816-q5u5x2-t500x500.jpg",
    rating: 4.9,
    views: "150k",
    released: "2017",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Dua Lipa",
  },
  {
    name: "Blank Space",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image: "https://i1.sndcdn.com/artworks-000123962072-i1avwd-t500x500.jpg",
    rating: 5,
    views: "100k",
    released: "2012",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Taylor Swift",
  },
  {
    name: "A Thousand Years",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image: "https://i1.sndcdn.com/artworks-000033886959-xniimk-t500x500.jpg",
    rating: 4.5,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Cristiana Perri",
  },
  {
    name: "Look What You Made Me Do ",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image: "https://i1.sndcdn.com/artworks-000240354051-ah9uw9-t500x500.jpg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Taylor Swift",
  },
  {
    name: "New Rules",
    description:
      "Grabs you on the first page and doesn't let go until you reach the end.",
    image: "https://i1.sndcdn.com/artworks-000232672816-q5u5x2-t500x500.jpg",
    rating: 4.9,
    views: "150k",
    released: "2017",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Dua Lipa",
  },
  {
    name: "Blank Space",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image: "https://i1.sndcdn.com/artworks-000123962072-i1avwd-t500x500.jpg",
    rating: 5,
    views: "100k",
    released: "2012",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Taylor Swift",
  },
  {
    name: "A Thousand Years",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image: "https://i1.sndcdn.com/artworks-000033886959-xniimk-t500x500.jpg",
    rating: 4.5,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Audio",
    cast: "Cristiana Perri",
  },
];
const trendingData = [
  {
    name: "Manhunt - Season 2",
    description: `Fresh off his success catching a headline-grabbing killer, DCI Colin Sutton (Martin Clunes, Doc Martin) is on the brink of retirement when he's asked to review the long-running inquiry into another infamous predator. Known as "The Night Stalker" to the press and "Minstead Man" to the police, this serial rapist has stalked the elderly of Southeast London for nearly two decades.

      Many at the Metropolitan Police view the inquiry as a poisoned chalice: high-profile but unsolvable crimes sure to sully Sutton's career at its pinnacle. Despite that risk, Colin can't help but commit himself to the investigation. Inspired by insights from his wife (Claudie Blakely, Pride & Prejudice), a police data analyst, Colin applies a new approach to the case, hoping to crack it open at last and end the attacker's 17-year reign of terror. "Clunes `,
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/rlj_amp293601_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2021",
    sub_category: "Crime",
    type: "Video",
    cast: "Martin Clunes, Claudie Blakley, Katie Lyons",
    price: 30,
  },
  {
    name: "Just the Nicest Couple",
    description:
      "Grabs you on the first page and doesn't let go until you reach the end.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/hqn_9780369718273_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Harry Potter and the Sorcerer's Stone",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/csp_9781781100486_270.jpeg",
    rating: 5,
    views: "100k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  ...moviesData,
  {
    name: "The Unfortunate Side Effects of Heartbreak and Magic",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/drs_9781639105748_270.jpeg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },

  {
    name: "The Unfortunate Side Effects of Heartbreak and Magic",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/drs_9781639105748_270.jpeg",
    rating: 4.3,
    views: "120k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Just the Nicest Couple",
    description:
      "Grabs you on the first page and doesn't let go until you reach the end.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/hqn_9780369718273_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
  {
    name: "Harry Potter and the Sorcerer's Stone",
    description:
      "For fans of Practical Magic and Gilmore Girls, The Unfortunate Side Effects of Heartbreak ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/csp_9781781100486_270.jpeg",
    rating: 5,
    views: "100k",
    released: "2023",
    sub_category: "Science Fiction",
    type: "Book",
    cast: "Robert Holand",
  },
];
const termsAndPolicyContentArray = [
  {
    title: "1. Account Creation and Use",
    points: [
      "a. Users are required to create an account to access our platform. You must provide accurate, current, and complete information during the registration process.",
      "b. You are responsible for maintaining the confidentiality of your account and password. Any activities that occur under your account will be your responsibility.",
      "c. You agree to use the platform in accordance with all applicable laws and regulations, as well as these terms and conditions.",
    ],
  },
  {
    title: "2. Content Upload and Intellectual Property",
    points: [
      "a. Vendors may upload original content or content from other platforms for sale or streaming. By uploading content, you represent and warrant that you have all necessary rights and permissions to do so.",
      "b. Vendors retain ownership of their original content, but grant Boss Amongst Bosses a non-exclusive, worldwide, royalty-free license to display, distribute, and promote their content on our platform.",
      "c. Users shall not reproduce, distribute, modify, create derivative works of, publicly display, or perform any copyrighted material found on Boss Amongst Bosses without the express written consent of the respective copyright owner.",
    ],
  },
  {
    title: "3. Subscription and Usage Fees",
    points: [
      "a. Vendors are required to pay a monthly subscription fee as outlined on our website.",
      "b. Customers will be charged a per item download or stream fee of $4 USD for each purchased item.",
      "c. All fees are non-refundable, unless otherwise stated in our refund policy.",
    ],
  },
  {
    title: "4. Royalty and Copyright",
    points: [
      "a. Boss Amongst Bosses and vendors shall enter into a royalty split agreement for all original content uploaded to the platform.",
      "b. Copyright ownership shall be shared as outlined in the copyright split agreement. Intellectual property protection for already protected vendor content shall impact the copyright split.",
    ],
  },
  {
    title: "5. Rewards Program",
    points: [
      "a. Sellers on our platform are eligible for participation in our rewards program, which may include features such as award ceremonies, discounts, and bestseller listings.",
      "b. Boss Amongst Bosses ambassadors play a vital role in promoting our platform and may receive special incentives, provided they comply with the terms of the ambassador program.",
    ],
  },
  {
    title: "6. Age Restriction",
    points: [
      "a. Customers must be of legal age to make purchases and provide credit card authorization.",
      "b. Adult content, including videos, movies, or any form of explicit material, is strictly prohibited from being uploaded or streamed on Boss Amongst Bosses.",
    ],
  },
  {
    title: "7. Terms and Conditions Policy",
    points: [
      "a. Users are expected to read, understand, and agree to this terms and conditions policy before using our platform.",
      "b. Our strict guidelines and policies also include a refund policy and fraud policy, which are clearly stated on our website for customers' awareness.",
    ],
  },
  {
    title: "8. Prohibited Activities",
    points: [
      "a. Users shall not engage in activities that are illegal, harmful, deceptive, or infringe upon the rights of others. This includes unauthorized distribution, sharing of personal information, hacking, or any form of misconduct.",
      "b. Boss Amongst Bosses reserves the right to investigate and take appropriate legal action against any user who violates this provision.",
    ],
  },
  {
    title: "9. Account Termination",
    points: [
      "a. Boss Amongst Bosses has the right to terminate user accounts without prior notice for violation of these terms and conditions, or for any other reason deemed necessary. Account termination may result in the removal of all associated content and the loss of access to the platform.",
    ],
  },
  {
    title: "10. Content Monitoring and Removal",
    points: [
      "a. Boss Amongst Bosses may monitor and review all content uploaded to the platform to ensure compliance with our policies. Inappropriate or infringing content may be removed without prior notice.",
    ],
  },
  {
    title: "11. Limitation of Liability",
    points: [
      "a. Boss Amongst Bosses, including its employees, directors, and affiliates, shall not be held liable for any damages or losses incurred as a result of using our platform. This includes, but is not limited to, the availability or accuracy of content, technical issues, or any unauthorized access to user accounts.",
    ],
  },
  {
    title: "12. Indemnification",
    points: [
      "a. By using our platform, you agree to indemnify and hold Boss Amongst Bosses harmless from any claims, damages, or liabilities arising from your use of the platform or any breach of this Agreement. This includes any third-party claims related to your uploaded content.",
    ],
  },
  {
    title: "13. Amendments",
    points: [
      "a. Boss Amongst Bosses reserves the right to update or modify this Agreement at any time. Any changes will be notified to users, and continued use of the platform constitutes acceptance of the modified terms.",
    ],
  },
  {
    title: "14. Governing Law and Jurisdiction",
    points: [
      "a. This Agreement shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from the use of the platform shall be subject to the exclusive jurisdiction of the courts in [Jurisdiction].",
    ],
  },
  {
    title: "15. Payment and Return Policy",
    points: [
      "a. Payment: All payments made through BAB Media shall be processed using the Stripe payment gateway. The payment process and terms are subject to Stripe's policies and terms of service.",
      "b. Return Policy: BAB Media does not provide refunds or returns for digital downloads or subscription fees. All sales are final.",
    ],
  },
  {
    title: "16. Fees and Disputes",
    points: [
      "a. Vendor Fees: Vendors on BAB Media will be charged a percentage fee for each transaction made through the platform, in accordance with the vendor agreement. The specific fee percentage will be communicated to the vendor separately.",
      "b. Customer Fees: Customers will be charged a per download cost for each item purchased through BAB Media, in addition to any applicable taxes, fees, and transaction costs.",
      "c. Subscription Fee: Both vendors and customers will be required to pay a subscription fee to access and use the services provided by BAB Media. The subscription fee amount will be determined by BAB Media and may be subject to change.",
      "d. Disputes: Any disputes or issues regarding payments, fees, or transactions must be reported to BAB Media's customer support team within [X] days of the occurrence. BAB Media will review and address the dispute as per their discretion.",
    ],
  },
  {
    title: "17. Royalty Split and Content Providers",
    points: [
      "a. Royalty Split: BAB Media operates on a 35/65 royalty split model, with content providers receiving 65% of the revenue earned from each transaction. The remaining 35% shall be retained by BAB Media as a service fee.",
      "b. Content Pricing: Content providers are responsible for setting the price for each item they offer on BAB Media. All prices must be equal to or greater than $5.00 USD, excluding any taxes, fees, and transaction costs.",
    ],
  },
  {
    title: "18. Advertising",
    points: [
      "a. BAB Media may display advertisements on its website. The type, format, and frequency of the ads are at BAB Media's discretion. Advertisements may be targeted based on user preferences, browsing history, or other relevant factors.",
    ],
  },
];
export const policyContentArray = [
  {
    title: "1. Zero Tolerance for Fraud",
    points: [
      "Boss Amongst Bosses has a strict zero-tolerance policy towards any form of fraudulent activities conducted on our digital platform. We are committed to ensuring the utmost protection for our customers, vendors, and the company itself.",
    ],
  },
  {
    title: "2. Account Authentication and Verification",
    points: [
      "All users, including customers and vendors, are required to provide accurate, current, and complete information during the registration process. Boss Amongst Bosses reserves the right to authenticate and verify user accounts to detect and prevent fraudulent activities.",
    ],
  },
  {
    title: "3. Monitoring and Analysis",
    points: [
      "Boss Amongst Bosses employs advanced monitoring and analysis systems to identify suspicious patterns, transactions, and behavior on the platform. This includes the use of fraud detection tools and algorithms, which continuously evaluate activity for potential fraud indicators.",
    ],
  },
  {
    title: "4. Immediate Reporting",
    points: [
      "Customers and vendors must promptly report any suspected fraudulent activities to Boss Amongst Bosses. Timely reporting allows us to take swift action and implement necessary measures to prevent further harm and protect all parties involved.",
    ],
  },
  {
    title: "5. Cooperation with Law Enforcement",
    points: [
      "In cases of suspected fraud, Boss Amongst Bosses will fully cooperate with law enforcement authorities and supply relevant information, evidence, and documentation to support investigations and ensure appropriate legal action is taken.",
    ],
  },
  {
    title: "6. Account Suspension/Termination",
    points: [
      "Upon reasonable suspicion or confirmation of fraudulent activities, Boss Amongst Bosses reserves the right to suspend or terminate user accounts involved without prior notice. Account suspension or termination may result in the removal of associated content and permanent loss of platform access.",
    ],
  },
  {
    title: "7. Fraudulent Transaction Reversals",
    points: [
      "In the event of confirmed fraudulent transactions, Boss Amongst Bosses will initiate adequate measures to reverse the transactions and restore affected parties to their original positions. This process will be carried out in compliance with the applicable laws and regulations.",
    ],
  },
  {
    title: "8. User Responsibility",
    points: [
      "Users are responsible for maintaining the security of their accounts. This includes safeguarding login information, using strong passwords, promptly reporting any unauthorized access, and keeping personal information confidential. Boss Amongst Bosses cannot be held liable for losses or damages resulting from the user's failure to uphold these responsibilities.",
    ],
  },
  {
    title: "9. Fraud Prevention Education",
    points: [
      "Boss Amongst Bosses will provide educational resources and guidelines to help users identify potential fraud risks and adopt best practices to prevent fraud. This will be made available through our website, email communications, and other suitable channels.",
    ],
  },
  {
    title: "10. Continuous System Enhancements",
    points: [
      "Boss Amongst Bosses is committed to ongoing system enhancements and upgrades to strengthen our fraud prevention capabilities. This includes staying up-to-date with industry standards, implementing advanced security measures, and regularly reviewing and upgrading our fraud prevention systems.",
    ],
  },
  {
    title: "11. Amendment of Fraud Policy",
    points: [
      "Boss Amongst Bosses reserves the right to amend or modify the fraud policy at any time without prior notice. Users will be notified of any updates, and continued use of the platform will be considered acceptance of the revised policy.",
    ],
  },

  {
    title: "12. User Collaboration",
    points: [
      "Boss Amongst Bosses encourages users to collaborate in the fight against fraud. We urge customers and vendors to remain vigilant and report any suspicious activities or potential fraud schemes they encounter on the platform. By working together, we can take collective action to protect the integrity of our digital marketplace.",
    ],
  },
  {
    title: "13. Fraud Investigation and Resolution",
    points: [
      "Upon receiving reports of fraud, Boss Amongst Bosses will initiate a thorough investigation into the matter. Our dedicated team will gather evidence, interview involved parties, and analyze relevant data to determine the extent of the fraud and take appropriate action.",
    ],
  },
  {
    title: "14. Legal Remedies",
    points: [
      "In cases of confirmed fraud, Boss Amongst Bosses will pursue all available legal remedies to seek restitution for any losses incurred. This may include initiating civil lawsuits, cooperating with law enforcement agencies to press criminal charges, or engaging third-party recovery services where necessary.",
    ],
  },
  {
    title: "15. Enhanced Security Measures",
    points: [
      "Boss Amongst Bosses is committed to continually enhancing our platform's security measures to safeguard against fraudulent activities. This includes implementing robust encryption protocols, strengthening authentication processes, and regularly updating our systems to stay ahead of emerging fraud tactics.",
    ],
  },
  {
    title: "16. Training and Awareness Programs",
    points: [
      "Boss Amongst Bosses will provide comprehensive training and awareness programs for employees, vendors, and customers to ensure a better understanding of fraud prevention strategies. By equipping all stakeholders with the necessary knowledge and skills, we aim to create a secure digital environment.",
    ],
  },
  {
    title: "17. Confidentiality and Privacy",
    points: [
      "Boss Amongst Bosses regards the privacy and confidentiality of users' information with utmost importance. Any personal data shared during fraud investigations will be handled in accordance with relevant privacy laws and regulations to preserve user confidentiality and protect their rights.",
    ],
  },
  {
    title: "18. Continuous Monitoring and Review",
    points: [
      "Boss Amongst Bosses will continuously monitor and review our fraud prevention measures to adapt to evolving threats. Regular audits and assessments are performed to identify any potential vulnerabilities and implement necessary updates to mitigate fraud risks effectively.",
    ],
  },
  {
    title: "19. Disclaimer of Liability",
    points: [
      "While Boss Amongst Bosses takes extensive measures to prevent fraud, we cannot guarantee complete immunity from fraudulent activities. Users acknowledge that Boss Amongst Bosses cannot be held liable for any damages or losses incurred as a result of fraudulent actions committed by third parties.",
    ],
  },
  {
    title: "20. Strong Community Support",
    points: [
      "Boss Amongst Bosses values its community and encourages open communication. We rely on the active involvement of our users to report incidents of fraud promptly and provide valuable insights to improve our fraud prevention efforts further.",
    ],
  },
];
// export const stripeAPIKey =
//   "pk_test_51JHyqqA8MciMaQrunkHpk9Chvnmy6m9ThgGDl3u9vt3wZszp2kodZTY4BSiRdIHlF7FGTqSbTYJtFRuXLYDpSkSu00yyqwqYgi";
export const stripeAPIKey =
  "pk_live_51JHyqqA8MciMaQruyb7Q8AOk1tE5R7UwBWRl4PQNw8TNU469wuUsIlv4y04OUMS1Z8LesfC2DEPbp4SrNYabP5gT00NlHCYw4t";
export {
  ebooksData,
  audioBookData,
  moviesData,
  seasonsData,
  musicData,
  trendingData,
  termsAndPolicyContentArray,
};

export const cartData = [
  {
    name: "The Hating Game",
    description:
      "Based on the best-selling book, THE HATING GAME tells the story of ambitious good girl Lucy Hutton and her cold, efficient work nemesis, Joshua Templeton. Committed to achieving professional success without compromising her ethics, Lucy ultimately embarks on a ruthless game of one-upmanship against Josh, a rivalry that is increasingly complicated by her mounting attraction to him. ",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/ver_vert0471_270.jpeg",
    rating: 4.4,
    views: "100k",
    released: "2021",
    sub_category: "Romance",
    type: "Video",
    cast: "Lucy Hale, Austin Stowell,",
    price: 70,
  },
  {
    name: "Valkyrie",
    description:
      "At the height of WWII, a group of German officers hatched a plot to assassinate Hitler and seize control of the military command in order to end the war. The operation was code-named  for the emergency plan that was meant to be used in case of a revolt against the Nazi government. This plan had been modified by the conspirators to ensure their success, but for various reasons the plot failed when finally carried out on 20 July 1944. The conspirators of the inner circle were shot after a kangaroo trial or sentenced to death soon after",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/mgm_ho04129u_270.jpeg",
    rating: 4.7,
    views: "150k",
    released: "2008",
    sub_category: "Drama",
    type: "Video",
    cast: " David Bamber, Christian Berkel",
    price: 30,
  },
  {
    name: "The Road Dog",
    description:
      "The Road Dog is the story of Jimmy Quinn, an alcoholic stand-up comedian who gets a second chance at life when he reconnects with his estranged son.",
    image:
      "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/frs_fdmdi842_270.jpeg",
    rating: 4.7,
    views: "100k",
    released: "2023",
    sub_category: "Comedy",
    type: "Video",
    cast: " Doug Stanhope, Khrysytne Haje",
    price: 150,
  },
];
export const sortingData = [
  {
    name: "A to Z",
    id: 1,
  },
  {
    name: "Z to A",
    id: 2,
  },
];

export const aboutContent = `Welcome to the Boss Amongst Bosses Digital Platform – Where Creativity Reigns Supreme! 

 

Step into a realm where digital artistry knows no bounds. Here at Boss Amongst Bosses Digital, we are the ultimate destination for content creators and consumers to connect, share, and immerse themselves in the finest digital creations. With our cutting-edge platform, we redefine the way you explore, engage, and monetize your digital media endeavors. 

 

For the Visionary Content Creators: 

 

Are you an accomplished author, groundbreaking musician, visionary filmmaker, or prolific artist seeking an unparalleled platform to showcase your creativity? Look no further. Boss Amongst Bosses Digital Platform brings you a groundbreaking opportunity to upload, promote, and monetize your masterpieces. From captivating ebooks that transport readers to new realms, to soul-stirring music that resonates deep within the soul, to visually breathtaking films that captivate audiences, to awe-inspiring artworks that push the boundaries of imagination – we provide a seamless and intuitive interface to share and sell your digital creations. With a few simple clicks, you can set your own prices and connect with a global audience hungry for extraordinary talent. In this digital age, become the boss (amongst bosses) of your craft with Boss Amongst Bosses Digital Platform. 

 

For the Discerning Creative Connoisseurs: 

 

Seeking an unparalleled digital media experience? Allow us to enchant you. Boss Amongst Bosses Digital Platform curates an extensive collection of captivating content from a diverse array of talented creators around the globe. Immerse yourself in a sea of captivating ebooks, each one an intricate tapestry of words that paint vivid stories in your mind's eye. Lose yourself in melodies and rhythms that touch your very soul as you explore our curated music offerings. Drown in the emotion and spectacle of thought-provoking movies that leave a lasting impact. Marvel at the breathtaking artworks that push the boundaries of imagination and evoke a myriad of emotions. With our intuitive search and browsing features, finding that one-of-a-kind creation has never been easier. Our secure and seamless checkout process ensures a hassle-free experience, bringing the finest digital media right to your fingertips. 

 

Join Our Vibrant Community: 

 

At Boss Amongst Bosses Digital Platform, we believe in the power of community. Unite with fellow creatives, industry professionals, and passionate supporters by becoming an esteemed ambassador. As an ambassador, you'll not only enjoy exclusive perks but will also earn a percentage of each downloaded item and monthly subscription fee. By joining our vibrant community of like-minded individuals, you become an integral part of revolutionizing the digital media landscape while fostering connections that transcend geographical boundaries, demographics, and artistic disciplines. 

 

Unleash Your Digital Passion: 

 

Boss Amongst Bosses Digital Platform is not just another digital marketplace – it's an embodiment of artistic expression and an incubator for dreams. We empower both content creators and consumers to pursue their passions, break through barriers, and leave an indelible mark on the digital landscape. Through our relentless pursuit of innovation, unwavering commitment to a global reach, and dedication to accommodating various creative formats, we provide the perfect environment for unleashing your digital passion. 

 

Ready to embark on a transformative journey? Join us today and unlock the endless possibilities that await within the Boss Amongst Bosses Digital Platform. Unleash your digital passion and be part of a thriving community, where creativity reigns supreme and the extraordinary becomes the norm. 

 

Boss Amongst Bosses Digital Platform - Empowering the Digital Renaissance. `;
export const toastConfiguration = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: false,
  theme: "light",
};

export const faqData = [
  {
    id: 1,
    question: "What is BAB (Boss Amongst Bosses) Media?",
    answer:
      "Boss Amongst Bosses (BAB) Media is an online platform where you can buy, sell, stream, download, and create seller profiles to promote and sell various products, including music.",
  },
  {
    id: 2,
    question: "How can I buy and sell products on BAB Media?",
    answer:
      "To buy and sell products on BAB Media, you can browse through the available listings and make purchases directly from sellers. As a seller, you can create listings for your products and manage your sales.",
  },
  {
    id: 3,
    question: "Can I stream and download music on BAB Media?",
    answer:
      "Yes, you can stream and download music on BAB Media. The platform provides a space for artists to upload their music for users to stream and download.",
  },
  {
    id: 4,
    question:
      "What advantages does creating a seller profile on BAB Media offer?",
    answer:
      "Creating a seller profile on BAB Media offers several advantages, such as reaching a wider audience, managing your products and sales in one place, and gaining exposure for your brand or music.",
  },
  {
    id: 5,
    question: "How can I search for specific items on BAB Media?",
    answer:
      "You can search for specific items on BAB Media by using the search bar or applying filters to narrow down your results, such as by category, genre, or artist.",
  },
  {
    id: 6,
    question: "What is required to create a seller profile on BAB Media?",
    answer:
      "To create a seller profile on BAB Media, you need to sign up as a seller and provide the required information for your profile, such as your contact details, a brief description, and uploading images or logos for your brand.",
  },
  {
    id: 7,
    question: "Is there a fee for creating a seller profile on BAB Media?",
    answer:
      "Yes, there is a fee associated with creating a seller profile on BAB Media. The details of the fee structure can be found in the seller registration process.",
  },
  {
    id: 8,
    question: "How can an artist upload music to BAB Media?",
    answer:
      "As an artist or music creator, you can upload your music to BAB Media by accessing your seller dashboard and following the instructions to upload your tracks, set pricing, and manage your music catalog.",
  },
  {
    id: 9,
    question:
      "What types of products can be sold on BAB Media? Are there any restrictions?",
    answer:
      "BAB Media allows you to sell various products, including music, digital artwork, merchandise, and more. However, there may be certain restrictions on the type of content that can be sold.",
  },
  {
    id: 10,
    question:
      "Is there a limit to the number of products I can sell on BAB Media?",
    answer:
      "There is no specific limit to the number of products you can sell on BAB Media. You can create and manage as many listings as you want, as long as they comply with the platform's guidelines.",
  },
  {
    id: 11,
    question: "Can I create playlists on BAB Media?",
    answer:
      "Yes, you can create playlists on BAB Media and share them with other users. This feature allows you to showcase your favorite music and promote your own tracks or artists.",
  },
  {
    id: 12,
    question: "How do I create a user profile on BAB Media?",
    answer:
      "To create a user profile on BAB Media, you need to sign up as a user by providing your basic information, such as your name, email address, and password.",
  },
  {
    id: 13,
    question: "How easy is it to navigate the BAB Media website?",
    answer:
      "Navigating the BAB Media website is simple. You can use the menu bar, search bar, and filters to browse different categories, search for items, and access specific features like your dashboard, settings, and messages.",
  },
  {
    id: 14,
    question:
      "Does BAB Media offer different membership plans or subscriptions?",
    answer:
      "BAB Media offers different membership plans and subscriptions, allowing users to access additional features, promotional opportunities, or exclusive content. Details about the plans can be found on the website.",
  },
  {
    id: 15,
    question: "Can I customize my seller profile on BAB Media?",
    answer:
      "Yes, you can customize your seller profile on BAB Media. You can add a profile picture, write a compelling bio, and showcase your products and music in an appealing way.",
  },
  {
    id: 16,
    question: "What are some ways to promote products on BAB Media?",
    answer:
      "To promote your products on BAB Media, you can utilize features like social sharing, email marketing, collaborating with influencers, and engaging with the BAB Media community through comments and interactions.",
  },
  {
    id: 17,
    question: "What payment methods does BAB Media accept?",
    answer:
      "BAB Media accepts various payment methods, including credit cards, debit cards, and online payment platforms like PayPal or Stripe. The available options may vary based on your region.",
  },
  {
    id: 18,
    question:
      "Can I offer discounts or promotional deals for my products on BAB Media?",
    answer:
      "Yes, you have the option to offer discounts or promotional deals for your products on BAB Media. This can be done by setting up sale prices or creating coupon codes for buyers to use during checkout.",
  },
  {
    id: 19,
    question: "Are there content restrictions on BAB Media?",
    answer:
      "While BAB Media aims to provide freedom of expression, there may be restrictions on certain types of content, such as explicit or offensive material. Please review the platform's guidelines and policies for more information.",
  },
  {
    id: 20,
    question:
      "How does BAB Media ensure the security of personal and financial information?",
    answer:
      "BAB Media takes the security of your personal and financial information seriously. The platform uses encryption and follows industry best practices to protect your data and transactions.",
  },
  {
    id: 21,
    question: "How can I change my account settings on BAB Media?",
    answer:
      "You can change your account settings on BAB Media by accessing your profile or account settings. From there, you can update information like your email address, password, notifications, and privacy preferences.",
  },
  {
    id: 22,
    question:
      "Is there customer support available on BAB Media? How can I contact them?",
    answer:
      "Yes, BAB Media has a customer support team available to assist you with any issues or questions you may have. You can reach out to them via email, live chat, or by submitting a support ticket.",
  },
  {
    id: 23,
    question: "What types of products are primarily sold on BAB Media?",
    answer:
      "BAB Media primarily focuses on digital products, such as music, digital artwork, and downloadable files. However, physical items like merchandise may also be sold, depending on the seller's offerings.",
  },
  {
    id: 24,
    question:
      "As a seller, how can I track the performance of my products on BAB Media?",
    answer:
      "As a seller, you can track the sales and performance of your products on BAB Media using the seller dashboard. This allows you to view sales data, revenue generated, customer feedback, and other analytics.",
  },
  {
    id: 25,
    question:
      "How can I update the price or description of my products on BAB Media?",
    answer:
      "To update the price or description of your products on BAB Media, you can access your seller dashboard and edit the respective listings. Make sure to save the changes to reflect the updated information.",
  },
  {
    id: 26,
    question: "Is there a rating and review system for products on BAB Media?",
    answer:
      "Yes, BAB Media provides a rating and review system for products. Buyers can leave feedback and ratings based on their experience with the product and the seller's service.",
  },
  {
    id: 27,
    question:
      "Can I upload album artwork or cover images for my music on BAB Media?",
    answer:
      "Yes, you can upload your own album artwork or cover images for your music on BAB Media. This will help in creating a visually appealing and professional presentation for your tracks.",
  },
  {
    id: 28,
    question:
      "Are there any restrictions on streaming or downloading music on BAB Media?",
    answer:
      "While there may be certain restrictions on streaming or downloading music on BAB Media, the platform aims to provide a seamless and user-friendly experience for both artists and listeners.",
  },
  {
    id: 29,
    question: "How can I contact other sellers or buyers on BAB Media?",
    answer:
      "To contact other sellers or buyers on BAB Media, you can utilize the messaging or communication features available on the platform, such as private messages, comments, or forums.",
  },
  {
    id: 30,
    question:
      "What is the dispute resolution process on BAB Media in case of a disagreement between a buyer and seller?",
    answer:
      "In the case of a dispute between a buyer and seller on BAB Media, the platform encourages both parties to communicate and find an amicable resolution. If needed, you can also reach out to the customer support team for assistance in resolving the dispute.",
  },
];
