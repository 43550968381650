import React, { useEffect } from "react";
import VedioDetailBanner from "../../Components/VedioDetailBanner";
import MyNavbar from "../../Components/Navbar/Navbar";
import ReviewItem from "../../Components/ReviewItem.jsx";
import DetailItem from "../../Components/DetailItem";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import BookDetailBanner from "../../Components/BookDetailBanner";
import HorizontalList from "../../Components/HorizontalList";
import {
  audioBookData,
  ebooksData,
  moviesData,
  musicData,
  seasonsData,
} from "../../Constant/Data";
import AudioPlayerComponent from "../../Components/AudioPlayerComponent";
import AudioDetailBanner from "../../Components/AudioDetailBanner";
import SeasonsList from "../../Components/SeasonsList";
import RatingAverage from "../../Components/RatingAverage";
import GiveRating from "../../Components/GiveRating";
import useDetail from "./useDetail";
import Loader from "../../Components/Loader";
import {
  getExtension,
  getYear,
  returnSubCategoryName,
} from "../../helper/helper";
import InfoModal from "../../Components/InfoModal";
import PlaylistComponent from "../../Components/PlayListComponent";
import SampleVideoModal from "../../Components/SampleVideoModal";
export default function Detail() {
  const {
    playerRef,
    itemDetail,
    similarItems,
    similarItemsData,
    similarItemsLoading,
    itemLoading,
    data,
    open,
    width,
    handleOpen,
    loading,
    isPlayerOpen,
    setIsPlayerOpen,
    onClickBuy,
    onWishClick,
    loginModal,
    handleLoginModal,
    onLoginClick,
    togglePlayerOpen,
    featuredVideoModal,
    toggleFaturedVideoModal
  } = useDetail();
  let { type } = itemDetail || data;
  const isMovie = type === "Video";
  const isAudioBook =
    returnSubCategoryName(itemDetail?.category) === "Audiobook" &&
    getExtension(itemDetail?.file) === "mp3";
  const isMusic = type === "Audio";
  const isPlaylist = type === "playlist";
  const isEbook = type === "ebook" || type === "Book";
  const isSeason = type === "season";
  const sub_category = returnSubCategoryName(
    itemDetail?.sub_category || data?.sub_category
  );
  const isPurchased = itemDetail?.is_purchased;
  return (
    <div className="container max-w-full min-w-full">
      {itemLoading && <Loader />}
      <Navbar2 />
      {!itemDetail?.id && itemLoading ? null : !itemDetail?.id &&
        !itemLoading ? null : (
        <>
          {isMovie ? (
            <VedioDetailBanner
              {...(itemDetail || data)}
              onClickBuy={onClickBuy}
              onWishClick={onWishClick}
              isPurchased={isPurchased}
              width={width}
              onFeaturedClick={toggleFaturedVideoModal}
            />
          ) : isPlaylist ? (
            <PlaylistComponent
              {...(itemDetail || data)}
              onClickBuy={onClickBuy}
              onWishClick={onWishClick}
              isPurchased={isPurchased}
              onPlayClick={togglePlayerOpen}
              onFeaturedClick={toggleFaturedVideoModal}
            />
          ) : isAudioBook ? (
            <BookDetailBanner
              {...(itemDetail || data)}
              onClickBuy={onClickBuy}
              onWishClick={onWishClick}
              isPurchased={isPurchased}
              onPlayClick={togglePlayerOpen}
              isAudioBook={isAudioBook}
              isPlayerOpen={isPlayerOpen}
              onFeaturedClick={toggleFaturedVideoModal}
            />
          ) : isMusic ? (
            <AudioDetailBanner
              {...(itemDetail || data)}
              onClickBuy={onClickBuy}
              onWishClick={onWishClick}
              isPurchased={isPurchased}
              onPlayClick={togglePlayerOpen}
              isPlayerOpen={isPlayerOpen}
              onFeaturedClick={toggleFaturedVideoModal}
            />
          ) : (
            <BookDetailBanner
              {...(itemDetail || data)}
              onClickBuy={onClickBuy}
              onWishClick={onWishClick}
              isPurchased={isPurchased}
              onPlayClick={togglePlayerOpen}
              onFeaturedClick={toggleFaturedVideoModal}
            />
          )}

          {!similarItemsData?.length && !loading ? null : (
            <section className="px-2 py-6 sm:px-12 lg:px-20 lg:py-6 lg:pb-2">
              <HorizontalList
                autoScroll
                type={"Movies"}
                data={similarItemsData}
                title="More Like This"
                loading={similarItemsLoading}
              />
            </section>
          )}
          <section className="flow-root  mx-auto  px-2 py-8 sm:px-10 lg:px-20 lg:py-10">
            <h2 className=" mb-8 text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              More Information
            </h2>
            <div className="flow-root rounded-lg border bg-white border-gray-100 py-3 shadow-md">
              <dl className="-my-3 divide-y divide-gray-200 text-sm">
                <DetailItem
                  title={"Name"}
                  value={itemDetail?.title || "Inception"}
                />
                <DetailItem
                  title={"Genres"}
                  value={sub_category || "Science Fiction"}
                />
                {itemDetail?.author && (
                  <DetailItem title={"Author"} value={itemDetail?.author} />
                )}
                {itemDetail?.cast && (
                  <DetailItem title={"Cast"} value={itemDetail?.cast} />
                )}
                {itemDetail?.rating && (
                  <DetailItem title={"Rating"} value={itemDetail?.rating} />
                )}
                {itemDetail?.views && (
                  <DetailItem title={"Views"} value={itemDetail?.views} />
                )}
                {itemDetail?.publish_date && (
                  <DetailItem
                    title={"Realesed Date"}
                    value={`${
                      data?.released ||
                      itemDetail?.released_date ||
                      itemDetail?.publish_date
                    }`}
                  />
                )}
                {itemDetail?.pages && (
                  <DetailItem title={"Pages"} value={itemDetail?.pages} />
                )}
                {itemDetail?.edition && (
                  <DetailItem title={"Edition"} value={itemDetail?.edition} />
                )}
                {itemDetail?.description && (
                  <DetailItem
                    title={"Description"}
                    value={
                      itemDetail?.description ||
                      "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Etfacilis debitis explicabo doloremque impedit nesciunt doloremfacere, dolor quasi veritatis quia fugit aperiam aspernatur neque molestiae labore aliquam soluta architecto?"
                    }
                  />
                )}
              </dl>
            </div>
          </section>
          {/* <section className="w-full py-6  ">
            <div className="flex items-center justify-between px-4 py-8 sm:px-10 lg:px-20 lg:py-6">
              <div className="max-w-xl">
                <h2 className="text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  User Reviews
                </h2>
              </div>

              <a
                href="#"
                className=" inline-flex shrink-0 items-center gap-2 rounded-full border border-secondary px-2 py-2 md:px-5 md:py-3 text-secondary transition hover:bg-secondary hover:text-white md:mt-0"
              >
                <span className="text-xs md:text-base font-medium">
                  {" "}
                  Read all reviews{" "}
                </span>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 rtl:rotate-180"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
              </a>
            </div>
            <div className="mx-auto flex flex-col-reverse  md:flex md:flex-row gap-4  px-4 py-8 sm:px-10 lg:px-20 lg:py-6">
              <div className=" grid grid-cols-1 gap-4 md:grid-cols-1 md:gap-6">
                {[1, 1, 1].map(() => (
                  <ReviewItem />
                ))}
              </div>
              <RatingAverage onClickFeedback={handleOpen} />
            </div>
          </section> */}
          <Footer />
          {isMusic && isPurchased && (
            <AudioPlayerComponent
              isPlayerOpen={isPlayerOpen}
              setIsPlayerOpen={setIsPlayerOpen}
              playerRef={playerRef}
              {...itemDetail}
            />
          )}
          {/* <GiveRating open={open} closeModal={handleOpen} /> */}
          {loginModal && (
            <InfoModal
              handelModal={handleLoginModal}
              onLoginClick={onLoginClick}
            />
          )}
          { featuredVideoModal&& <SampleVideoModal vedioUrl={itemDetail?.feature_video} onClose={toggleFaturedVideoModal}/>}
        </>
      )}
    </div>
  );
}
