import React from "react";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import CartItem2 from "../../Components/CartItem2";
import { useNavigate } from "react-router-dom";
import { cartData } from "../../Constant/Data";
export default function AddToCart2() {
  const navigate = useNavigate();
  const onCheckout = () => {
    navigate("/Checkout");
  };
  return (
    <>
      <Navbar2 />
      <div className="md:min-h-screen bg-gray-100 md:py-12">
        <div className=" max-w-md mx-auto  bg-white md:shadow-lg md:rounded-lg   md:max-w-5xl">
          <div className="md:flex ">
            <div className="w-full p-4 px-5 py-5">
              <div className="md:grid md:grid-cols-3 gap-2  ">
                <div className="col-span-2 px-1 py-5 md:p-5 mb-2 md:mb-0 min-h-[24rem] md:max-h-[34rem]  overflow-y-scroll  md:min-h-[30rem]">
                  <h1 className="text:lg md:text-xl font-medium ">
                    Shopping Cart
                  </h1>
                  {cartData.map((item, i) => (
                    <CartItem2 {...item} />
                  ))}
                </div>
                <div className="md:min-h-[30rem] md:max-h-[34rem] mb-2 md:mb-0 p-5 flex-col flex justify-between   bg-white rounded overflow-visible border-gray-200 border-2 ">
                  <div>
                    <span className="text:lg md:text-xl font-medium  block pb-3">
                      Summary
                    </span>
                  </div>
                  <div className="w-full ">
                    <div className="mb-2 flex justify-between ">
                      <p className="text-sm md:text-base text-gray-700">
                        Subtotal
                      </p>
                      <p className="text-sm md:text-base text-gray-700">
                        $129.99
                      </p>
                    </div>
                    <hr className="my-4 " />
                    <div className="flex justify-between  items-end   ">
                      <p className="text-base md:text-lg  font-bold">Total</p>
                      <div className="">
                        <p className="text-sm text-right text-gray-700">
                          including VAT
                        </p>
                        <p className="mb-1 text-base md:text-lg font-bold">
                          $134.98 USD
                        </p>
                      </div>
                    </div>
                    <button
                      onClick={onCheckout}
                      className="mt-6 w-full rounded-md bg-secondary py-1.5 font-medium text-blue-50 hover:bg-secondaryLight"
                    >
                      Check out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
