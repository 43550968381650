import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
export default function CartItem2({ name, image, price, cast }) {
  return (
    <div className="flex justify-between items-center mt-6 pt-6">
      <div className="flex  items-center">
        <img
          src={
            image ||
            "https://images.squarespace-cdn.com/content/v1/55e594e1e4b0a22db8637b85/1697210958361-25D7DSD7NL68TEKA7STK/The+beekeeper+NL.png"
          }
          className="rounded-lg  h-[5rem] w-[5rem]   md:h-[6rem] md:w-[6rem] object-cover  "
        />
        <div className="flex flex-col ml-4 md:ml-6">
          <span className="text-sm md:text-lg mr-3 md:mr-6 font-medium">
            {name || "The Beekeers"}
          </span>
          <span className="text-xs md:text-base font-light text-gray-700 mt-2">
            {cast || "Rony Atlan"}
          </span>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="pr-3 md:pr-8 ">
          <span className="text-sm md:text-base text-secondary font-medium">
            ${price || "10.50"}
          </span>
        </div>
        <div>
          <XMarkIcon className="h-4 w-h4 md:h-5 md:w-5 text-gray-700 hover:text-red-800" />
        </div>
      </div>
    </div>
  );
}
