import React from "react";
import { wishList } from "../assets/Images";

export default function EmptyList({ description, image, title, btn = true }) {
  return (
    <>
      <div className=" w-full h-screen flex flex-col items-center justify-center">
        <img
          src={image || wishList}
          className="h-[8rem] w-[8rem] md:h-[9rem] md:w-[9rem]"
        />
        <div className="flex flex-col items-center justify-center">
          <p className="text-2xl md:text-3xl lg:text-4xl text-gray-800 mt-12">
            {title || " No Items Found"}
          </p>
          <p className="md:text-lg lg:text-xl text-gray-600 mt-8">
            {description || " There is no items in your wishlist."}
          </p>
          {btn && (
            <a
              href="#"
              className="flex items-center space-x-2 bg-secondary hover:bg-orange-640 text-gray-100 px-4 py-2 mt-12 rounded transition duration-150"
              title="Return Home"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L7.414 9H15a1 1 0 110 2H7.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Return Home</span>
            </a>
          )}
        </div>
      </div>
    </>
  );
}
