import React from "react";
import { login } from "../assets/Images";
import {
  Player,
  ControlBar,
  ForwardControl,
  BigPlayButton,
  ReplayControl,
  LoadingSpinner,
} from "video-react";
export default function SampleVideoModal({ handelModal, onLoginClick,posterImage,vedioUrl,onClose }) {
  return (
    <div
      onClick={handelModal}
      className="modal  bg-black/80 h-screen fixed left-0 flex justify-center items-center top-0 w-full z-50 px-6 py-3 md:px-10  "
    >
      <div className=" relative h-[60vh] lg:h-[70vh]  p-6 px-2 md:px-8 rounded-md lg:max-w-[32vw] my-auto md:mx-auto lg:w-[70vw] w-[95vw] ">
    
     <button onClick={onClose}  data-ripple-light="true" class="z-20 mt-2 ml-2 text-white flex flex-col shrink-0 grow-0 justify-around 
                absolute  right-1    rounded-full
                  mr-1 mb-5 lg:mr-5 lg:mb-5 xl:mr-10 xl:mb-
                  dow-md shadow-gray-100 transition-all hover:shadow-lg hover:shadow-gray-100 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none 
                 hover:bg-white
                 hover:p-1.5
                  ">
  
  <svg class="h-7 w-7 text-white hover:text-black"  fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
</svg>


  </button>
      <Player
        autoPlay
        fluid={false}
        width={"100%"}
        height={"100%"}
        src={vedioUrl||"https://media.w3.org/2010/05/sintel/trailer_hd.mp4"}
      >
        <LoadingSpinner />
        <BigPlayButton className="text-secondary bg-white " position="center" />
        <ControlBar
          className="!bg-secondaryColorLight  !justify-center !items-center "
          autoHide={false}
        >
      
        </ControlBar>
      </Player>
      </div>
    </div>
  );
}
