import React from "react";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import { faqData } from "../../Constant/Data";
export default function FAQ() {
  function toggleFAQ(id) {
    const button = document.getElementById(id);
    const content = button.nextElementSibling;
    button.setAttribute(
      "aria-expanded",
      button.getAttribute("aria-expanded") === "false" ? "true" : "false"
    );
    content.style.maxHeight =
      button.getAttribute("aria-expanded") === "true"
        ? content.scrollHeight + "40px"
        : "0";
  }
  return (
    <>
      <Navbar2 />
      <div class="py-24 pt-14 px-6 sm:px-8 md:px-20 w-full mx-auto flex flex-col md:flex-row justify-center gap-12">
        <div class="flex flex-col text-left  md:basis-1/3">
          <p class="inline-block font-semibold text-primary mb-4">
            BAB DIGITAL FAQ
          </p>
          <p class="sm:text-4xl text-3xl font-extrabold text-base-content">
            Frequently Asked Questions
          </p>
        </div>
        <ul class="basis-1/3">
          {faqData.map(({ id, question, answer }, index) => (
            <li>
              <button
                id={`${id}`}
                class="relative flex gap-2 items-center w-full py-5 text-base font-semibold text-left border-t md:text-lg border-base-content/10"
                aria-expanded="false"
                onClick={() => toggleFAQ(`${id}`)}
              >
                <span class="flex-1 text-base-content">{question}</span>
                <svg
                  class="flex-shrink-0 w-4 h-4 ml-auto fill-current"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    class="transform origin-center transition duration-200 ease-out false"
                  ></rect>
                  <rect
                    y="7"
                    width="16"
                    height="2"
                    rx="1"
                    class="transform origin-center rotate-90 transition duration-200 ease-out false"
                  ></rect>
                </svg>
              </button>
              <div
                class="transition-all duration-300 ease-in-out max-h-0 overflow-hidden"
                // style="transition: max-height 0.3s ease-in-out 0s;"
              >
                <div class="pb-5 leading-relaxed">
                  <div class="space-y-2 leading-relaxed">{answer}</div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Footer />
    </>
  );
}
