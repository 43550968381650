import React from "react";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import CheckoutItem from "../../Components/CheckoutItem";
import { useNavigate } from "react-router-dom";
import CheckoutForm from "../../Components/CheckoutForm";
import useCheckout from "./useCheckout";
import PaymentModal from "../../Components/PaymentModal";
export default function Checkout() {
  const {
    itemDetail,
    subTotal,
    discountPrice,
    serviceCharges,
    total,
    modalType,
    userData,
    handelModalType,
  } = useCheckout();
  const navigate = useNavigate();
  const onPay = () => {
    navigate("/Profile");
  };
  return (
    <div>
      {modalType && (
        <PaymentModal handelModalType={handelModalType} type={modalType} />
      )}
      <Navbar2 />
      <div className="min-w-screen md:min-h-screen px-0  sm:px-5 lg:px-20 xl:px-32 bg-gray-50 py-0 md:py-8">
        <div className="w-full shadow-xl bg-white   md:rounded-md px-5 py-10 text-black">
          <div className="w-full">
            <div className="-mx-3 md:flex items-start">
              <div className="px-3 md:w-7/12 lg:pr-20">
                <h4 className="text-black font-semibold mb-6 text-lg md:text-2xl">
                  Checkout Items
                </h4>
                <div className="w-full mx-auto text-black font-light mb-6 border-b border-gray-300 pb-6 space-y-10">
                  <CheckoutItem {...itemDetail} />
                </div>
                <div className="mb-6 pb-6 border-b border-gray-200">
                  <div className="-mx-2 flex items-end ">
                    <div className="flex-grow px-2 lg:max-w-xs">
                      <label className="text-black font-semibold text-sm mb-2 ml-1">
                        Discount code
                      </label>
                      <div>
                        <input
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-secondary transition-colors"
                          placeholder="XXXXXX"
                          type="text"
                        />
                      </div>
                    </div>
                    <div className="px-2">
                      <button className="block w-full max-w-xs mx-auto border border-transparent bg-secondary hover:bg-secondaryLight focus:bg-secondaryLight text-white rounded-md px-5 py-2 font-semibold">
                        APPLY
                      </button>
                    </div>
                  </div>
                </div>
                <div className="mb-6 pb-6 border-b border-gray-300 text-black">
                  <div className="w-full flex mb-3 items-center">
                    <div className="flex-grow">
                      <span className="text-black">Subtotal</span>
                    </div>
                    <div className="pl-3">
                      <span className="font-semibold">${subTotal}</span>
                    </div>
                  </div>
                  <div className="w-full mb-3 flex items-center">
                    <div className="flex-grow">
                      <span className="text-black">Discount</span>
                    </div>
                    <div className="pl-3">
                      <span className="font-semibold">${discountPrice}</span>
                    </div>
                  </div>
                  <div className="w-full flex items-center">
                    <div className="flex-grow">
                      <span className="text-black">Service Charges</span>
                    </div>
                    <div className="pl-3">
                      <span className="font-semibold">${serviceCharges}</span>
                    </div>
                  </div>
                </div>
                <div className="mb-6 pb-6 border-b border-gray-300 md:border-none text-black text-xl">
                  <div className="w-full flex items-center">
                    <div className="flex-grow">
                      <span className="text-black">Total</span>
                    </div>
                    <div className="pl-3">
                      <span className="font-semibold">${total}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-3 md:px-8 md:w-2/5 space-y-4 ">
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-300 p-3 text-black font-light mb-6">
                  <div className="w-full flex mb-3 items-center">
                    <div className="w-32">
                      <span className="text-black font-semibold">Name</span>
                    </div>
                    <div className="flex-grow pl-3">
                      <span className="text-black font-medium">
                        {userData?.name}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="w-full mx-auto rounded-lg bg-white border border-gray-300 text-black font-light mb-6">
                  <div className="w-full p-3 border-b border-gray-200">
                    <div className="mb-8">
                      <label
                        htmlFor="type1"
                        className="flex items-center cursor-pointer"
                      >
                        <img
                          src="https://leadershipmemphis.org/wp-content/uploads/2020/08/780370.png"
                          className="h-6 "
                        />
                      </label>
                    </div>
                    <div>
                      <CheckoutForm
                        handelModalType={handelModalType}
                        amount={total}
                        id={itemDetail?.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
