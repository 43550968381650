import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {
  FilmIcon,
  MusicalNoteIcon,
  BookOpenIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { deepCopy, returnSubCategoryName } from "../helper/helper";
import { useReduxStore } from "../hooks/useReduxStore";
import { updateItem } from "../store/Reducers/itemReducer";
export default function HorizontalListCard({
  image,
  title,
  id,
  description,
  rating,
  type = "movie",
  views = 1,
  item = {},
  cast,
  sub_category = [],
  category,
  author,
}) {
  const { dispatch } = useReduxStore();
  // sub_category = returnSubCategoryName(sub_category);
  const navigate = useNavigate();
  const onClickCard = () => {
    dispatch(updateItem({ itemDetail: deepCopy(item) }));
    navigate(
      {
        pathname: "/Detail",
        search: `?id=${item?.id}`,
      },
      { state: { ...item } }
    );
  };
  const dummuImageUrl =
    "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";
  return (
    <Card
      onClick={onClickCard}
      className="w-full max-w-[11rem]  lg:max-w-[13rem] xl:max-w-[14rem]    shadow-lg my-2 hover:bg-orange-50 hover:cursor-pointer "
    >
      <CardHeader className="mx-2 my-1 " floated={false} color="blue-gray">
        <img
          className="h-[10rem] lg:h-[14rem] w-full object-cover"
          src={image || dummuImageUrl}
          alt="ui/ux review check"
        />
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
        {/* <IconButton
          size="sm"
          color="red"
          variant="text"
          className="!absolute top-4 right-4 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            className="h-6 w-6"
          >
            <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
          </svg>
        </IconButton> */}
      </CardHeader>
      <CardBody className="px-3  pt-2 pb-3">
        <div className="mb-1 flex items-center justify-between">
          <Typography
            variant="h5"
            color="blue-gray"
            className="font-semibold text-sm  lg:text-base !line-clamp-1"
          >
            {title || "Wooden House, Florida"}
          </Typography>
        </div>
        {/* {type === "Audio" && ( */}
        <Typography
          variant="paragraph"
          className="!line-clamp-1 text-xs mt-1 text-normal text-gray-500"
          // color="gray"
        >
          {author || cast || "Author"}
        </Typography>
        {/* )} */}
        {/* {type !== "music" && (
          <Typography
            variant="paragraph"
            className="line-clamp-2 text-sm"
            color="gray"
          >
            {sub_category ||
              " Enter a freshly updated and thoughtfully furnished peaceful home surrounded by ancient trees, stone walls, and open meadows."}
          </Typography>
        )} */}
        <div className="mt-5 flex gap-5 items-center ">
          <Typography
            color="blue-gray"
            className="flex items-center gap-1 font-normal text-xs  text-gray-800 "
          >
            {type === "Video" && (
              <FilmIcon className="h-4 w-4 text-secondary" />
            )}
            {type === "Audio" && (
              <MusicalNoteIcon className="h-4 w-4 text-secondary" />
            )}
            {type === "Book" && (
              <BookOpenIcon className="h-4 w-4 text-secondary" />
            )}
            {returnSubCategoryName(category)}
          </Typography>
          {/* <Typography
            color="blue-gray"
            className="flex items-center gap-1 font-normal text-xs text-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-0.5 h-5 w-5 text-yellow-700"
            >
              <path
                fillRule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clipRule="evenodd"
              />
            </svg>
            {rating || "5.0"}
            <EyeIcon className="h-4 w-4  text-secondary" />
            {views}
          </Typography> */}
        </div>
      </CardBody>
    </Card>
  );
}
