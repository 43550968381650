import { useEffect, useState } from "react";
import { useReduxStore } from "../../hooks/useReduxStore";
import { GetItemsByCategory } from "../../store/Actions/action";
import { scrollToTop, sorting } from "../../helper/helper";
import { updateHome } from "../../store/Reducers/homeReducer";
import { useMemo } from "react";
import { updateItem } from "../../store/Reducers/itemReducer";

const useProductByCategory = () => {
  const { dispatch, getState } = useReduxStore();
  const {
    items,
    selectedCategory,
    totalItemsCount,
    currentPage,
    lastPage,
    perPageCount,
  } = getState("Home");
  const { loading } = getState("AUTH");
  const { search } = getState("Item");
  const [sortBy, setSortBy] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({
    category: {},
    releasedDate: {},
    language: {},
  });
  const onSelect = (item, type) => {
    // console.log("type--------", type);
    let obj = { ...selectedFilter };
    obj[type] = item;
    setSelectedFilter({ ...obj });
  };
  const getItems = (page) => {
    let param = {
      id: selectedCategory?.id || 1,
      page: page || currentPage,
      category: selectedFilter?.category?.id,
      released_date: selectedFilter?.releasedDate?.id,
      language: selectedFilter?.language?.title,
      search: search?.length ? search : null,
    };

    dispatch(GetItemsByCategory(param));
    scrollToTop();
  };
  useEffect(() => {
    getItems(1);
  }, [selectedCategory, selectedFilter, search]);
  const onSorting = (item) => {
    setSortBy(item);
    // console.log("sort", item);
  };
  const onUnMount = () => {
    setSortBy(null);
    setSelectedFilter({
      category: {},
      releasedDate: {},
      language: {},
    });
    dispatch(updateHome({ selectedCategory: null, items: [], currentPage: 1 }));
    dispatch(updateItem({ search: "" }));
  };
  const listData = useMemo(() => {
    return sorting(
      [...items],
      sortBy?.id === 2 ? "desending" : sortBy?.id === 1 ? "assending" : null
    );
  }, [sortBy, items]);

  useEffect(() => {
    return () => {
      onUnMount();
    };
  }, []);

  return {
    items,
    totalItemsCount,
    currentPage,
    lastPage,
    perPageCount,
    loading,
    selectedCategory,
    sortBy,
    listData,
    selectedFilter,
    onSelect,
    onSorting,
    getItems,
  };
};

export default useProductByCategory;
