import React from "react";

export default function HorizontalCardLoader() {
  return (
    <>
      <div className=" w-full  flex items-center flex-col  ">
        <div className="flex flex-col bg-white shadow-md  rounded-md items-center">
          <div
            data-placeholder=""
            className="h-32  sm:h-52 w-full overflow-hidden relative bg-gray-200"
          />
          <div className="flex flex-col p-4">
            <div className="flex">
              <div
                data-placeholder=""
                className=" flex h-5 w-5 overflow-hidden relative bg-gray-200 mr-1"
              />
              <div
                data-placeholder=""
                className="flex h-5 w-24 sm:w-32 lg:w-[9rem] xl:w-[10rem] 3xl:w-[12rem]  overflow-hidden relative bg-gray-200"
              />
            </div>
            <div className="flex mt-1">
              <div
                data-placeholder=""
                className="flex h-5 w-5  overflow-hidden relative bg-gray-200 mr-1"
              />
              <div
                data-placeholder=""
                className="flex h-5 w-24 sm:w-32 lg:w-[9rem] xl:w-[10rem] 3xl:w-[12rem] overflow-hidden relative bg-gray-200"
              />
            </div>
          </div>
          <div className="w-full h-px  overflow-hidden relative bg-gray-200 m-4" />
          <div className="flex justify-between items-center p-4 w-full">
            <div
              data-placeholder=""
              className="mr-2 h-5 w-14  overflow-hidden relative bg-gray-200"
            ></div>
            <div
              data-placeholder=""
              className=" h-5 w-14 overflow-hidden relative bg-gray-200"
            ></div>
          </div>
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n    [data-placeholder]::after {\n        content: " ";\n        box-shadow: 0 0 50px 9px rgba(254,254,254);\n        position: absolute;\n        top: 0;\n        left: -100%;\n        height: 100%; \n        animation: load 1s infinite;\n    }\n    @keyframes load {\n        0%{ left: -100%}\n        100%{ left: 150%}\n    }\n',
        }}
      />
    </>
  );
}
