import React from "react";
import {
  Player,
  ControlBar,
  ForwardControl,
  BigPlayButton,
  ReplayControl,
  VolumeMenuButton,
  LoadingSpinner,
} from "video-react";
import { useReduxStore } from "../../hooks/useReduxStore";

const VedioPlayer = (props) => {
  const { getState, dispatch } = useReduxStore();
  const { itemDetail } = getState("Item");
  const { image: posterImage, file: vedioUrl } = itemDetail;
  return (
    <div className="h-screen overflow-hidden w-screen">
      <Player
        autoPlay
        fluid={false}
        width={"100%"}
        height={"100%"}
        poster={posterImage}
        src={vedioUrl}
      >
        <LoadingSpinner />
        <BigPlayButton className="text-secondary bg-white " position="center" />
        <ControlBar
          className="!bg-black  !justify-center !items-center "
          autoHide={false}
        >
          <ReplayControl seconds={10} order={2.2} />
          <ForwardControl seconds={10} order={3.2} />
        </ControlBar>
      </Player>
    </div>
  );
};

export default VedioPlayer;
