import React from 'react'
import { Fade,Flip,Slide,Zoom,Bounce } from "react-reveal";

export default function SoulFlowChooseUsSection() {
  return (
    <div className="bg-black">
    <section
      id="features"
      className="relative block px-6 py-10 md:py-20 md:px-10  border-t border-b border-neutral-900 bg-neutral-900/30"
    >
 <Fade
     duration={1000}
     distance={'6rem'}
     top
 >
 <div className="relative mx-auto max-w-5xl text-center">
        {/* <span className="text-gray-400 my-3 flex items-center justify-center font-medium uppercase tracking-wider">
          Why choose us
        </span> */}
        <h2 className="block w-full bg-gradient-to-b from-white to-gray-400 bg-clip-text font-bold text-transparent text-3xl sm:text-5xl">
        Why choose us
        </h2>
        <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-400">
        B.A.B Soul Flow Records unique commitment to our artists and our unwavering support for their creative endeavors. 
        </p>
      </div>
 </Fade>
  <Zoom  cascade   duration={1500}>
  <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3 md:justify-center">
        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
          <div
            className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
            style={{
              backgroundImage:
                "linear-gradient(rgb(255, 140, 33) 0%, rgb(255, 182, 113) 100%)",
              borderColor: "rgb(255, 140, 33)"
            }}
          >
              <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height={24}
      width={24}
      strokeWidth={2}
    >
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M12 11a5 5 0 015 5v6h-2v-6a3 3 0 00-2.824-2.995L12 13a3 3 0 00-2.995 2.824L9 16v6H7v-6a5 5 0 015-5zm-6.5 3c.279 0 .55.033.81.094a5.947 5.947 0 00-.301 1.575L6 16v.086a1.492 1.492 0 00-.356-.08L5.5 16a1.5 1.5 0 00-1.493 1.356L4 17.5V22H2v-4.5A3.5 3.5 0 015.5 14zm13 0a3.5 3.5 0 013.5 3.5V22h-2v-4.5a1.5 1.5 0 00-1.356-1.493L18.5 16c-.175 0-.343.03-.5.085V16c0-.666-.108-1.306-.309-1.904A3.42 3.42 0 0118.5 14zm-13-6a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm13 0a2.5 2.5 0 110 5 2.5 2.5 0 010-5zm-13 2a.5.5 0 100 1 .5.5 0 000-1zm13 0a.5.5 0 100 1 .5.5 0 000-1zM12 2a4 4 0 110 8 4 4 0 010-8zm0 2a2 2 0 100 4 2 2 0 000-4z" />
    </svg>
         
          </div>
          <h3 className="mt-6 text-white text-xl font-bold">Experienced Team</h3>
          <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
          Our team consists of seasoned professionals with years of experience in music production and recording. We have worked with a diverse range of artists and genres, ensuring top-notch quality for every project.
          </p>
        </div>
        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
          <div
            className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
            style={{
              backgroundImage:
                "linear-gradient(rgb(255, 140, 33) 0%, rgb(255, 182, 113) 100%)",
              borderColor: "rgb(255, 140, 33)"
            }}
          >
              <svg fill="none" viewBox="0 0 15 15" height={24} width={24}    strokeWidth={2} >
      <path
        fill="currentColor"
        d="M4.5 7H4v1h.5V7zm6 1h.5V7h-.5v1zm-6-4H4v1h.5V4zm2 1H7V4h-.5v1zm4-4.5l.354-.354L10.707 0H10.5v.5zm3 3h.5v-.207l-.146-.147-.354.354zM8 11l-.354.354L8 11zm-.5.5l.224.447.04-.02.036-.027-.3-.4zM4.5 8h6V7h-6v1zm0-3h2V4h-2v1zm8 9h-10v1h10v-1zM2 13.5v-12H1v12h1zM2.5 1h8V0h-8v1zM13 3.5v10h1v-10h-1zM10.146.854l3 3 .708-.708-3-3-.708.708zM2.5 14a.5.5 0 01-.5-.5H1A1.5 1.5 0 002.5 15v-1zm10 1a1.5 1.5 0 001.5-1.5h-1a.5.5 0 01-.5.5v1zM2 1.5a.5.5 0 01.5-.5V0A1.5 1.5 0 001 1.5h1zm3.474 10.158c.111-.333.427-.642.83-.75.377-.101.862-.035 1.342.446l.708-.708c-.72-.72-1.569-.903-2.309-.704-.713.192-1.297.733-1.52 1.4l.95.316zm2.172-.304a.933.933 0 01.079.087l.79-.614a1.945 1.945 0 00-.161-.18l-.708.707zm.079.087c.078.1.06.132.063.11.002-.014.006.009-.054.063a1 1 0 01-.29.169 1.781 1.781 0 01-.394.108.848.848 0 01-.25.01c-.017-.004.018 0 .07.037a.388.388 0 01.131.43.209.209 0 01-.023.047c-.002.002.015-.02.072-.067.114-.092.324-.226.674-.4l-.448-.895c-.377.188-.66.36-.854.517a1.375 1.375 0 00-.26.267.705.705 0 00-.14.438c.01.216.127.375.255.468.113.084.238.12.33.139.187.037.4.027.593-.002.38-.058.872-.222 1.207-.526.174-.159.339-.387.374-.686.036-.306-.074-.593-.267-.84l-.79.613zm.075.459a2.56 2.56 0 01.518-.307l-.397-.918c-.24.104-.48.245-.721.425l.6.8zm.518-.307c.65-.281 1.231-.133 1.826.15.15.072.296.15.444.23.144.078.296.161.44.235.276.139.618.292.972.292v-1c-.094 0-.248-.047-.52-.184-.128-.066-.262-.14-.416-.223-.15-.081-.316-.17-.49-.252-.698-.333-1.611-.616-2.653-.166l.397.918z"
      />
    </svg>
         
          </div>
          <h3 className="mt-6 text-white text-xl font-bold">Unique Contract Opportunities </h3>
          <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
          Our team thrives on tackling unique and challenging projects, offering bespoke contract opportunities tailored to meet the specific needs of our clients. We pride ourselves on our ability to adapt and innovate, providing flexible and creative solutions that drive success. 
          </p>
        </div>
        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
          <div
            className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
            style={{
              backgroundImage:
                "linear-gradient(rgb(255, 140, 33) 0%, rgb(255, 182, 113) 100%)",
              borderColor: "rgb(255, 140, 33)"
            }}
          >
                <svg
      viewBox="0 0 24 24"
      fill="currentColor"
      height={24}
      width={24}
      strokeWidth={2}

    >
      <path d="M11.13 7.171c-.496.42 2.943-.458 2.6 1.239-.332 1.633-3.62-.343-7.223-.176-1.594.073-3.054.53-3.985 1.668.973-1.108 2.901-.844 2.398-.081-1.172 1.776-3.376.497-4.92 3.975.185-.4.685-1.196 2.843-1.526 1.586-.242 4.214-.016 5.054 1.297.924 1.444-3.759 1.28-1.167 1.573 3.593.406 6.299 3.31 9.813 3.311 4.55 0 7.422-2.324 7.457-6.146.063-6.923-9.101-8.318-12.87-5.134zm6.768 7.554c-1.195-.033-2.404-.512-3.364-.98-2.365-1.155-3.338-1.553-3.338-1.608 0-.067 1.42.484 3.813-.789 1.383-.735 1.432-1.377 2.89-1.505 1.73-.152 2.962 1.13 2.962 2.478 0 1.349-1.222 2.453-2.963 2.404z" />
    </svg>
        
          </div>
          <h3 className="mt-6 text-white text-xl font-bold">Digital Media Platform</h3>
          <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
          Our digital media platform is a comprehensive solution designed to cater to the diverse needs of our clients. With a focus on user engagement, scalability, and seamless content delivery, we provide an innovative platform that transforms ideas into impactful digital experiences.
          </p>
        </div>
        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
          <div
            className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
            style={{
              backgroundImage:
                "linear-gradient(rgb(255, 140, 33) 0%, rgb(255, 182, 113) 100%)",
              borderColor: "rgb(255, 140, 33)"
            }}
          >
                <svg fill="none" viewBox="0 0 24 24" height={24} width={24}
              strokeWidth={2}

                 >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M12.26 21.997a10.276 10.276 0 01-.52 0 10.004 10.004 0 01-8.983-6.173 10.034 10.034 0 01.017-7.69A10.015 10.015 0 014.908 4.95l.042-.042a10.015 10.015 0 013.167-2.126 10.034 10.034 0 017.753-.006 10.015 10.015 0 013.186 2.138l.03.03c.913.917 1.65 2.01 2.153 3.223a10.012 10.012 0 01.76 3.985 10.004 10.004 0 01-6.226 9.112 10.013 10.013 0 01-3.512.733zm1.772-6.55l2.874 2.873a8.004 8.004 0 01-9.812 0l2.874-2.874a4.007 4.007 0 004.064 0zm-5.478-1.415L5.68 16.906a8.004 8.004 0 010-9.812l2.874 2.874a4.007 4.007 0 000 4.064zm1.528-1.463a2.01 2.01 0 01-.014-1.087 1.99 1.99 0 01.518-.896 1.99 1.99 0 011.932-.518c.328.088.639.26.896.518a1.99 1.99 0 01.518 1.932c-.088.328-.26.639-.518.896a1.99 1.99 0 01-1.932.518 1.991 1.991 0 01-.896-.518 1.99 1.99 0 01-.504-.845zm3.95-4.015a4.007 4.007 0 00-4.064 0L7.094 5.68a8.004 8.004 0 019.812 0l-2.874 2.874zm4.288 8.352a8.004 8.004 0 000-9.812l-2.874 2.874a4.007 4.007 0 010 4.064l2.874 2.874z"
        clipRule="evenodd"
      />
    </svg>
        
          </div>
          <h3 className="mt-6 text-white text-xl font-bold"> Collaborative Approach</h3>
          <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
          We believe in the power of collaboration and work closely with our clients to achieve their goals. Our collaborative approach ensures that your vision is realized, and every project is a success.
          </p>
        </div>
        <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
          <div
            className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border "
            style={{
              backgroundImage:
                "linear-gradient(rgb(255, 140, 33) 0%, rgb(255, 182, 113) 100%)",
              borderColor: "rgb(255, 140, 33)"
            }}
          >
             <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height={24}
      width={24}
      strokeWidth={2}

    >
      <path d="M32 32C14.3 32 0 46.3 0 64v368c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V152.2c0-18.2-19.4-29.7-35.4-21.1L320 215.4v-63.2c0-18.2-19.4-29.7-35.4-21.1L128 215.4V64c0-17.7-14.3-32-32-32H32z" />
    </svg>
           
          </div>
          <h3 className="mt-6 text-white text-xl font-bold"> Industry Connections </h3>
          <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-400">
          Our extensive network of industry connections gives us a competitive edge, enabling us to provide exceptional services and opportunities to our clients.
          </p>
        </div>
      </div>
  </Zoom>
      {/* <div
        className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b"
        style={{
          backgroundImage:
            "linear-gradient(to right top, rgba(79, 70, 229, 0.2) 0%, transparent 50%, transparent 100%)",
          borderColor: "rgba(92, 79, 240, 0.2)"
        }}
      ></div> */}
      <div
        className="absolute bottom-0 right-0 z-0 h-1/3 w-full"
        style={{
          backgroundImage:
            "linear-gradient(to left top, rgba(220, 38, 38, 0.2) 0%, transparent 50%, transparent 100%)",
          borderColor: "rgba(92, 79, 240, 0.2)"
        }}
      ></div>
    </section>
  </div>
  )
}
