import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../Constant/Data";

export default function OTP() {
  const location = useLocation();
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState("");
  const [isResending, setIsResending] = useState(false); // State for managing resend status

  // Retrieve the email from state
  const email = location.state?.email || 'No message passed';

  const handleSubmit = async (event) => {
    event.preventDefault();

    let newErrors = {};
    if (!otp) newErrors.otp = "OTP is required";

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setErrors({}); // Clear errors if no validation errors

    const formData = {
      email: email,
      otp: otp,
    };

    try {
      const response = await fetch('https://admin.bab-media.com/public/api/verify-otp', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        toast.success(
          "OTP verified successfully!", toastConfiguration
        );
        setOtp("");
        navigate("/Login");
      } else {
        setMessage(data.message || "An error occurred. Please try again.");
        
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    }
  };

  const resendOtp = async () => {
    setIsResending(true);
    
    // Encode email for safe URL usage
    const encodedEmail = encodeURIComponent(email);
  
    try {
      const response = await fetch(`https://admin.bab-media.com/public/api/resend-otp?email=${encodedEmail}`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        setMessage("OTP sent again successfully!");
       
      } else {
        setMessage(data.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
    } finally {
      setIsResending(false);
    }
  };
  

  return (
    <section className="min-h-screen flex items-stretch text-white">
      <div
        className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
        style={{
          backgroundImage: "url(https://image.hoopladigital.com/www/home/banner.jpg)",
        }}
      >
        <div className="absolute bg-black opacity-60 inset-0 z-0" />
        <div className="w-full px-24 z-10">
          <h1 className="text-5xl font-bold text-left tracking-wide">
            Keep it special
          </h1>
          <p className="text-3xl my-4">
            Instant access to millions of audiobooks, ebooks, Movies, audios, and more
          </p>
        </div>
      </div>
      <div
        className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0"
        style={{ backgroundColor: "#161616" }}
      >
        <div
          className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
          style={{
            backgroundImage: "url(https://image.hoopladigital.com/www/home/banner.jpg)",
          }}
        >
          <div className="absolute bg-black opacity-60 inset-0 z-0" />
        </div>
        <div className="w-full py-6 z-20">
          <h1 className="my-6 text-3xl font-bold">Verify OTP</h1>

          {/* Display email at the top of the form */}
          {email && (
            <p className="text-xl text-gray-300 mb-4">
              A verification code has been sent to <strong>{email}</strong>
            </p>
          )}

          <form onSubmit={handleSubmit} className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto">
            

            <div className="mb-4">
              <label htmlFor="otp" className="block text-gray-100 mb-2">OTP</label>
              <input
                type="text"
                id="otp"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="w-full p-2 rounded bg-gray-700 text-white"
              />
              {errors.otp && <p className="text-red-500">{errors.otp}</p>}
            </div>

            {/* <div className="text-right py-2 text-gray-400 hover:underline hover:text-gray-100">
              <a href="/login">Already Have an Account?</a>
            </div> */}
            <div className="px-4 pb-2 pt-4">
              <button type="submit" className="uppercase block w-full p-4 text-lg rounded-full bg-secondary hover:bg-secondary/75 focus:outline-none">
                Verify OTP
              </button>
            </div>
            <p className="text-gray-100 py-3">OR</p>
            <div className="text-center text-gray-400 hover:underline hover:text-gray-100">
              <a href="https://admin.bab-media.com/public/login">Become a Seller</a>
            </div>
            <div className="mt-2 text-center text-gray-400 hover:underline hover:text-gray-100">
              <a href="#" onClick={(e) => { e.preventDefault(); resendOtp(); }}>
                {isResending ? "Resending OTP..." : "Resend OTP"}
              </a>
            </div>

            {message && <p className={`mt-4 ${errors.otp ? 'text-red-500' : 'text-red-500'}`}>{message}</p>}
          </form>
        </div>
      </div>
    </section>
  );
}
