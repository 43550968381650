import React, { useState } from "react";
import { useReduxStore } from "../../hooks/useReduxStore";
const useCheckout = () => {
  const [modalType, setModalType] = useState(null);
  const { dispatch, getState } = useReduxStore();
  const { userData } = getState("AUTH");
  const { itemDetail } = getState("Item");
  const subTotal = itemDetail?.price || 0;
  const discountPrice = 0;
  const serviceCharges = Number(itemDetail?.category[0]?.service_charges || 0);
  const total = subTotal - discountPrice + serviceCharges;
  const handelModalType = (type) => setModalType(type);
  return {
    itemDetail,
    subTotal,
    discountPrice,
    serviceCharges,
    total,
    modalType,
    handelModalType,
    userData,
  };
};

export default useCheckout;
