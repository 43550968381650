import { Carousel } from '@material-tailwind/react';
import React from 'react'
import { Fade,Flip,Slide } from "react-reveal";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
export default function MystioBanner() {
  return (
    <div className="bg-black h-[100vh] py-20 border-t-secondary border-t-2 ">
    <div className="container mx-auto px-6 md:px-12">
      <div className="flex mr-4 sm:mr-0 flex-col md:flex-row items-center">
        <div className="md:w-1/2 lg:w-2/3">
       <Fade
          duration={1200}
          distance={'6rem'}
          top
          cascade
          
       >
          <h1 className="text-4xl md:text-6xl lg:text-7xl text-white font-bold mb-6">
            <span className="text-secondary ">Boss Amongst Bosses  </span>
            <br/>
            in Retail Delivery 
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl text-gray-400 mb-8 leading-2">
        
      Mystio is more than just a delivery service <br/> it's a brand synonymous with trust and excellence. 
          </p>
          </Fade>
        
        </div>
        <div className="md:w-1/2 lg:w-1/3 mt-8 md:mt-0 w-full">
        <Swiper
        spaceBetween={10}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          {({isActive})=>(
              <Fade
              duration={900}
              distance={'2rem'}
              right
              cascade
              when={isActive}
              
           >
           <img
                src={require('../assets/Designer (7).png')}
                alt="Hero Image"
                className="rounded-lg shadow-lg "
              />
           </Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
   <Fade
   duration={900}
   distance={'2rem'}
   right
   cascade
   when={isActive}
>
<img
     src={require('../assets/mystio.png')}
     alt="Hero Image"
     className="rounded-lg shadow-lg "
   />
</Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
    <Fade
    duration={900}
    distance={'2rem'}
    right
    cascade
    when={isActive}   
   >
   <img
        src={require('../assets/mystio3.png')}
        alt="Hero Image"
        className="rounded-lg shadow-lg "
      />
   </Fade>
          )}
        </SwiperSlide>
        </Swiper>

        </div>
      </div>
    </div>
  </div>
  )
}
