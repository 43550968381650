import React, { useState } from "react";
import { Link } from "react-router-dom";
import AuthInput from "../../Components/AuthInput";
import Footer from "../../Components/Footer/Footer";
import useSignup from "./useSignup";
import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = "6Le84TsqAAAAACaoQneF1ELV6M8LtgExgrcHGdPl"; // Replace with your Site Key

export default function Signup() {
  const { register, handleSubmit, onSubmit, errors, setEmail, email } = useSignup();
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);

  // Function to handle email input change
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Callback for reCAPTCHA verification
  const handleCaptcha = (token) => {
    setCaptchaToken(token);
    setCaptchaError(false); // Reset any previous CAPTCHA error
  };

  // Handle form submission
  const handleFormSubmit = (data) => {
    if (!captchaToken) {
      setCaptchaError(true); // Show an error if CAPTCHA is not completed
      return;
    }

    onSubmit({ ...data, captchaToken });
  };

  return (
    <>
      <section className="min-h-screen flex items-stretch text-white">
        <div
          className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
          style={{
            backgroundImage:
              "url(https://image.hoopladigital.com/www/home/banner.jpg)",
          }}
        >
          <div className="absolute bg-black opacity-60 inset-0 z-0" />
          <div className="w-full px-24 z-10">
            <h1 className="text-5xl font-bold text-left tracking-wide">
              Keep it special
            </h1>
            <p className="text-3xl my-4">
              Instant access to millions of audiobooks, ebooks, Movies, audios,
              and more
            </p>
          </div>
        </div>
        <div
          className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-6 z-0"
          style={{ backgroundColor: "#161616" }}
        >
          <div
            className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
            style={{
              backgroundImage:
                "url(https://image.hoopladigital.com/www/home/banner.jpg)",
            }}
          >
            <div className="absolute bg-black opacity-60 inset-0 z-0" />
          </div>
          <div className="w-full py-6 z-20">
            <h1 className="my-6 text-3xl font-bold">Welcome To BAB Digital</h1>

            <form
              onSubmit={handleSubmit(handleFormSubmit)}
              className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
            >
              <AuthInput
                errors={errors}
                register={register}
                type="text"
                name="name"
                id="first-name"
                placeholder="Enter Your Name"
              />

              <AuthInput
                errors={errors}
                register={register}
                type="email"
                name="email"
                id="email"
                placeholder="Email"
                onChange={handleEmailChange}
              />
              <AuthInput
                errors={errors}
                register={register}
                type="password"
                name="password"
                id="password"
                placeholder="Password"
              />
              <AuthInput
                errors={errors}
                register={register}
                type="password"
                name="password_confirmation"
                id="cpassword"
                placeholder="Confirm Your Password"
              />

              {/* Add reCAPTCHA widget */}
              <ReCAPTCHA
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleCaptcha}
                className="my-4"
              />
              {captchaError && (
                <div className="text-red-500">Please complete the reCAPTCHA</div>
              )}

              <div className="text-right py-2 text-gray-400 hover:underline hover:text-gray-100">
                <Link to="/Login">Already Have an Account?</Link>
              </div>

              <div className="px-4 pb-2 pt-4">
                <button
                  type="submit"
                  className="uppercase block w-full p-4 text-lg rounded-full bg-secondary hover:bg-secondary/75 focus:outline-none"
                >
                  Register
                </button>
              </div>
              <p className="text-gray-100 py-3">OR</p>
              <div className="text-center text-gray-400 hover:underline hover:text-gray-100">
                <a href="https://admin.bab-media.com/public/login">Become a Seller</a>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
