import React from "react";

export default function CheckoutItem({ image, title, cast, price, author }) {
  const secondText = author || "William joness";
  return (
    <div className="w-full flex items-center">
      <div className="overflow-hidden rounded-lg w-[4.5rem] h-[4.5rem] md:w-[6rem] md:h-[6rem] bg-gray-50 border border-gray-200">
        <img
          src={image}
          alt=""
          className="object-cover object-center w-[4.5rem] h-[4.5rem] md:w-[6rem] md:h-[6rem]"
        />
      </div>
      <div className="flex-grow pl-3 md:pl-5">
        <h6 className="font-semibold  text-sm md:text-xl md:mr-8 mr-4 text-black">
          {title}
        </h6>
        <p className="text-gray-600 text-sm md:text-base font-normal mt-2 ">
          {secondText}
        </p>
      </div>
      <div>
        <span className="font-semibold text-secondary text-base md:text-xl">
          ${price}
        </span>
        <span className="font-semibold text-secondary text-sm">.00</span>
      </div>
    </div>
  );
}
