import { Link } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
import useForgetPassword from "./useForgetPassword";
import AuthInput from "../../Components/AuthInput";
import EmailSent from "../../Components/EmailSent";

export default function ForgetPassword() {
  const { register, handleSubmit, onSubmit, errors, emailSent, email } =
    useForgetPassword();
  return (
    <>
      {/* <Navbar></Navbar> */}
      <>
        <section className="min-h-screen flex items-stretch text-white ">
          <div
            className="lg:flex w-1/2 hidden bg-gray-500 bg-no-repeat bg-cover relative items-center"
            style={{
              backgroundImage:
                "url(https://image.hoopladigital.com/www/home/banner.jpg",
            }}
          >
            <div className="absolute bg-black opacity-60 inset-0 z-0" />
            <div className="w-full px-24 z-10">
              <h1 className="text-5xl font-bold text-left tracking-wide">
                Keep it special
              </h1>
              <p className="text-3xl my-4">
                Instant access to millions of audiobooks, ebooks, Movies,audios,
                and more
              </p>
            </div>
          </div>
          <div
            className="lg:w-1/2 w-full flex items-center justify-center text-center md:px-16 px-0 z-0"
            style={{ backgroundColor: "#161616" }}
          >
            <div
              className="absolute lg:hidden z-10 inset-0 bg-gray-500 bg-no-repeat bg-cover items-center"
              style={{
                backgroundImage:
                  "url(https://image.hoopladigital.com/www/home/banner.jpg",
              }}
            >
              <div className="absolute bg-black opacity-60 inset-0 z-0" />
            </div>
            <div className="w-full py-6 z-20">
              <h1 className="my-6 text-3xl font-bold">Reset Your Password</h1>

              {emailSent ? (
                <EmailSent email={email} />
              ) : (
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  className="sm:w-2/3 w-full px-4 lg:px-0 mx-auto"
                >
                  <p className="text-gray-100 my-3 text-center">
                    Please enter the email address associated with your BAB
                    Digital account. We'll send you an email to help you reset
                    your password.
                  </p>
                  <AuthInput
                    errors={errors}
                    register={register}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    hidden={false}
                  />

                  <div className="px-4 pb-2 pt-4">
                    <button className="uppercase block w-full p-4 text-lg rounded-full bg-secondary  hover:bg-secondary/75  focus:outline-none">
                      Send Email
                    </button>
                  </div>
                  <div className="mt-2 text-center text-gray-400 hover:underline hover:text-gray-100">
                    <Link to="/Login">Remember Password</Link>
                  </div>
                </form>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </>
    </>
  );
}
