import React from "react";
import { getYear } from "../helper/helper";
import { PlayIcon, PauseIcon } from "@heroicons/react/24/outline";

export default function PlaylistItem({
  image,
  title,
  name,
  publish_date,
  isActive,
}) {
  return (
    <tr
      className={`hover:!bg-orange-50 !cursor-pointer ${
        isActive ? "!bg-orange-50" : "!bg-white"
      } ${!isActive ? "!text-gray-800" : "!text-secondary"}`}
    >
      <td className=" px-1 md:px-5 py-[7px] border-b  border-gray-200  text-sm mr-5 md:mr-0">
        <div className="flex items-center">
          <div className="flex-shrink-0 w-10 h-10">
            <img
              className="w-full h-full rounded-md shadow-sm"
              src={
                image ||
                "https://bab-digital.design-services-online.com/bab-digital-dev/public/storage/admin/feature_images/ehWYQFcTFdaCiccLWgfIGAMLZ2oA1CnAu48DX3Im.jpg"
              }
              alt=""
            />
          </div>
          <div className="ml-3">
            <p className=" text-xs md:text-sm font-semibold whitespace-no-wrap ">
              {title || name || "Vera Carpenter"}
            </p>
          </div>
        </div>
      </td>
      <td className="px-5 text-center md:px-5 py-[7px] border-b border-gray-200  text-sm">
        <p className=" text-xs md:text-sm font-semibold whitespace-no-wrap ">
          Taylor Swift
        </p>
      </td>
      <td className="px-5 md:px-5 py-[7px] border-b text-center border-gray-200  text-sm">
        <p className=" text-xs md:text-sm  font-semibold whitespace-no-wrap">
          {getYear(publish_date || "Jan 21, 2020")}
        </p>
      </td>
      <td className=" px-5 text-center md:px-5 py-[7px] border-b border-gray-200  text-xs md:text-sm">
        {/* <div className="rounded-full p-[6px] bg-secondary  hover:bg-orange-200 relative inline-block  cursor-pointer hover:shadow-4xl ">
          {isActive ? (
            <PauseIcon className="h-3 w-3 text-white" />
          ) : (
            <PlayIcon className="h-3 w-3 text-white" />
          )}
        </div> */}
        <p className=" text-xs md:text-sm  font-semibold whitespace-no-wrap">
          {"5:30"}
        </p>
      </td>
    </tr>
  );
}
