import React, { useState } from "react";
import CollectionBanner from "../../Components/CollectionBanner.jsx";
import { FilterModal } from "../../Components/FilterModal.jsx";
import FilterSection from "../../Components/FilterSection.jsx";
import Footer from "../../Components/Footer/Footer.js";
import HorizontalListCard from "../../Components/HorizontalListCard.jsx";
import { Navbar2 } from "../../Components/Navbar2/Navbar2.js";
import { sortingData } from "../../Constant/Data.js";
import useProductByCategory from "./useProductByCategory.js";
import Pagination from "../../Components/Pagination.jsx";
import SelectBoxSimple from "../../Components/SelectBoxSimple.jsx";
import { FunnelIcon } from "@heroicons/react/20/solid";
import EmptyList from "../../Components/EmptyList.jsx";
import { emptyItem } from "../../assets/Images.js";
export default function ProductByCategory() {
  const {
    items,
    selectedCategory,
    sortBy,
    totalItemsCount,
    currentPage,
    lastPage,
    perPageCount,
    listData,
    selectedFilter,
    onSelect,
    onSorting,
    getItems,
  } = useProductByCategory();
  let data = window?.history?.state?.usr || {};
  let { type } = data || {};
  const [category, setCategory] = useState(type || "ebook");
  const [open, setOpen] = React.useState(false);
  const openFilter = () => setOpen(true);
  const closeFilter = () => setOpen(false);
  const isMusic = category === "music";
  const isMovie = category === "movie";
  const isEbook = category === "ebook";
  const isAudioBook = category === "audio_book";
  const categoryTitle = isAudioBook
    ? "Audio Books"
    : isEbook
    ? "E-Books"
    : isMusic
    ? "Musics"
    : isMovie
    ? "Movies"
    : "Seasons";
  const bannerImage = isAudioBook
    ? "https://s.zst.com.br/cms-assets/2021/05/audio_books_zoom_capa.jpg"
    : isEbook
    ? "https://perspectivesonreading.com/wp-content/uploads/2019/03/POR-Reading-Heros1.jpg"
    : isMusic
    ? "https://fiverr-res.cloudinary.com/images/q_auto,f_auto/gigs3/313825339/original/e43988be7a19cbf652a84d7cb57bc0f17ef53f3a/design-amazing-music-album-cover.png"
    : isMovie
    ? "https://149512919.v2.pressablecdn.com/wp-content/uploads/2021/12/philly-movies-hero-banner.jpg"
    : "Seasons";

  const onCategoryPress = (type) => {
    setCategory(type);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <Navbar2
        {...{
          category,
          onCategoryPress,
        }}
      ></Navbar2>
      <div className="min-h-screen">
        <CollectionBanner
          bannerImage={selectedCategory?.image || bannerImage}
          categoryTitle={selectedCategory?.name || categoryTitle}
        />

        <section className="h-auto w-full px-0 xl:px-14  py-0 flex flex-col justify-center align-center  ">
          <div className="py-3 flex px-4  justify-between center lg:hidden bg-black">
            <button
              onClick={openFilter}
              className="flex cursor-pointer items-center gap-2 border-b  pb-1 text-white transition "
            >
              <p className="text-base font-semibold "> Filters </p>
            </button>
            <FunnelIcon
              onClick={openFilter}
              className="md:h-6 md:w-6 h-5 w-5 text-white"
            />
          </div>
          <div className="mx-auto max-w-screen px-0 py-0 sm:px-6 sm:py-6 lg:px-8">
            <div className="mt-4 lg:mt-8 lg:grid lg:grid-cols-4 lg:items-start lg:gap-8">
              <div className="hidden space-y-4 lg:block">
                <FilterSection
                  onSelect={onSelect}
                  selectedFilter={selectedFilter}
                />
              </div>

              <div className="lg:col-span-3 w-screen lg:w-auto px-2 md:px-0 lg:min-w-[65vw] ">
                <div className="flex justify-between w-full   items-center mb-6 md:mb-10">
                  <h3 className=" text-2xl md:text-4xl  font-bold text-black ">
                    {selectedCategory?.name || categoryTitle}
                  </h3>
                  <div className="inline-flex items-center ">
                    <SelectBoxSimple
                      data={[...sortingData]}
                      label={"Sort By"}
                      onItemClick={onSorting}
                      selected={sortBy}
                    />
                  </div>
                </div>

                {items?.length ? (
                  <ul className="grid grid-cols-2  min-h-[70vh] gap-4 sm:grid-cols-2 md:grid-cols-4  xl:grid-cols-5 3xl:grid-cols-6">
                    {listData?.map((item, i) => (
                      <li>
                        <HorizontalListCard
                          {...item}
                          item={item}
                          // type={category || "ebook"}
                        />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <EmptyList
                    description={`There is no items in  ${selectedCategory?.name}`}
                    image={emptyItem}
                    btn={false}
                  />
                )}

                {items?.length ? (
                  <Pagination
                    {...{
                      totalItemsCount,
                      currentPage,
                      lastPage,
                      perPageCount,
                      onBtnClick: getItems,
                    }}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer onCategoryPress={onCategoryPress} />
      <FilterModal
        open={open}
        onOpen={openFilter}
        onClose={closeFilter}
        onSelect={onSelect}
        selectedFilter={selectedFilter}
      />
    </>
  );
}
