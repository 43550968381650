import Article from "../../Components/MystioIntoduction.js";
import BABSoulFlowFooter from "../../Components/BABSoulFlowFooter.jsx";
import { BABSoulFlowHeader } from "../../Components/BABSoulFlowHeader.js";
import BABSoulFlowStartingInstruction from "../../Components/BABSoulFlowStartingInstruction.jsx";
import BABSoulFlowWorks from "../../Components/BABSoulFlowWorks.jsx";
import ConnectUsSection from "../../Components/ConnectUsSection.jsx";
import LandingPageBanner from "../../Components/LandingPageBanner.jsx";
import SoulFlowAboutSection from "../../Components/SoulFlowAboutSection.jsx";
import SoulFlowChooseUsSection from "../../Components/SoulFlowChooseUsSection.jsx";
import SoulFlowContactUsSection from "../../Components/SoulFlowContactUsSection.jsx";
import SoulFlowServiceSection from "../../Components/SoulFlowServiceSection.jsx";
import SoulFlowStatsSection from "../../Components/SoulFlowStatsSection.jsx";
import useLanding from "./useLanding.js";
import MystioIntoduction from "../../Components/MystioIntoduction.js";
import { Navbar2 } from "../../Components/Navbar2/Navbar2.js";



export default function Landing() {

useLanding()
  return (
 <>
{/* <BABSoulFlowHeader /> */}
<Navbar2 />
<div className=" center bg-black">
<LandingPageBanner/>
<SoulFlowAboutSection/>
<SoulFlowServiceSection/>
{/* <BABSoulFlowWorks/> */}
<SoulFlowChooseUsSection/>
{/* <BABSoulFlowStartingInstruction/> */}
<ConnectUsSection/>
<SoulFlowStatsSection/>
<SoulFlowContactUsSection/>
<MystioIntoduction/>
</div>
<BABSoulFlowFooter />
      </>
  );
}
