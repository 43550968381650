import React from "react";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import { termsAndPolicyContentArray } from "../../Constant/Data";
export default function PrivacyPolicy() {
  return (
    <>
      <Navbar2 />
      <div className="min-w-screen min-h-screen px-2 sm:px-5 lg:px-20 xl:px-32  py-8">
        <div className="container mx-auto p-4">
          <h1 className="text-3xl font-semibold text-center mb-8  ">
            Terms and Conditions Policy
          </h1>
          {termsAndPolicyContentArray.map((item, i) => (
            <div className="bg-white p-6 shadow-md rounded-lg mb-4">
              <h2 className="text-xl font-semibold mb-4">{item?.title}</h2>
              {item?.points?.map((item2) => (
                <p className="mb-2">{item2}</p>
              ))}
            </div>
          ))}
          <p className="mb-2 text-xl  my-10">
            By using Boss Amongst Bosses, you acknowledge that you have read,
            understood, and agreed to these terms and conditions. It is
            recommended to review them periodically for any updates or changes.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
