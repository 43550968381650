import React from "react";
import { Link } from "react-router-dom";
import { NoSymbolIcon } from "@heroicons/react/20/solid";
export default function PaymentFailed({ handelModalType }) {
  const onOkClick = () => handelModalType(null);
  return (
    <div className="bg-white p-6 px-4 md:px-20 rounded-sm flex flex-col justify-center items-center  md:mx-auto">
      <NoSymbolIcon className="h-[7rem] w-[7rem] text-red-700 py-3 mb-3" />
      <div className="text-center">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
          Transaction Failed
        </h3>
        <p className="text-gray-800 md:text-base my-2 mt-4">
          Your Transaction has failed.
        </p>
        <p className="text-gray-800 md:text-base my-2">
          Please go back and try again.
        </p>
        <div
          onClick={onOkClick}
          className="my-6 mt-8 min-w-[12rem] py-3 bg-secondary hover:bg-orange-500 text-white font-semibold text-center"
        >
          Ok
        </div>
      </div>
    </div>
  );
}
