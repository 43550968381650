import React from "react";
import { FilterModal } from "../../Components/FilterModal.jsx";
import Footer from "../../Components/Footer/Footer.js";
import { Navbar2 } from "../../Components/Navbar2/Navbar2.js";
import ProfileMenuSection from "../../Components/ProfileMenuSection.jsx";
import ProfileForm from "../../Components/ProfileForm.jsx";
import MyItemsSection from "../../Components/MyItemsSection.jsx";
import MyWishlistSection from "../../Components/MyWishlistSection.jsx";
import { useEffect } from "react";
import { useReduxStore } from "../../hooks/useReduxStore.js";
import { useNavigate } from "react-router-dom";

export default function Profile() {
  const { getState, dispatch } = useReduxStore();
  const navigate = useNavigate();
  const { changeSuccess } = getState("AUTH");
  const [open, setOpen] = React.useState(false);
  const [tab, setTab] = React.useState("my_items");
  const openFilter = () => setOpen(true);
  const closeFilter = () => setOpen(false);
  const onSelectTab = (value) => {
    // console.log(value, "value");
    setTab(value);
  };
  useEffect(() => {
    changeSuccess && navigate("/");
  }, [changeSuccess]);
  return (
    <>
      <Navbar2></Navbar2>
      <div className="min-h-screen">
        <section className="h-auto w-full  xl:px-14  md:py-4 flex flex-col justify-center align-center  ">
          <div className="mx-auto max-w-screen py-0   sm:px-6 sm:py-6 lg:px-8">
            <div className="hidden w-full md:flex flex-col justify-center md:items-start items-center mb-4 md:mb-10 ">
              <h2 className="text-2xl font-bold text-black sm:text-4xl text-center lg:text-left">
                My Account
              </h2>
            </div>

            <div className="mt-0 w-full lg:mt-8 lg:grid lg:grid-cols-4 lg:items-start lg:gap-10">
              <div className="space-y-4 lg:block">
                <ProfileMenuSection
                  setSelectTab={onSelectTab}
                  selectedTab={tab}
                />
              </div>

              {tab === "profile" ? (
                <ProfileForm />
              ) : tab === "my_items" ? (
                <MyItemsSection />
              ) : (
                <MyWishlistSection />
              )}
            </div>
          </div>
        </section>
      </div>
      <Footer />
      <FilterModal open={open} onOpen={openFilter} onClose={closeFilter} />
    </>
  );
}
