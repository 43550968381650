import React from "react";
import {
  Drawer,
  Button,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import FilterSection from "./FilterSection";

export function FilterModal({
  open = true,
  onClose,
  onOpen,
  onSelect,
  selectedFilter,
}) {
  const openDrawer = () => {
    onOpen();
  };
  const closeDrawer = () => {
    onClose();
  };

  return (
    <Drawer
      overlayProps={{ ancestorScroll: true }}
      ancestorScroll={false}
      open={open}
      aria-disabled
      onClose={closeDrawer}
      className="p-4 min-h-screen h-auto overflow-y-scroll pb-48"
    >
      <div className="mb-6 flex items-center justify-between">
        <Typography variant="h5" color="blue-gray">
          Filter
        </Typography>
        <IconButton variant="text" color="blue-gray" onClick={closeDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-5 w-5"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </IconButton>
      </div>
      <FilterSection
        selectedFilter={selectedFilter}
        onSelect={onSelect}
        heading={false}
      />
    </Drawer>
  );
}
