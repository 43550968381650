import APIRequest from "./APIRequest";

export const getAllCategories = () => {
  return APIRequest.get(`categories`);
};
export const getSubCategories = () => {
  return APIRequest.get(`subcategories`);
};
export const getBanners = () => {
  return APIRequest.get(`promotions`);
};

export const trendingItems = () => {
  return APIRequest.get("products/trending");
};
export const popularItems = () => {
  return APIRequest.get("products/popular");
};
export const getItemsByCategory = ({ id, page, search }) => {
  let param = {};
  if (search) {
    param.title = search;
  }
  return APIRequest.get(`products/category/${id}?page=${page}`, param);
};
export const getPopularItemsByCategory = () => {
  return APIRequest.get(`categories/products`);
};
