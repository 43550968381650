import { useEffect } from "react";
import { useRef } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

const AudioPlayerComponent = ({
  onPlay,
  file,
  image = "https://marvel-b1-cdn.bc0a.com/f00000000280066/d2snwnmzyr8jue.cloudfront.net/sca_9781338635201_270.jpeg",
  title = "Title",
  author = "author",
  playerRef,
  isPlayerOpen,
  setIsPlayerOpen,
}) => {
  // console.log("playerRef-----", playerRef);
  const onPlayClick = () => {
    setIsPlayerOpen(true);
  };
  const onPause = () => {
    setIsPlayerOpen(false);
  };
  return (
    <div
      className="fixed shadow-xl  bottom-0 w-screen  z-50 animate-fade-up animate-ease-in
      animate-duration-300
      animate-normal
      animate-fill-both
      backdrop-blur
    "
    >
      <AudioPlayer
        autoPlay={false}
        className="backdrop-blur bg-white/50"
        ref={playerRef}
        controls={true}
        src={file}
        style={{
          // marginTop: "1.5rem",
          backgroundColor: "rgba(3, 3, 3, 0.8)",
          // backdropFilter: blur(9),
          // paddingTop: "3px",
          // paddingBottom: "5px",
        }}
        onPlay={onPlayClick}
        onPause={onPause}
        customAdditionalControls={[
          <img
            className="h-[2rem] w-[2rem] md:h-[2.5rem] md:w-[2.5rem] rounded-sm mr-2 md:mr-4 mt-1"
            src={image}
          />,
          <div className="mt-1">
            <p className="text-white font-medium max-w-[70px] md:max-w-[100px] line-clamp-1   text-xs md:text-sm ">
              {title}
            </p>
            <p className="text-gray-600 font-normal max-w-[70px] md:max-w-[90px] line-clamp-1 text-[10px]   md:text-xs ">
              {author}
            </p>
          </div>,
        ]}
      />
    </div>
  );
};

export default AudioPlayerComponent;
