import React from "react";
import Footer from "../../Components/Footer/Footer";
import { Navbar2 } from "../../Components/Navbar2/Navbar2";
import { termsAndPolicyContentArray } from "../../Constant/Data";
export default function PrivacyPolicy() {
  return (
    <>
      <Navbar2 />
      <div className="min-w-screen min-h-screen px-2 sm:px-5 lg:px-20 xl:px-32  py-8">
        <div className="container mx-auto py-4">
          <h1 className="text-3xl font-semibold text-center mb-8  ">
            Terms and Conditions Policy
          </h1>
          <p className="mb-3 font-normal mx-6">
            Please read these terms and conditions carefully before using the
            Boss Amongst Bosses BAB Media Digital Platform ("BAB Media"). By
            accessing or using BAB Media, you agree to be bound by these terms
            and conditions. If you do not agree with any part of these terms and
            conditions, you may not use BAB Media.
          </p>
          {termsAndPolicyContentArray.map((item, i) => (
            <div className="py-3 md:py-6 px-6 rounded-lg mb-2  md:mb-4">
              <h2 className="text-xl font-semibold mb-4">{item?.title}</h2>
              {item?.points?.map((item2) => (
                <p className="mb-3">{item2}</p>
              ))}
            </div>
          ))}
          <p className="mb-2 text-base mx-6   mb-10 ">
            <h2 className="text-xl font-semibold ">{"Miscellaneous:"}</h2>
            <br />
            - BAB Media reserves the right to modify or update these terms and
            conditions at any time without prior notice.
            <br />
            <br /> - The use of BAB Media is subject to compliance with all
            applicable laws, rules, and regulations.
            <br /> <br /> - BAB Media shall not be held responsible for any
            loss, damage, or inconvenience caused by the use of its services.
            <br /> <br /> - These terms and conditions shall be governed by and
            construed in accordance with the laws of [Jurisdiction].
            <br /> <br /> - Any disputes arising from or relating to these terms
            and conditions shall be subject to the exclusive jurisdiction of the
            courts of [Jurisdiction].
            <br />
            <br />
            If you have any questions or concerns regarding these terms and
            conditions, please contact us at <strong>
              702-743-0050
            </strong> or <strong>bab-media2023@gmail.com</strong>
          </p>
          <p className="mb-2 text-lg mx-6 font-medium  my-10">
            By using BAB Media, you acknowledge that you have read, understood,
            and agreed to these terms and conditions.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}
