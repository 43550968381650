import React from "react";

export default function PaymentLoading() {
  return (
    <div className="bg-white p-6 px-4 md:px-20 min-h-[18rem] rounded-sm  flex justify-center items-center flex-col  md:mx-auto">
      <div className="w-[5rem] h-[5rem] border-8 rounded-full border-t-secondary animate-spin"></div>

      <div className="text-center mt-6">
        <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">
          Transaction in progress
        </h3>
        <p className="text-gray-600 my-2">Please don't close this window</p>
      </div>
    </div>
  );
}
