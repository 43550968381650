import { IconButton, Typography, rating } from "@material-tailwind/react";
import React from "react";
import { HeartIcon, ShareIcon, PlayIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid";

import {
  FilmIcon,
  MusicalNoteIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import SharePopover from "./SharePopover";
import { getYear, returnSubCategoryName, toFixed } from "../helper/helper";
import { useReduxStore } from "../hooks/useReduxStore";
import { updateHome } from "../store/Reducers/homeReducer";
import FavouriteBtn from "./FavouriteBtn";
import { DetailPageUrl } from "../services/API";

export default function DetailBanner({
  rating = "4.0",
  views = 1,
  type = "movie",
  description,
  image = "https://img.freepik.com/free-photo/movie-background-collage_23-2149876028.jpg?w=900&t=st=1701434772~exp=1701435372~hmac=0ea91e43e3ad843fa0a52d9f8d2935642eb09535679f293198c7ce530a45f30f",
  title,
  cast,
  author,
  sub_category,
  released_date,
  publish_date,
  onClickBuy,
  isPurchased = false,
  onWishClick,
  price,
  is_favourite,
  width,
  id,
  onFeaturedClick,
  feature_video
}) {
  const { getState } = useReduxStore();
  sub_category = returnSubCategoryName(sub_category);
  const isMusic = type === "Audio";
  const isMovie = type === "Video";
  const isEbook = type === "ebook";
  const isAudioBook = type === "audio_book";
  const typeName = `${
    isAudioBook
      ? "Audio Book"
      : isEbook
      ? "Book"
      : isMusic
      ? "Music"
      : isMovie
      ? "Movie"
      : "Season"
  }`;
  const navigate = useNavigate();
  // const onAddToCart = () => {
  //   navigate("/AddToCart");
  // };
  const onWatch = () => {
    navigate("/Vedio");
  };

  const onFirstBtnPress = () => {
    !isPurchased ? onClickBuy() : onWatch();
  };
  return (
    <div
      style={{
        backgroundImage: width < 720 ? "none" : `url(${image})`,
      }}
      id="Detail"
      className={` bg-white md:bg-blue md:h-screen   mx-auto  px-4 sm:px-6 md:px-4 lg:px-14 py-4 sm:py-20   shadow-md md:relative md:justify-center  md:flex md:flex-col   md:bg-cover md:bg-center md:bg-no-repeat`}
    >
      <div className="absolute hidden md:block inset-0 bg-gradient-to-r from-black/100 to-black/5"></div>
      <div className="mx-auto md:mx-0  justify-end  px-4  sm:px-6  lg:px-8 flex gap-10 md:gap-20 lg:flex-justify md:justify-start lg:flex  flex-col-reverse lg:flex-row ">
        <div className=" z-10 sm:text-center  lg:text-left lg:w-2/5 lg:flex lg:flex-col lg:justify-center">
          <h2 className="text-2xl lg:text-6xl leading-10 lg:leading-[2.9rem] text-center md:text-left text-black md:text-white  font-medium ">
            {title || "INCEPTIONS"}
          </h2>
          <div className="mt-6   items-center justify-center md:justify-start ">
            <Typography className="flex items-center text-blue-gray-900 md:text-white  justify-center text-center md:justify-start gap-1.5 font-normal text-xs md:text-lg ">
              <FilmIcon className="h-5 w-5 text-secondary" />
              {`${typeName} |`}
              <EyeIcon className="h-4 w-4 md:h-6 md:w-6 text-secondary" />
              {`${views}`}
            </Typography>
          </div>
          {!isMusic && (
            <p className="text-gray-600 md:text-gray-300 line-clamp-3 text-base md:text-lg mt-4 text-center md:text-left ">
              {description ||
                "An intelligence operative for a shadowy global peacekeeping agency races to stop a hacker from stealing its most valuable — and dangerous — weapon. races to stop a hacker from stealing its most valuable — and dangerous — weapon."}
            </p>
          )}
          <Typography
            // color="blue-gray"
            className="text-center text-blue-gray-900 md:text-white md:text-left mt-3 gap-1.5 font-normal text-base md:text-lg line-clamp-3   "
          >
            {"By : "} {cast || author || "Cristopher Nolan"}
          </Typography>
          {!isPurchased && (
            <Typography
              // color="blue-gray"
              className="text-center text-blue-gray-900 md:text-white md:text-left mt-3 gap-1.5 font-normal text-base md:text-lg line-clamp-3   "
            >
              {"Price"} : $ {toFixed(price)}
            </Typography>
          )}

          <Typography className="text-center md:text-left mt-2 gap-1.5 font-semibold  text-base text-secondary md:text-base line-clamp-2 w-full   ">
            {sub_category}
          </Typography>
          <div className="mt-10 gap-6 flex flex-col-reverse sm:flex-row justify-center md:justify-start">
            <button
              onClick={onFirstBtnPress}
              className="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
              {!isPurchased && "Buy and "}
              {isMusic
                ? "Play"
                : isEbook
                ? "Read"
                : isAudioBook
                ? "Listen"
                : "Watch"}
            </button>
            { feature_video&&  <button
              onClick={onFeaturedClick}
              className="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
           Watch Promo 
            </button>}
            <div className="mt-2 md:mt-0 gap-6 flex  sm:flex-row justify-center  md:justify-start md:items-center">
              <FavouriteBtn
                is_favourite={is_favourite}
                onClick={onWishClick}
                isLight={width > 720}
              />

              {
                <SharePopover
                  link={`${DetailPageUrl}?id=${id}`}
                  isDark={width < 720}
                />
              }
            </div>
          </div>
          {/* End of Button Section */}
        </div>
        {/*   Image Section     */}
        <div className="lg:inset-y-0 relative  lg:right-0 lg:w-1/3 ">
          <img
            className="md:hidden h-56 w-full object-cover  sm:h-72 md:h-96  lg:w-full lg:h-full lg:max-h-[35rem] "
            src={
              image ||
              "https://ntvb.tmsimg.com/assets/p7825626_v_h10_aa.jpg?w=1280&h=720"
            }
            alt=""
          />
          {/* <div className="hover:bg-black/30 hover:cursor-pointer absolute  flex justify-center items-center top-0 bottom-0 h-56 w-full  sm:h-72 md:h-96  lg:h-full  inset-0 bg-black/0  ">
            <IconButton
              size="lg"
              color="white"
              className="rounded-full bg-black    "
            >
              <PlayIcon className="h-6 w-6 text-white  " />
            </IconButton>
          </div> */}
        </div>
        {/*   End of Image Section     */}
      </div>
    </div>
  );
}
