import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Schemas from "../../utilities/validations";
import { useReduxStore } from "../../hooks/useReduxStore";
import { resetPasswordAction } from "../../store/Actions/action";
import { useNavigate, useSearchParams, useParams } from "react-router-dom";
export default function useChangePassword() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const token = window?.location?.href?.split("/")?.pop()?.split("?")?.shift();
  // console.log("token", token, email);
  const navigate = useNavigate();
  const { dispatch, getState } = useReduxStore();
  const { resetSuccess } = getState("AUTH");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: Schemas.resetPassword,
  });

  const onSubmit = (data = {}) => {
    // console.log("data---------", data);
    dispatch(resetPasswordAction({ ...data, email, token }));
  };
  const goBackTo = () => {
    if (!token || !email) navigate(-1);
  };
  const onNavigate = () => {
    navigate("/Login", { replace: true });
  };
  useEffect(() => {
    resetSuccess && onNavigate();
  }, [resetSuccess]);

  // useEffect(() => {
  //   goBackTo();
  // }, [token, email]);
  return {
    register,
    handleSubmit,
    onSubmit,
    errors,
  };
}
