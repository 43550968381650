import { delay, put, takeLatest, call } from "redux-saga/effects";
import { updateHome } from "../Reducers/homeReducer";
import { GetBanners, GetHomeData, GetItemsByCategory } from "../Actions/action";
import * as homeService from "../../services/homeService";
import { apiResponse, onError } from "../../helper/helper";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../Constant/Data";
function* getAllCategoriesSaga(action) {
  try {
    const { ok, data, originalError } = yield call(
      homeService.getAllCategories
    );
    // console.log("getAllCategories-----------", data);
    if (ok) {
      const { data: categories } = data || {};
      yield put(
        updateHome({
          categories,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    // console.log("error-----------", error);
  }
}
function* getSubCategoriesSaga(action) {
  try {
    const { ok, data, originalError } = yield call(
      homeService.getSubCategories
    );
    // console.log("getSubCategoriesSaga-----------", data);
    if (ok) {
      const { data: subCategories } = data || {};
      yield put(
        updateHome({
          subCategories,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message, toastConfiguration));
    }
  } catch (error) {
    // console.log("error-----------", error);
  }
}
function* getBannerSaga(action) {
  try {
    const { ok, data, originalError } = yield call(homeService.getBanners);
    // console.log("getBannerSaga------", data);
    if (ok) {
      const { data: homeBanners } = data || {};
      yield put(
        updateHome({
          homeBanners,
        })
      );
    } else {
      // const errorMsg = Boolean(typeof data?.message === "string");
      // const message = data?.message || originalError?.message;
      // if (errorMsg) toast.error(message);
      // else toast.error(apiResponse(message));
    }
  } catch (error) {
    toast.error("Some Thing Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  }
}

function* getHomeDataSaga(action) {
  try {
    yield put(updateHome({ trendingLoading: true }));
    const { ok, data, originalError } = yield call(homeService.trendingItems);
    // console.log("trendingItems------", data);

    if (ok) {
      const { data: trendingItems } = data || {};
      yield put(
        updateHome({
          trendingItems,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
    }
  } catch (error) {
    // console.log("error-----------", error);
  } finally {
    yield put(updateHome({ trendingLoading: false }));
  }
}
function* getPopularItemsSaga(action) {
  try {
    yield put(updateHome({ popularLoading: true }));
    const { ok, data, originalError } = yield call(homeService.popularItems);
    // console.log("popularItems------", data);
    if (ok) {
      const { data: popularItems } = data || {};
      yield put(
        updateHome({
          popularItems,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
    }
  } catch (error) {
    toast.error("Some Thing Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  } finally {
    yield put(updateHome({ popularLoading: false }));
  }
}
function* getPopularItemsByCategorySaga(action) {
  try {
    yield put(updateHome({ popularLoading: true }));
    const { ok, data, originalError } = yield call(
      homeService.getPopularItemsByCategory
    );
    // console.log("getPopularItemsByCategorySaga------", data);

    if (ok) {
      const { data: categoriesItems } = data || {};
      yield put(
        updateHome({
          categoriesItems,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
    }
  } catch (error) {
    // console.log("error-----------", error);
  } finally {
    yield put(updateHome({ popularLoading: false }));
  }
}
function* getItemsByCategorySaga(action) {
  try {
    const { id, page, search } = action?.payload || {};
    const { ok, data, originalError, status } = yield call(
      homeService.getItemsByCategory,
      { id, page, search }
    );
    // console.log("getItemsByCategory------", data);

    if (ok) {
      const { data: items, meta } = data || {};
      yield put(
        updateHome({
          items,
          currentPage: meta?.current_page,
          lastPage: meta?.last_page,
          totalItemsCount: meta?.total,
          perPageCount: meta?.per_page,
        })
      );
    } else {
      if (status === 404) {
        yield put(
          updateHome({
            items: [],
          })
        );
        return;
      }
      onError(data, originalError);
    }
  } catch (error) {
    toast.error("Some Thing Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  }
}

function* homeSaga() {
  yield takeLatest(GetHomeData, getAllCategoriesSaga);
  yield takeLatest(GetBanners, getBannerSaga);
  yield takeLatest(GetHomeData, getHomeDataSaga);
  yield takeLatest(GetHomeData, getPopularItemsByCategorySaga);
  yield takeLatest(GetHomeData, getPopularItemsSaga);
  yield takeLatest(GetHomeData, getSubCategoriesSaga);
  yield takeLatest(GetItemsByCategory, getItemsByCategorySaga);
}

export default homeSaga;
