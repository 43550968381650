import React, { useRef } from "react";
import { useReduxStore } from "../hooks/useReduxStore";
import { updateItem } from "../store/Reducers/itemReducer";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { updateHome } from "../store/Reducers/homeReducer";
import SelectBoxSimple from "./SelectBoxSimple";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConfiguration } from "../Constant/Data";
export default function SearchModal({ handelModal, onLoginClick }) {
  const { getState, dispatch } = useReduxStore();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const { search } = getState("Item");
  const { categories, selectedCategory } = getState("Home");
  const closeSearchModal = () => {
    dispatch(updateItem({ searchModal: false }));
  };
  const onCategoryPress = (selectedCategory) => {
    dispatch(updateHome({ selectedCategory }));
  };

  const onSearch = () => {
    if (searchRef?.current?.value?.length && selectedCategory) {
      dispatch(updateItem({ search: searchRef?.current?.value }));
      closeSearchModal();
      navigate("/ProductCollection", { state: {} });
    } else if (searchRef?.current?.value?.length && !selectedCategory) {
      toast.info(
        "Please select a category to search items ",
        toastConfiguration
      );
    } else {
    }
  };
  return (
    <div
      onClick={handelModal}
      className="modal   bg-black/80 h-screen fixed left-0 flex  top-0 w-screen z-[999999] animate-fade-down animate-ease-in
      animate-duration-300
      animate-normal
      animate-fill-both
      "
    >
      <div className=" bg-white h-fit z-50 py-3 px-4  md:py-3 md:px-32  w-screen ">
        <div
          onClick={closeSearchModal}
          className="absolute top-3 right-5 cursor-pointer"
        >
          <XMarkIcon className="h-6 w-6 text-gray-800 hover:text-black" />
        </div>
        <div className="relative flex w-full  mt-10  border-gray-300 border rounded-lg  lg:px-0">
          <div className="bg-gray-300 flex flex-col items-center justify-center ">
            <SelectBoxSimple
              color="white"
              variant="filled"
              labelClassName="border-gray-300 !bg-gray-300  !ml-0 px-2 md:px-6 !shadow-none  border-gray-300 text-xs md:text-sm r-rounded-none text-black   !w-auto"
              onItemClick={onCategoryPress}
              data={categories}
              labelColor={"text-black"}
              label={"Select Category"}
              selected={selectedCategory}
            />
          </div>
          <input
            className="w-full py-2 px-4 border flex-1  text-black border-gray-300 shadow-sm focus:outline-none focus:ring-0 focus:ring-secondary focus:border-secondary"
            type="search"
            placeholder="Search"
            ref={searchRef}
            maxLength={30}
            defaultValue={search}
          />
          <button
            onClick={onSearch}
            className="absolute inset-y-0 right-0 flex items-center px-3 md:px-4 text-white bg-secondary border border-secondary hover:border-orange-200 rounded-r-md hover:bg-orange-200 focus:outline-none ring-1 ring-secondary focus:border-secondary"
          >
            <svg
              className="h-4 w-4 md:h-5 md:w-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.795 13.408l5.204 5.204a1 1 0 01-1.414 1.414l-5.204-5.204a7.5 7.5 0 111.414-1.414zM8.5 14A5.5 5.5 0 103 8.5 5.506 5.506 0 008.5 14z"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
