import React, { useEffect, useMemo, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { moviesData, ebooksData, sortingData } from "../Constant/Data";
import MyItemsCard from "./MyItemsCard.jsx";
import { useReduxStore } from "../hooks/useReduxStore";
import { GetBuyItemsAction } from "../store/Actions/action";
import EmptyList from "./EmptyList";
import Pagination from "./Pagination";
import { emptyItem } from "../assets/Images";
import { updateItem } from "../store/Reducers/itemReducer";
import { scrollToTop, sorting } from "../helper/helper";
import SelectBoxSimple from "./SelectBoxSimple";
export default function MyItemsSection({ selected = "All" }) {
  const [sortBy, setSortBy] = useState(null);
  const { getState, dispatch } = useReduxStore();
  const { buyItems, totalItemsCount, currentPage, lastPage, perPageCount } =
    getState("Item");
  const { loading } = getState("AUTH");
  const [openMenu, setOpenMenu] = React.useState(false);
  const listData = useMemo(() => {
    return sorting(
      [...buyItems],
      sortBy?.id === 2 ? "desending" : sortBy?.id === 1 ? "assending" : null
    );
  }, [sortBy, buyItems]);
  const onSorting = (item) => {
    setSortBy(item);
    // console.log("sort", item);
  };
  const getBuyItems = (page) => {
    dispatch(GetBuyItemsAction({ page: page || currentPage }));
    scrollToTop();
  };
  useEffect(() => {
    getBuyItems();
  }, []);
  useEffect(() => {
    return () => {
      dispatch(updateItem({ items: [], currentPage: 1 }));
    };
  }, []);
  return (
    <div className="lg:col-span-3 w-[100vw] lg:w-[65vw] min-h-screen   bg-white md:shadow-lg md:px-10 py-3 px-3 rounded-sm md:border-[1px] md:border-gray-100 ">
      <div className="flex justify-between items-center py-4">
        <h4 className="text-xl font-semibold text-black sm:text-3xl text-center lg:text-left">
          Your Items
        </h4>
        <SelectBoxSimple
          data={[...sortingData]}
          label={"Sort By"}
          onItemClick={onSorting}
          selected={sortBy}
        />
        {/* <Menu open={openMenu} handler={setOpenMenu} allowHover>
          <MenuHandler>
            <Button
              variant="text"
              className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
            >
              {selected}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3.5 w-3.5 transition-transform ${
                  openMenu ? "rotate-180" : ""
                }`}
              />
            </Button>
          </MenuHandler>
          <MenuList>
            <MenuItem>All</MenuItem>
            <MenuItem>Movies</MenuItem>
            <MenuItem>Music</MenuItem>
            <MenuItem>Books</MenuItem>
          </MenuList>
        </Menu> */}
      </div>
      <ul className="grid grid-cols-1    gap-4 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-2 3xl:grid-cols-2  ">
        {listData.map((item, i) =>
          item?.product_details ? (
            <li>
              <MyItemsCard
                {...item.product_details}
                item={item?.product_details}
              />
            </li>
          ) : null
        )}
      </ul>
      {!loading && !buyItems?.length && (
        <EmptyList
          description={"There is no items in your buy list"}
          image={emptyItem}
        />
      )}
      {listData?.length && listData.length > 9 ? (
        <Pagination
          {...{
            totalItemsCount,
            currentPage,
            lastPage,
            perPageCount,
            onBtnClick: getBuyItems,
          }}
        />
      ) : null}
    </div>
  );
}
