import React from "react";
import { Link } from "react-router-dom";

export default function EmailSent({ email = "test@gmail.com" }) {
  return (
    <div className=" p-6 px-4 md:px-12 rounded-sm  md:mx-auto">
      <svg
        viewBox="0 0 24 24"
        className="text-secondary w-16 h-16 mx-auto my-6 mb-8"
      >
        <path
          fill="currentColor"
          d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
        ></path>
      </svg>
      <div className="text-center">
        <h3 className="md:text-4xl text-2xl text-white font-semibold text-center">
          Email Sent
        </h3>
        <p className="text-gray-200 my-5 md:text-lg leading-relaxed">
          We sent an email to{" "}
          <strong className="text-secondary"> {email} </strong> with a link to{" "}
          <br /> reset your password
        </p>
        {/* <p> Check Your Email!</p> */}
        <div className="py-10 mt-2 text-center"></div>
      </div>
    </div>
  );
}
