import { Carousel } from '@material-tailwind/react';
import { Fade,Flip,Slide,Bounce,Zoom } from "react-reveal";
import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


// import required modules
import {Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
export default function SoulFlowAboutSection() {
  return (
    <section className="text-white body-font bg-black border-t border-b border-neutral-900 ">
    <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
      <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      <Fade 
        duration={1200}
        distance={'6rem'}
        left
        
      >
      <h1 className="title-font sm:text-5xl text-3xl mb-8 font-semibold text-white">
         About Our Company 
        </h1>
      </Fade>
      <Fade
          duration={1200}
          distance={'6rem'}
          left
          
      >
      <p className="mb-8 text-gray-400 text-lg leading-relaxed">
        B.A.B Soul Flow Records By AJB Welcome to B.A.B Soul Flow Records, the premier destination for music innovation and creativity in Atlantic City, New Jersey. Founded by AJB, our record label is dedicated to nurturing and promoting talented artists, providing them with the platform and support they need to thrive in the music industry. 
        </p>
      </Fade>
      </div>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
      {/* <Carousel
         
         loop
         autoplay
         transition={{ duration: 2 ,type:"Inertia"}}
         className="h-[15rem] 2xl:h-[32rem] xl:h-[32rem]lg:h-[32rem] md:h-[32rem] "
       >
      <Zoom 
          duration={1500}
          cascade

       >
      <img
          className="object-cover object-center rounded"
          alt="hero"
          src={require("../assets/Picture2.png")}
        />
      </Zoom>
      <Zoom 
          duration={1500}
          cascade

       >
      <img
          className="object-cover object-center rounded"
          alt="hero"
          src={require("../assets/Designer (20).png")}
        />
      </Zoom>
      <Zoom 
          duration={1500}
          cascade

       >
      <img
          className="object-cover object-center rounded"
          alt="hero"
          src={require("../assets/downloadfile-3.jpg")}
        />
      </Zoom>
      </Carousel> */}
                <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
          {({isActive})=>(
        <Fade 
        duration={3000}
        cascade
        when={isActive}
     >
    <img
        className="object-cover object-center rounded"
        alt="hero"
        src={require("../assets/Picture2.png")}
      />
    </Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
   <Fade 
      duration={3000}
          cascade
          when={isActive}
          distance={"2rem"}
>
<img
   className="object-cover object-center rounded"
   alt="hero"
   src={require("../assets/Designer (20).png")}
 />
</Fade>
          )}
        </SwiperSlide>
        <SwiperSlide>
          {({isActive})=>(
   <Fade 
      duration={3000}
          cascade
          when={isActive}
          distance={"2rem"}
>
<img
   className="object-cover object-center rounded"
   alt="hero"
   src={require("../assets/downloadfile-3.jpg")}
 />
</Fade>
          )}
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
    <div className="container mx-auto flex px-5 py-24 md:flex-row-reverse flex-col items-center">
   <Fade
    duration={1200}
    distance={'6rem'}
    right
   >
   <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
        <h1 className="title-font sm:text-5xl text-3xl mb-8 font-semibold text-white">
        Our Mission   
        </h1>
        <p className="mb-8 text-lg text-gray-400 leading-relaxed">
        At B.A.B Soul Flow Records, our mission is to empower artists to express themselves authentically through music. We believe in the transformative power of music and the ability of artists to inspire, connect, and uplift audiences around the world. Our unique and distinctive music genre sets us apart and allows us to offer a fresh and captivating sound to our listeners
        </p>
        {/* <div className="flex justify-center">
          <button className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg">
            Button
          </button>
          <button className="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg">
            Button
          </button>
        </div> */}
      </div>
   </Fade>
      <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
             <Swiper
        spaceBetween={30}
        effect={'fade'}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: true,
        }}
        modules={[EffectFade, Navigation, Pagination,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide>
    {({isActive})=>(
          <Fade
          duration={3000}
          cascade
          when={isActive}
          distance={"2rem"}
          >
          <img
             className="object-cover object-center rounded"
             alt="hero"
             src={require("../assets/downloadfile-1.jpg")}
           />
          </Fade>
    )}
        </SwiperSlide>
        <SwiperSlide>
   {({isActive})=>(
         <Fade
         duration={3000}
         cascade
         when={isActive}
         distance={"2rem"}
         >
         <img
            className="object-cover object-center rounded"
            alt="hero"
            src={require("../assets/downloadfile-2.jpg")}
          />
         </Fade>
   )}
        </SwiperSlide>
      </Swiper>
      </div>
    </div>
  </section>
  )
}
