import React from "react";

export default function SelectItemComponent({
  title,
  selected,
  onSelect,
  item,
  filterType,
}) {
  const onClick = () => {
    onSelect(selected ? null : item, filterType, selected);
  };
  // console.log("selected---------", selected);

  return (
    <button
      onClick={onClick}
      className={`flex flex-row justify-between p-1   w-full rounded ${
        selected ? "bg-secondary" : ""
      }   !cursor-pointer`}
      aria-disabled="false"
    >
      <div
        className={`select-none capitalize font-normal text-sm ${
          selected ? "text-white" : "text-gray-800"
        }  `}
      >
        {title}
      </div>
      {/* <div class="text-sm text-secondary-interaction">144381</div> */}
    </button>
  );
}
