import React from 'react'
import { Fade,Flip,Slide,Zoom,Bounce } from "react-reveal";

export default function ConnectUsSection() {
  return (
    <div className="bg-black p-4 min-h-screen">
  <div
    aria-hidden="true"
    className="absolute inset-0 h-max w-full m-auto grid grid-cols-2 -space-x-52 opacity-20"
  >
    <div className="blur-[106px] h-56 bg-gradient-to-br  to-purple-400 from-blue-700" />
    <div className="blur-[106px] h-32 bg-gradient-to-r from-cyan-400  to-indigo-600" />
  </div>
  <div className="max-w-7xl mx-auto px-6 md:px-12 xl:px-6">
  <Fade
     duration={1000}
     distance={'6rem'}
     top
 >
  <div className="max-w-xl mt-20 mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-20">
    <div>
      {/* <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-sky-500 text-white">
        Brand new
      </p> */}
    </div>
    <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-white sm:text-4xl md:mx-auto">
      <span className="relative inline-block">
        <svg
          viewBox="0 0 52 24"
          fill="currentColor"
          className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-slate-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
        >
          <defs>
            <pattern
              id="fdca20a0-aeb4-4caf-ba1b-4351eee42363"
              x={0}
              y={0}
              width=".135"
              height=".30"
            >
              <circle cx={1} cy={1} r=".7" />
            </pattern>
          </defs>
          <rect
            fill="url(#fdca20a0-aeb4-4caf-ba1b-4351eee42363)"
            width={52}
            height={24}
          />
        </svg>
        <span className="relative mr-3">Let</span>
      </span>
     Connect With Us
    </h2>
    <p className="text-base text-gray-300 md:text-lg">
    Follow us on social media for updates, behind-the-scenes glimpses, and more
    </p>
  </div>
  </Fade>
    <Fade
     duration={1200}
     distance={'8rem'}
     right

    >
    <div className="mt-16 grid divide-x divide-y  divide-gray-700 overflow-hidden  rounded-3xl border text-gray-600 border-gray-700 sm:grid-cols-2 lg:grid-cols-4  lg:divide-y-0 xl:grid-cols-4">
      <div className="group relative bg-black-200 transition hover:z-[1] hover:shadow-2xl  hover:shadow-gray-600/10">
        <div className="relative space-y-8 py-12 p-8">
          <img
            src={require("../assets/facebook.png")}
            loading="lazy"
            width={200}
            height={200}
            className="w-12 h-12 rounded-full"
            style={{ color: "transparent" }}
          />
          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
              Facebook/BAB Soul Flow Records
            </h5>
            <p className="text-gray-300">
            Join us on facebook to stay connected with us
            </p>
          </div>
        </div>
      </div>
      <div className="group relative bg-black-200 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="relative space-y-8 py-12 p-8">
          <img
                      src={require("../assets/instagram.png")}
            loading="lazy"
            width={200}
            height={200}
            className="w-12 h-12 rounded-full"
            style={{ color: "transparent" }}
          />
          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
            Instagram/BAB Soul Flow Records
            </h5>
            <p className="text-gray-300">
            Join us on instagram to stay connected with us
            </p>
          </div>
        </div>
      </div>
      <div className="group relative bg-black-200 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="relative space-y-8 py-12 p-8">
          <img
         src={require("../assets/twitter.png")}
            loading="lazy"
            width={200}
            height={200}
            className="w-12 h-12 rounded-full"
            style={{ color: "transparent" }}
          />
          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
            Twitter/BAB Soul Flow Records
            </h5>
            <p className="text-gray-300">
            Join us on Twitter to stay connected with us 
            </p>
          </div>
        </div>
      </div>
      <div className="group relative bg-black-200 transition hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10">
        <div className="relative space-y-8 py-12 p-8">
          <img
           src={require("../assets/linkedin.png")}
            loading="lazy"
            width={200}
            height={200}
            className="w-12 h-12 rounded-full"
            style={{ color: "transparent" }}
          />
          <div className="space-y-2">
            <h5 className="text-xl font-semibold text-white transition group-hover:text-secondary">
            Linkedin/BAB Soul Flow Records
            </h5>
            <p className="text-gray-300">
             Join us on linkdin to stay connected with us 
            </p>
          </div>
        </div>
      </div>
    </div>
    </Fade>
  </div>
</div>
  )
}
