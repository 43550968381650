import React from "react";
import ProfileInput from "./ProfileInput";
import { useReduxStore } from "../hooks/useReduxStore";
import { ChangePasswordAction } from "../store/Actions/action";
import Schemas from "../utilities/validations";
import { useForm } from "react-hook-form";
export default function ChangePasswordComponent() {
  const { getState, dispatch } = useReduxStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: Schemas.changePassword,
  });

  const onSave = (data = {}) => {
    // console.log("data------", data);
    let { new_password, old_password, password_confirmation } = data;
    dispatch(
      ChangePasswordAction({
        current_password: old_password,
        password: new_password,
        password_confirmation,
      })
    );
  };
  return (
    <>
      <ProfileInput
        label={"Current Password"}
        placeholder="Enter Current Password"
        type="password"
        id="c_password"
        name={"old_password"}
        register={register}
        errors={errors}
      />
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        <ProfileInput
          label={"New Password"}
          placeholder="Enter New Password"
          type="password"
          id="n_password"
          name={"new_password"}
          register={register}
          errors={errors}
        />
        <ProfileInput
          label={"Confirm Password"}
          placeholder="Enter New Password Again"
          type="password"
          id="n_password_a"
          name={"password_confirmation"}
          register={register}
          errors={errors}
        />
      </div>
      <div className="mt-10">
        <button
          onClick={handleSubmit(onSave)}
          className="inline-block w-full rounded-md bg-secondary px-8 py-3 font-medium text-white sm:w-auto"
        >
          Save Changes
        </button>
      </div>
    </>
  );
}
