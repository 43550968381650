import { delay, put, takeLatest, call } from "redux-saga/effects";
import { logout, updateAuth } from "../Reducers/authReducer";
import {
  ChangePasswordAction,
  EditBasicInfoAction,
  GetWishlistItemsAction,
  LoginAction,
  LogoutAction,
  RegisterAction,
  ToggleWishlistItemAction,
  resetPasswordAction,
  resetPasswordRequestAction,
} from "../Actions/action";
import * as authServie from "../../services/authService";
import { toast } from "react-toastify";
import { apiResponse, onError } from "../../helper/helper";
import { updateItem } from "../Reducers/itemReducer";
import { toastConfiguration } from "../../Constant/Data";
function* editBasicInfoSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.editBasicInfo,
      payload
    );
    if (ok) {
      let { data: userData } = data || {};
      toast.success("Edit Successfully", toastConfiguration);
      yield put(updateAuth({ userData }));
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong");
    // console.log("error-----------", error);
  }
}
function* changePasswordSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.changePassword,
      payload
    );
    if (ok) {
      toast.success(
        "Your password has been changed successfully",
        toastConfiguration
      );
      yield delay(200);
      yield put(updateAuth({ changeSuccess: true }));
      yield delay(500);
      yield put(updateAuth({ changeSuccess: false }));
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message, toastConfiguration));
    }
  } catch (error) {
    toast.error("Something Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  }
}
function* registerSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.register,
      payload
    );
    if (ok) {
      toast.success(
        "Your Account Has Been Registered Successfully",
        toastConfiguration
      );
      yield put(updateAuth({ registerSuccess: true }));
      yield delay(500);
      yield put(updateAuth({ registerSuccess: false }));
    } else {
      if (data?.email) {
        toast.error(apiResponse(data.email), toastConfiguration);
        return;
      }
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  } finally {
  }
}
function* verifyUserSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.VerifyUser,
      payload
    );
    if (ok) {
      toast.success(
        "Your OTP has been verified. You can now sign in now.",
        toastConfiguration
      );
      yield put(updateAuth({ OTPSuccess: true }));
      yield delay(500);
      yield put(updateAuth({ OTPSuccess: false }));
    } else {
      if (data?.email) {
        toast.error(apiResponse(data.email), toastConfiguration);
        return;
      }
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  } finally {
  }
}
function* ReseandOTPSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.ReSendOTP,
      payload
    );
    if (ok) {
      toast.success(
        "A new OTP has been sent to your email address. Please check your inbox and enter the new OTP to continue.",
        toastConfiguration
      );
      yield put(updateAuth({ OTPsent: true }));
      yield delay(500);
      yield put(updateAuth({ OTPSent: false }));
    } else {
      if (data?.email) {
        toast.error(apiResponse(data.email), toastConfiguration);
        return;
      }
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong", toastConfiguration);
    // console.log("error-----------", error);
  } finally {
  }
}
function* resetPasswordRequestSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.resetPasswordRequest,
      payload
    );
    if (ok) {
      toast.success(
        "Password reset link sent successfully",
        toastConfiguration
      );
      yield put(
        updateItem({
          emailSent: true,
        })
      );
    } else {
      if (data?.error) {
        toast.error(apiResponse(data?.error), toastConfiguration);
        return;
      }
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong ", toastConfiguration);
    // console.log("error-----------", error);
  }
}
function* resetPasswordSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(
      authServie.resetPassword,
      payload
    );

    if (ok) {
      toast.success(
        "Your password has been reset successfully",
        toastConfiguration
      );
      yield put(
        updateAuth({
          resetSuccess: true,
        })
      );
      yield delay(500);
      yield put(updateAuth({ resetSuccess: false }));
    } else {
      if (data?.error) {
        toast.error(apiResponse(data?.error), toastConfiguration);
        return;
      }
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong ", toastConfiguration);
    // console.log("error-----------", error);
  }
}
function* loginSaga({ payload }) {
  try {
    const { ok, data, originalError } = yield call(authServie.login, payload);
    // console.log("data-----------", data);
    if (ok) {
      let { data: userData } = data || {};
      yield put(
        updateAuth({
          userData,
          token: userData?.token,
          loginSuccess: true,
        })
      );
      yield delay(500);
      yield put(updateAuth({ loginSuccess: false }));
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    toast.error("Something Went Wrong ", toastConfiguration);
    // console.log("error-----------", error);
  } finally {
  }
}
function* logoutSaga() {
  try {
    const { ok, data, originalError } = yield call(authServie.logout);
    if (ok) {
      toast.success("Logout Successfully", toastConfiguration);
      yield put(logout());
    } else {
      onError(data, originalError);
      yield put(logout());
    }
  } catch (error) {
    toast.error("Something Went Wrong ", toastConfiguration);
  }
}
function* getWishlistItemsSaga(action) {
  try {
    const { ok, data, originalError, status } = yield call(
      authServie.getWishListItem,
      {}
    );
    if (ok) {
      const { data: wishlistItems } = data || {};
      yield put(
        updateAuth({
          wishlistItems,
        })
      );
    } else {
      if (status === 404) {
        yield put(
          updateAuth({
            wishlistItems: [],
          })
        );
        return;
      }
      onError(data, originalError);
    }
  } catch (error) {
    toast.error("Something Went Wrong ", toastConfiguration);
    // console.log("error-----------", error);
  } finally {
  }
}
function* toggleWishlistItemSaga(action) {
  const { id, itemDetail } = action?.payload || {};
  try {
    const { ok, data, originalError } = yield call(
      authServie.toggleWisthListItem,
      { id }
    );
    // console.log("toggleWishlistItemSaga------", data);
    if (ok) {
      let { message } = data || {};
      toast(message, toastConfiguration);
      yield put(
        updateItem({
          itemDetail,
        })
      );
    } else {
      const errorMsg = Boolean(typeof data?.message === "string");
      const message = data?.message || originalError?.message;
      if (errorMsg) toast.error(message, toastConfiguration);
      else toast.error(apiResponse(message), toastConfiguration);
    }
  } catch (error) {
    // console.log("error-----------", error);
  } finally {
  }
}
function* authSaga() {
  yield takeLatest(RegisterAction, registerSaga);
  yield takeLatest(EditBasicInfoAction, editBasicInfoSaga);
  yield takeLatest(ChangePasswordAction, changePasswordSaga);
  yield takeLatest(LoginAction, loginSaga);
  yield takeLatest(LogoutAction, logoutSaga);
  yield takeLatest(resetPasswordAction, resetPasswordSaga);
  yield takeLatest(resetPasswordRequestAction, resetPasswordRequestSaga);
  yield takeLatest(GetWishlistItemsAction, getWishlistItemsSaga);
  yield takeLatest(ToggleWishlistItemAction, toggleWishlistItemSaga);
}

export default authSaga;
