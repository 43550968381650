import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import Schemas from "../../utilities/validations";
import { useReduxStore } from "../../hooks/useReduxStore";
import { updateAuth } from "../../store/Reducers/authReducer";
import { toast } from "react-toastify";
import { toastConfiguration } from "../../Constant/Data";
export default function useContact() {
  const form = useRef();
  const { dispatch } = useReduxStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: Schemas.contact,
  });
  const sendEmail = async (data, e) => {
    try {
      dispatch(updateAuth({ loading: true }));
      let result = await emailjs.send(
        "service_n4oof58",
        "template_5gnhqwj",
        {
          ...data,
          to_name: "BAB Media",
          reply_to: data?.user_email,
        },

        "0kb3bnzQpzN7czxpV"
      );
      e?.target?.reset();
      toast.success("Sent Successfully", toastConfiguration);
    } catch (error) {
      toast.error(error?.text || "Something went wrong", toastConfiguration);
      //   console.log("error------", error.text);
    } finally {
      dispatch(updateAuth({ loading: false }));
    }
  };

  return {
    sendEmail,
    register,
    handleSubmit,
    form,
    errors,
  };
}
