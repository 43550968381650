import LandingPageBanner from "./LandingPageBanner.jsx";
import 'swiper/css';
import 'swiper/css/pagination';
// import Swiper core and required modules
import SwiperCore from 'swiper'
import { Navigation, Pagination ,HashNavigation,Mousewheel ,EffectFade} from 'swiper/modules';

import bgImage from '../assets/mystio3.png'
import SoulFlowAboutSection from "./SoulFlowAboutSection.jsx";
import MystioBanner from "./MystioBanner.jsx";
import MystiaAbout from "./MystiaAbout.jsx";
import MystioChooseUsSection from "./MystioChooseUsSection.jsx";
SwiperCore.use([Mousewheel, Pagination, Navigation,HashNavigation])

export default function MystioIntoduction() {

  return (
    <>
    <div className=" center bg-black">
    <MystioBanner/>
<MystiaAbout/>
<MystioChooseUsSection/>
    </div>
          </>
  );
}
