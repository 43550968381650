import { IconButton, Typography, rating } from "@material-tailwind/react";
import React from "react";
import { PlayIcon } from "@heroicons/react/24/outline";
import {
  FilmIcon,
  MusicalNoteIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { HeartIcon, ShareIcon } from "@heroicons/react/24/outline";
import { EyeIcon } from "@heroicons/react/24/solid";
import SharePopover from "./SharePopover";
import { getYear, returnSubCategoryName, toFixed } from "../helper/helper";
import FavouriteBtn from "./FavouriteBtn";
import { DetailPageUrl } from "../services/API";
export default function DetailBanner3({
  rating = "4.0",
  views = 1,
  type = "movie",
  description,
  image,
  title,
  author,
  artist,
  sub_category = [],
  released_date,
  publish_date,
  duration_time = "",
  onClickBuy,
  isPurchased = false,
  onWishClick,
  onPlayClick,
  price,
  is_favourite,
  isPlayerOpen,
  id,
  onFeaturedClick,
  feature_video
}) {
  sub_category = returnSubCategoryName(sub_category);

  const isMusic = type === "Audio";
  const isMovie = type === "movie" || type === "season";
  const isEbook = type === "ebook";
  const isAudioBook = type === "audio_book";
  const typeName = `${
    isAudioBook
      ? "Audio Book"
      : isEbook
      ? "Book"
      : isMusic
      ? "Music"
      : isMovie
      ? "Movie"
      : "Season"
  }`;
  const navigate = useNavigate();
  const onAddToCart = () => {
    navigate("/AddToCart");
  };
  const onWatch = () => {
    navigate("/Checkout");
  };
  const onPlay = () => {
    onPlayClick();
  };
  const onFirstBtnPress = () => {
    isPurchased ? onPlay() : onClickBuy();
  };
  return (
    <section
      id="Detail"
      className=" bg-white  mx-auto  px-4 sm:px-6 md:px-4 lg:px-14 py-4 sm:py-14 shadow-md"
    >
      <div className=" mx-auto justify-end  px-4  sm:px-6  lg:px-8 flex gap-10 md:gap-20 lg:flex-justify lg:flex  flex-col-reverse lg:flex-row-reverse">
        <div className="sm:text-center lg:text-left lg:w-1/3 lg:flex lg:flex-col lg:justify-center">
          <h2 className="text-2xl lg:text-4xl leading-10 lg:leading-[2.9rem] text-center md:text-left text-black  font-medium ">
            {title || "INCEPTIONS"}
          </h2>
          <div className="mt-4   items-center justify-center md:justify-start ">
            <Typography
              color="blue-gray"
              className="flex items-center  justify-center text-center md:justify-start gap-1.5 font-normal text-xs md:text-lg "
            >
              <MusicalNoteIcon className="h-5 w-5 text-secondary" />
              {`${typeName} |  `}
              <EyeIcon className="h-4 w-4 md:h-6 md:w-6 text-secondary" />
              {views || 0}
              {/* | {getYear(publish_date || released_date)} 
              {duration_time && ` | ${duration_time}`} */}
            </Typography>
          </div>
          {!isMusic && (
            <p className="text-gray-600 line-clamp-3 text-base md:text-lg mt-4 text-center md:text-left ">
              {description ||
                "An intelligence operative for a shadowy global peacekeeping agency races to stop a hacker from stealing its most valuable — and dangerous — weapon. races to stop a hacker from stealing its most valuable — and dangerous — weapon."}
            </p>
          )}

          <Typography className="text-center text-gray-600 md:text-left mt-3 gap-1.5 font-normal text-sm  line-clamp-3   ">
            {"Artist"} : {artist || author}
          </Typography>
          {!isPurchased && (
            <Typography
              color="blue-gray"
              className="text-center md:text-left mt-2 gap-1.5 font-normal text-base md:text-base line-clamp-3   "
            >
              {"Price"} : $ {toFixed(price)}
            </Typography>
          )}
          <Typography className="text-center text-secondary md:text-left mt-2 gap-1.5 font-normal text-sm  line-clamp-3   ">
            {sub_category}
          </Typography>
          <div className="mt-10 gap-6 flex flex-col-reverse sm:flex-row justify-center  md:justify-start md:items-center">
            <button
              onClick={onFirstBtnPress}
              className="w-full sm:w-32 px-3 py-2 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
              {!isPurchased && "Buy and "}
              {isPlayerOpen
                ? "Pause"
                : isMusic
                ? "Play"
                : isEbook
                ? "Read"
                : isAudioBook
                ? "Listen"
                : "Watch"}
            </button>
            { feature_video&&  <button
              onClick={onFeaturedClick}
              className="w-full sm:w-40 px-4 py-3 rounded font-semibold text-md bg-secondary text-white border-2 border-secondary"
            >
           Watch Promo 
            </button>}
            <div className="mt-2 md:mt-0 gap-6 flex  sm:flex-row justify-center  md:justify-start md:items-center">
              <FavouriteBtn is_favourite={is_favourite} onClick={onWishClick} />
              {<SharePopover link={`${DetailPageUrl}?id=${id}`} />}
            </div>
          </div>
        </div>
        {/*   Image Section     */}
        <div className="lg:inset-y-0 relative  lg:right-0">
          <img
            className="h-56 w-full object-contain sm:h-72 md:h-96  lg:w-full lg:h-full lg:max-h-[23rem] "
            src={
              image ||
              "https://ntvb.tmsimg.com/assets/p7825626_v_h10_aa.jpg?w=1280&h=720"
            }
            alt=""
          />
        </div>
      </div>
    </section>
  );
}
