import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  IconButton,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import {
  FilmIcon,
  MusicalNoteIcon,
  BookOpenIcon,
} from "@heroicons/react/24/outline";
import { updateItem } from "../store/Reducers/itemReducer";
import { useReduxStore } from "../hooks/useReduxStore";
import { deepCopy, returnSubCategoryName } from "../helper/helper";
export default function MyItemsCard({
  image,
  title,
  id,
  description,
  rating,
  type = "movie",
  views,
  item = {},
  category,
}) {
  const { dispatch } = useReduxStore();
  const onItemClick = () => {
    dispatch(updateItem({ itemDetail: deepCopy(item) }));
    navigate(
      {
        pathname: "/Detail",
        search: `?id=${item?.id}`,
      },
      { state: { ...item } }
    );
  };
  const navigate = useNavigate();
  const dummuImageUrl =
    "https://images.unsplash.com/photo-1499696010180-025ef6e1a8f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80";
  return (
    <Card
      onClick={onItemClick}
      className="border-[0.5px] rounded-md border-orange-100  w-full max-w-[40rem] flex-row md:max-w-[38rem]  lg:max-w-[42rem] xl:max-w-[50rem]  shadow-md  md:shadow-lg md:my-2 hover:bg-orange-50 hover:cursor-pointer "
    >
      <CardHeader
        className="m-0 w-2/5 shrink-0 rounded-md rounded-r-none "
        floated={false}
        color="blue-gray"
      >
        <img
          className=" h-32  w-full md:object-cover md:h-[10rem] lg:h-[11rem] xl:h-[12rem] "
          src={image || dummuImageUrl}
          alt="item image"
        />
        <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-tr from-transparent via-transparent to-black/60 " />
      </CardHeader>
      <CardBody className="px-3  pt-2  pb-4  md:pt-5 ">
        <Typography
          variant="h5"
          color="blue-gray"
          className="font-semibold text-sm  md:text-lg line-clamp-1"
        >
          {title || "Wooden House, Florida"}
        </Typography>
        <Typography
          variant="paragraph"
          className="line-clamp-2 mt-2 md:line-clamp-2 text-xs md:text-sm md:mt-3"
          color="gray"
        >
          {description ||
            " Enter a freshly updated and thoughtfully furnished peaceful home surrounded by ancient trees, stone walls, and open meadows."}
        </Typography>
        <div className="mt-3 md:mt-5 flex gap-3 items-center ">
          {type === "Video" && <FilmIcon className="h-5 w-5 text-secondary" />}
          {type === "Audio" && (
            <MusicalNoteIcon className="h-5 w-5 text-secondary" />
          )}
          {type === "Book" && (
            <BookOpenIcon className="h-5 w-5 text-secondary" />
          )}

          <Typography
            color="blue-gray"
            className="flex items-center gap-1.5 font-semibold text-xs   "
          >
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="-mt-0.5 h-5 w-5 text-yellow-700"
            >
              <path
                fillRule="evenodd"
                d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                clipRule="evenodd"
              />
            </svg> */}
            {returnSubCategoryName(category)}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
}
