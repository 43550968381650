import React from "react";
export default function ProfileInput({
  type,
  name,
  id,
  placeholder,
  errors = [],
  label,
  defaultValue = "",
  disabled,
  inputClass = "",
  showLabel = true,
  register = () => {},
}) {
  return (
    <div className="">
      {showLabel && <label className="mb-2 text-black">{label}</label>}
      <input
        disabled={disabled}
        defaultValue={defaultValue}
        type={type}
        id={id}
        placeholder={placeholder}
        className={`w-full rounded-md bg-white  text-black border-gray-200 hover:border-secondary focus:border-secondary focus:outline-none active:border-secondary  mt-2 border-2 p-3 text-sm ${inputClass}`}
        {...register(name)}
      />
      {errors[name] && (
        <p className="text-red-800 mt-2 text-base md:text-base  text-left ml-1">
          {errors[name]?.message}
        </p>
      )}
    </div>
  );
}
