import React, { useEffect } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { moviesData, ebooksData } from "../Constant/Data";
import MyItemsCard from "./MyItemsCard.jsx";
import { useReduxStore } from "../hooks/useReduxStore";
import { GetWishlistItemsAction } from "../store/Actions/action";
import EmptyList from "./EmptyList";
export default function MyWishlistSection({ selected = "All" }) {
  const [openMenu, setOpenMenu] = React.useState(false);
  const { dispatch, getState } = useReduxStore();
  const { wishlistItems } = getState("AUTH");
  const getWishlist = () => dispatch(GetWishlistItemsAction());
  useEffect(() => {
    getWishlist();
  }, []);
  return (
    <div className="lg:col-span-3 min-h-screen w-[100vw] lg:w-[65vw]  bg-white md:shadow-lg md:px-10 py-3 px-3 rounded-sm md:border-[1px] md:border-gray-100 ">
      <div className="flex justify-between items-center py-4">
        <h4 className="text-xl font-semibold text-black sm:text-3xl text-center lg:text-left">
          Your Wishlist
        </h4>
        {/* <Menu open={openMenu} handler={setOpenMenu} allowHover>
          <MenuHandler>
            <Button
              variant="text"
              className="flex items-center gap-3 text-base font-normal capitalize tracking-normal"
            >
              {selected}
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`h-3.5 w-3.5 transition-transform ${
                  openMenu ? "rotate-180" : ""
                }`}
              />
            </Button>
          </MenuHandler>
          <MenuList>
            <MenuItem>All</MenuItem>
            <MenuItem>Movies</MenuItem>
            <MenuItem>Music</MenuItem>
            <MenuItem>Books</MenuItem>
          </MenuList>
        </Menu> */}
      </div>
      {!wishlistItems?.length ? (
        <EmptyList />
      ) : (
        <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-2  xl:grid-cols-2 3xl:grid-cols-2  ">
          {wishlistItems.map((item, i) =>
            item?.product ? (
              <li>
                <MyItemsCard {...item?.product} item={item?.product} />
              </li>
            ) : null
          )}
          {/* {[...moviesData, ...ebooksData].map((item, i) => (
          <li>
            <MyItemsCard {...item} item={item} />
          </li>
        ))} */}
        </ul>
      )}
    </div>
  );
}
