import { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
import { GetBanners, GetHomeData } from "../../store/Actions/action";
import { useReduxStore } from "../../hooks/useReduxStore";
import { toast } from "react-toastify";
import { updateItem } from "../../store/Reducers/itemReducer";
import { useNavigate } from "react-router-dom";
import { updateHome } from "../../store/Reducers/homeReducer";
const useLanding = () => {
  const { getState, dispatch } = useReduxStore();
  const navigate = useNavigate();
  const {
    homeBanners,
    popularLoading,
    trendingLoading,
    categoriesItems,
    popularItems,
    trendingItems,
  } = getState("Home");

  const onViewAll = (item) => {
    dispatch(updateHome({ selectedCategory: { ...item, products: null } }));
    navigate("/ProductCollection", { state: {} });
  };
  useEffect(() => {
    dispatch(GetBanners());
    dispatch(GetHomeData());
  }, []);
  useEffect(() => {
    dispatch(updateItem({ itemDetail: {} }));
  }, []);

  return {
    homeBanners,
    popularLoading,
    trendingLoading,
    categoriesItems,
    popularItems,
    trendingItems,
    onViewAll,
  };
};

export default useLanding;
